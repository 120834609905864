//kwr-header

//Base theme override for KWR site
.ms-header {
    &__container {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
    }
    & .ms-search {
        &__autoSuggest {
            background-color: $moe-white !important;
            //Responsive UI for 992px and above
            @media only screen and (min-width: $msv-breakpoint-l) {
                max-width: 34rem;
            }
        }
    }
}

//kwr header
.kwr-header {
    .kwr-nav-wrap {
        border-top: 1px solid $moe-grey;
        margin-top: 0;
    }
}
//language toggle holder
//language toggle english maori
.moe-language-toggle-holder {
    &--desktop {
        align-self: center;
    }
    &--mobile {
        padding-top: 20px;
    }
}


//Base Theme styles override for KWR specific
main {
    & p,
    & .ms-text-block {
        a {
            color: $kwr-red-active;
            text-decoration: unset;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

//kwr-modal
.kwr-body-search,
.kwr-category-body {
    & .msc-modal{
        &__close-button {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.3125rem;
            border-radius: 1.25rem;            
            color: $color-grey-light;
        }
        .ms-search-result-container{
            &__refiner-section {
                background-color: $color-grey-dark;
                &-ttl,
                & h1, h2, h3,                
                & .ms-refine-submenu-item.multi-select-checked:before,
                & .ms-refine-submenu-item__label,
                & .ms-refine-submenu.list-group .refine-submenu-item__rating {
                    color: $white !important;        
                }
            }
        }
        .ms-search-result-container__Sort-by-category,
        .ms-search-result-wrapper-sort-by-category {
            & select {
                background-color: $color-grey-dark !important;
                color: $white;
                border: 1px solid #707070 !important;
                height: 2.5rem !important;
                font-weight: normal;
            }
        }
        & .msc-l-dropdown__label,
        & .ms-refine-submenu-item.multi-select:before,
        & .msc-rating__count,
        & .ms-refine-submenu__toggle_expanded,
        & button.ms-refine-submenu__toggle_collapsed {
            color: $color-grey-light !important;
        }
        & a, label, .msc-button,        
        & .ms-refine-submenu__toggle_collapsed:after {
            color: $white;
        }
        & ul.ms-refine-submenu__list {
            border-top: 1px solid $color-grey-light;
        }
    }

    //Modal Filter for Mobile & Tablet
    .msc-search-result-modal {
        & .msc-modal{
            &__content {
                background-color: $color-grey-dark;
                color: $white;
            }
            &__footer {
                & .ms-sort-and-filter-modal-close {
                    background-color: transparent;
                    border-color: $color-grey-light;
                    color: $color-grey-light;
                }
            }
        }
    }
}

//Mobile and Tablet (992px and below) Responsive UI
@media only screen and (max-width: $msv-container-max-width-ml) {
   & .msc-search-result-modal {
        max-width: 100%;
   }
}

// fix: 122180 - Select all issue in KWR
.msc-modal__dialog.ms-quickView__dialog .ms-quickView__content .moe-link--selectall {
    color: #000;

    &:hover {
        color: $kwr-red-active;
    }
}


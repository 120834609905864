.moe-decorative-hero {
    background-color: $moe-green-lgter;
    background-size: 7rem auto !important;
    background-color: $moe-gray-drkst;
    background-repeat: repeat-x;
    font-family: 'Montserrat', sans-serif;
    h1, h2, h3, h4 {
        display: inline-block;
        background-color: white;
        border: 1px solid rgb(140, 140, 140);
        margin: 5rem 0;
        padding: 2rem 7rem;
        font-size: 1.375rem;
        font-weight: 900;
        color: $moe-gray-drkst !important;
    }
}

//Site Specific Decorative Hero bg colors
.nzc-body--myaccount {
    & .moe-decorative-hero {
        background-color: $moe-account-nzc;
    }
}
.twk-body--myaccount {
    & .moe-decorative-hero {
        background-color: $moe-account-twk;
    }
}
.kwr-body--myaccount {
    & .moe-decorative-hero {
        background-color: $moe-account-kwr;
    }
}

//moe-tiles

//Basetheme tiles styles override for MOE sites

//ms-tile custom styles
.ms-tile,
.ms-tile-572-234,
.ms-tile-no-img {
    border-radius: 0.625rem; //10px
    @extend .moe-boxshadow;
    cursor: pointer;
}
.ms-tile,
.ms-tile-no-img {
    max-width: 23.5rem; //376px
}
.ms-tile-572-234 {
    max-width: 35.75rem; //572px
}
.ms-tile,
.ms-tile-no-img,
.ms-tile-572-234 {
    min-width: 14.625rem; //234px
    max-height: 27.375rem; //437px
}
.ms-tile img {
    width: 23.5rem; //376px
    height: 9.625rem; //154px
}
.ms-tile-572-234 img {
    width: 35.75rem; //572px
    height: 14.625rem; //234px
}
.ms-tile-text-container {
    padding: 1.5rem;
    height: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.ms-tile-no-img .ms-tile-text-container {
    height: 95%;
}
.ms-tile-572-234 .ms-tile-text-container {
    height: 43%;
}
.ms-tile-text-container .ms-tile-text-desc {
    margin-top: 0.7rem;
    font-size: var(--msv-body-font-size-s);
    white-space: normal;
}

//ms-tile-list
.ms-tile-list {
    & > .ms-tile-list__heading {
        padding: 0;
        //Responsive Ui
        @media only screen and (max-width: $msv-container-max-width-l) {
            margin-left: 1.5rem;
        }
    }
    &-container {
        min-width: 17rem;
        max-width: 35.75rem;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 0.625rem;
        background-color: $white;
        padding-bottom: 1.5rem;
        overflow: hidden;
        @extend .moe-boxshadow;
        cursor: pointer;

        &:hover {
            text-decoration: none;
            & .ms-tile-top,
            & .ms-tile-bottom {
                background-color: var(--msv-tile-top-hover-color);
            }
        }
        //Small Mobile Responsive UI
        @media only screen and (max-width: $msv-breakpoint-xs) {
            min-width: 19rem;
        }
        //Mobile Responsive UI
        @media only screen and (min-width: $msv-container-min-width-s) and (max-width: $msv-container-max-width-m) {
            min-width: 21rem;
        }
        //Tablet to Base Desktop Responsive UI
        @media only screen and (min-width: $msv-breakpoint-l) and (max-width: $msv-breakpoint-ml) {
            min-width: 19.25rem;
        }
    }
    &__image {
        display: block;
        //min-height: 9.625rem;
        position: relative;
        padding: 0;
        background-color: $moe-gray-100;
        img {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
    &__heading {
        height: 100%;
        width: 100%;
        display: block;
        /*display: flex;
        flex-direction: column;
        align-items: baseline;
        align-content: center;
        flex-wrap: nowrap;
        justify-content: space-between;*/
        padding: 0 1.5rem;
        & h2,
        h3,
        h4 {
            font-size: 1.25rem;
        }
    }
    &__title {
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        line-height: 120%;
    }
    &__title,
    &__text {
        display: block;
        white-space: normal;
    }
}

//ms-tile
.ms-tile {
    &-container {
        display: flex;
        justify-content: center;
        align-items: stretch;
    }
    &-text-arrow {
        cursor: pointer;
        align-self: flex-end;
        position: absolute;
        bottom: 0;
        right: 1rem;
        padding: 1rem 0.5rem;
        & svg {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
    &__item {
        display: inline-flex;
        margin: 0.75rem 1.5rem 0.75rem 0;
        //Small Mobile Responsive UI
        @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
            margin: 0.75rem;
        }
    }
    &-top,
    &-bottom {
        height: 1rem;
        width: 100%;
        background-color: var(--moe-tile-bottom-background-color);
        border-radius: 0 0 0.625rem 0.625rem;
        transition: all 0.2s ease-in-out;
        display: flex;
        cursor: default;
        position: absolute;
        z-index: 1;
    }
    &-top {
        border-radius: 0.625rem 0.625rem 0 0;
        top: 0;
    }
    &-bottom {
        border-radius: 0 0 0.625rem 0.625rem;
        bottom: 0;
    }
}

//Tile List in Carousel
.msc-ss-carousel {
    & .msc-flipper.msc-ss-carousel__flipper {
        top: 50%;
        left: 1.5rem;
        font-size: 2rem; //32px
        border: rgba(0, 0, 0, 0);
        border-radius: 50%;
        background: var(--msv-bg-color);
        //Large Desktops
        @media only screen and (min-width: $msv-breakpoint-xl) {
            left: -2.25rem;
        }
        &--next {
            right: 1.5rem;
            left: auto;
            //Large Desktops
            @media only screen and (min-width: $msv-breakpoint-xl) {
                right: -2.25rem;
            }
        }
        &.disabled,
        &.disabled * {
            opacity: 0.5;
        }
        //Mobile and Tablet Responsive UI
        @media screen and (max-width: $msv-container-max-width-l) {
            top: auto !important;
            bottom: -0.5rem;
        }
    }
    //Small Mobile Responsive UI
    @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
        // fix: bug 102155 - fix carousel issue
        // Remove padding left and right
        // padding-left: 0.5rem;
        // padding-right: 0.5rem;

        // Override max-width over moe-section(max-width: 85%)
        max-width: 100% !important;
        // END: 102155
        padding-bottom: 4rem;
    }

    //Mobile and Tablet Responsive UI
    @media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-l) {
        // fix: bug 102155 - fix carousel issue
        // Remove padding left and right
        // padding-left: 1.5rem;
        // padding-right: 1.5rem;
        // END: 102155
        padding-bottom: 4rem;
    }
    //Large Desktops
    @media only screen and (min-width: $msv-breakpoint-xl) {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    &-slide {
        /* overflow: hidden;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px; */
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }
}

//Tiles different sizes
.tile-lists-desc-only-main-container,
.tile-lists-medium-img-main-container,
.tile-lists-large-img-main-container {
    padding: 0;
    margin-bottom: 2rem;
    //Large Desktops
    @media only screen and (min-width: $msv-breakpoint-xl) {
        max-width: 87.5rem !important;
    }    
    //Mobile and Tablet Responsive UI
    @media screen and (max-width: $msv-container-max-width-l) {
        margin-bottom: 0;
    }
}

.moe-tile-desc-only-container,
.moe-tile-medium-image-container,
.moe-tile-large-image-container {
    height: 100%;
    .msc-ss-carousel-slide li > div:first-child {
        height: 100%;
    }
    & .ms-tile-list-container {
        //fix: bug 102268 & 102269
        transition: ease-in-out .3s;
        
        &:hover {
            transform: translateY(-5px);
        }
        //END
        @media screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-s) {
            min-width: 18.625rem; //298px
        }
    }
}

//Text only tile specfic
.moe-tile-desc-only-container {
    .ms-tile-list {
        &-container {
            width: 21.375rem; //342px
            padding-top: 1rem;
            & .ms-tile-list__title {
                font-size: 1rem;
                font-weight: 700;
            }
            //Responsive UI
            @media screen and (max-width: $msv-breakpoint-mxl) {
                width: 19rem; //304px
            }
        }
        &__heading {
            padding-bottom: 1.5rem;
        }
        &-item-container {
            height: 100%;
        }
    }
}

//Text and Medium Image tile specfic
.moe-tile-medium-image-container {
    & .ms-tile-list {
        &-container {
            width: 28.125rem !important; //450px
            height: 31.25rem; //500px
            //Mobile, Tablet and Base Desktop Responsive UI
            @media only screen and (max-width: $msv-container-max-width-l) {
                width: 21rem !important;
                height: 26rem;
            }
        }
        &__image {
            width: 100%;
            height: 15rem; //240px
            //Mobile, Tablet and Base Desktop Responsive UI
            @media only screen and (max-width: $msv-container-max-width-l) {
                height: 11rem;
            }
            overflow: hidden;
            img {
                width: auto;
                height: 100%;
                margin: 0 auto;
            }
        }
        &__heading {
            height: auto;
            max-height: 12.5rem;
            overflow: hidden;
        }
        &__title {
            margin-top: 1rem;
        }
    }
}

//Text and Large Image tile specfic
.moe-tile-large-image-container {
    & .ms-tile-list {
        &-container {
            width: 35.75rem !important; //572px
            //Mobile, Tablet and Base Desktop Responsive UI
            /*@media only screen and (max-width: $msv-container-max-width-l) {
                width: auto;
            }*/
        }
        &__image {
            width: 100%;
            height: 14.625rem; //234px
            overflow: hidden;
            img {
                width: 100%;
                height: auto;
            }
        }
        &__title {
            margin-top: 1rem;
        }
    }
}

// For larger devices desc only title move left
.tile-lists-desc-only-main-container {
    .moe-nzc-och-home-tile-with-desc {
        & > .ms-tile-list__heading {
            @media only screen and (min-width: $msv-breakpoint-xl) {
                padding-left: 0;
            }
        }
    }
    //Large Desktops
     & .ms-tile__item {
        @media only screen and (min-width: $msv-breakpoint-xl) {
            margin: 0.25rem;
        }
    }
}

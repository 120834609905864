//moe active image
.msc-active-image {
    max-width: $msv-breakpoint-xl;
    padding: 0;
    margin: 0 auto 2rem auto;
    &-container {
        picture {
            width: 100%;
        }
        //Mobile Responsive UI
        @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
        max-width: 18.75rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        }
    }
    & h1, h2, h3 {
        font-size: 1.25rem;
    }
    * {
        font-size: 1rem;
    }
}
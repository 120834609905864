//moe-navigation-menu

//Base theme override for MOE sites
.ms-nav .ms-nav__deafult {
    position: relative;
}
.ms-nav {
    &__list {
        & .level-2 > li,
        & .ms-nav__list__item__button,
        & .ms-nav__list__item__link,
        & .havesubmenu {
            font-weight: normal !important;
        } 
    }   
}
.ms-nav .ms-nav__deafult > .ms-nav__list {
    display: flex;
    flex-direction: column;
    //fix: bug 99580 - add background color to gap between link items
    background: white;

    .ms-nav__list__item {
        .ms-nav__list__item__span,
        .havesubmenu .ms-nav__list__item__link {
            cursor: pointer;
        }
    }

    .havesubmenu {
        height: auto;

        .show {
            transition: all 0.3s;
        }

        .collapse {
            transition: all 0.3s;
        }

        .ms-nav__deafult {
            padding-left: 0.5rem;
            margin-top: -1rem;
            @media screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-container-max-width-l) {
                margin-top: -0.3rem; //Bug 91468 Fix
            }
            ul > li {
                height: 33px;
            }

            ul > li:last-child {
                height: auto;
            }
        }
    }

    .havesubmenu .submenu_link {
        display: inline-flex;
        //fix: bug 99580 - focus outline cutoff fixed
        --nzc-nav-submenu-gap: 0.25em;
        align-items: center;
        gap: var(--nzc-nav-submenu-gap);

        button.ms-nav__drawer-close__glyph__end {
            outline-offset: -4px;
            margin-block-end: 0.2em;
        }
        //fix: bug 99580 END
    }
}

.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link,
.ms-nav__list__item__span {
    //color: $moe-grey;
    color: black;
    transition: all 0.3s;

    &:hover {
        color: black;
    }
}

.ms-nav__drawer-close__glyph__end {
    cursor: pointer;
    background: transparent;
    border: none;
}

.ms-nav__drawer-close__glyph__end::after {
    @include msv-icon();
    content: $msv-ChevronRight;
    display: inline-flex;
    //commented below line for fix: bug 99580 - focus outline cutoff fixed
    // line-height: $navigation-menu-item-line-height;
    text-align: right;
    margin-right: 0.8rem;
    transition: all 0.3s;
}

.ms-nav__drawer_rotate {
    &::after {
        transform: rotate(90deg);
    }
}

ul.ms-nav__list > li {
    display: flex;
    flex-direction: column;
}

//Responsive UI
@media screen and (min-width: $msv-breakpoint-l) {
    .ms-nav > .ms-nav__list > .ms-nav__list__item {
        margin-right: 0;
        margin-top: 0;
    }

    .ms-nav > .ms-nav__list {
        flex-wrap: nowrap !important;
    }
}

//Base Desktop to medium Desktop Responsive UI
.desktop-vp {
    &.ms-nav {
        & > .ms-nav__list {
            &:first-child {
                & > .ms-nav__list__item {
                    & .ms-nav__list__item {
                        &__button,
                        &__link {
                            @media screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-container-max-width-l) {
                                width: auto !important;
                                padding: 0.625rem 0.5rem !important;
                                height: auto;
                                white-space: normal;
                                line-height: 120%;
                                align-items: center;
                                //fix: bug 99580 - focus outline cutoff fixed
                                margin-block-end: 0.2em;
                                margin-inline: 0.25em;
                                //END
                            }
                        }
                    }
                }
            }
        }
    }
}
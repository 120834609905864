//kwr-tabs
//Base Tabs classes override for KWR Tabs
.ms-tab-tabs-section-li[aria-selected="true"] {
    ::before {
        height: 0.25rem;
        background-color: $color-red;
    }
} 
//Responsive UI from Mobile to medium Desktops
@media only screen and (max-width: $msv-breakpoint-mxl) {
    .ms-tab-tabs-section {
        flex-wrap: wrap;
        &-li {
            white-space: nowrap;
        }
    }    
}

//kwr Tabs
.kwr-tabs {
    &-wrap {
        &--dark {
            background-color: transparent;
            .ms-tab {
                &-header-section__heading,
                &-tabs-section-li,
                &-item-header,
                &-tabs-section-li[aria-selected="true"] .ms-tab-item-header,
                &-content-section,
                & .ms-content-block__details,
                & .ms-content-block__title,
                & .ms-text-block {
                    color: $white;
                }
                &-tabs-section {
                    position: relative;
                    border-bottom: 0.25rem solid $color-grey-darkest;
                    &-li {
                        padding: 1rem;
                        background-color: transparent;
                        overflow: visible;                     
                        & .ms-tab-item-header {
                            text-transform: uppercase;
                        }
                        &[aria-selected="true"] {
                            background-color: transparent;
                            ::before { 
                                height: 0.25rem;                 
                                background-color: $color-red !important;
                                bottom: -0.25rem;
                            }
                        }
                    }
                }
                &-content-section {
                    background-color: transparent;
                    & .ms-content-block__details {
                        height: auto !important;
                    }
                }
            }
        }
    }

    //Tabs with red underline selected state
    &--underline {
        border-bottom: 0.25rem solid $color-grey-darkest;
        margin-bottom: 1.5rem;
        & .moe-tabs__item,
        & .ms-search-result-container__no-results-message {
            color: $white;
        }
        & .moe-tabs__item {
            border-bottom: 0.25rem solid transparent;
            padding: 1rem;
            margin-bottom: 0;
            position: relative;  
            bottom: -0.25rem;          
            &.active {
                border-bottom: 0.25rem solid $color-red;
            }
        }
    }
}

//fix: bug 99565 - fix tab no focus issue
.ms-tab-tabs-section-li[aria-selected="true"]:focus-visible {
    outline: 1px solid;
}
// END
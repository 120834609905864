/*
* Config for the styles
*/
// --------  opacities  --------

$opacity-semi-translucent: 0.02;
$opacity-translucent: 0.35;
$opacity-very-translucent: 0.6;
$opacity-semi-transparent: 0.8;

// --------  colors  --------

$color-black: #000;
$color-white: #fff;
$color-grey-lighter: #f8f4f6;
$color-grey-light: #96929a;
$color-grey-midlight: #6e6a76;
$color-grey-mid: #27253e;
$color-grey: #ccc;
$color-grey-dark: #1e1c34; // previously #2A282D
$color-grey-darkest: #151324; // previously #1A181D
$color-grey-nearblack: #131424; // previously #1A181D
$color-blue-light: #58cdef;
$color-blue: #0099d9;
$color-blue-dark: #055f85;
$color-purple-light: #ba94ed;
$color-purple: #895bc7;
$color-orange: #fe9500;
$color-red: #dd2246;
$color-green-light: #46c655;
$color-green: #00a914;
$color-background: $color-white;
$color-background-blue: lighten($color-blue-light, 30%);
$color-background-grey: $color-grey-lighter;
$color-background-green: lighten($color-green-light, 43%);
$color-purple-lighter: #b99ebc;
$color-purple-mid: #895a87;
$color-navy: #2a356a;

$color-ranginui: #5fb2de;
$color-ranginui-rgba-95: rgba(95, 178, 222, 0.95);
$color-ranginui-rgba-75: rgba(95, 178, 222, 0.75);
$color-ranginui-rgba-65: rgba(95, 178, 222, 0.65);
$color-ranginui-rgba-50: rgba(95, 178, 222, 0.5);
$color-ranginui-rgba-25: rgba(95, 178, 222, 0.25);
$color-ranginui-rgba-0: rgba(95, 178, 222, 0);
$color-papatuanuku: #7cb141;
$color-papatuanuku-rgba-95: rgba(124, 177, 65, 0.95);
$color-papatuanuku-rgba-75: rgba(124, 177, 65, 0.75);
$color-papatuanuku-rgba-65: rgba(124, 177, 65, 0.65);
$color-papatuanuku-rgba-50: rgba(124, 177, 65, 0.5);
$color-papatuanuku-rgba-25: rgba(124, 177, 65, 0.25);
$color-papatuanuku-rgba-0: rgba(124, 177, 65, 0);
$color-tangaroa: #09518a;
$color-tangaroa-rgba-95: rgba(9, 81, 138, 0.95);
$color-tangaroa-rgba-75: rgba(9, 81, 138, 0.75);
$color-tangaroa-rgba-65: rgba(9, 81, 138, 0.65);
$color-tangaroa-rgba-50: rgba(9, 81, 138, 0.5);
$color-tangaroa-rgba-25: rgba(9, 81, 138, 0.25);
$color-tangaroa-rgba-0: rgba(9, 81, 138, 0);
$color-tane: #259d5c;
$color-tane-rgba-95: rgba(37, 157, 92, 0.95);
$color-tane-rgba-75: rgba(37, 157, 92, 0.75);
$color-tane-rgba-65: rgba(37, 157, 92, 0.65);
$color-tane-rgba-50: rgba(37, 157, 92, 0.5);
$color-tane-rgba-25: rgba(37, 157, 92, 0.25);
$color-tane-rgba-0: rgba(37, 157, 92, 0);
$color-tumatauenga: #dd2246;
$color-tumatauenga-rgba-95: rgba(221, 34, 70, 0.95);
$color-tumatauenga-rgba-75: rgba(221, 34, 70, 0.75);
$color-tumatauenga-rgba-65: rgba(221, 34, 70, 0.65);
$color-tumatauenga-rgba-50: rgba(221, 34, 70, 0.5);
$color-tumatauenga-rgba-25: rgba(221, 34, 70, 0.25);
$color-tumatauenga-rgba-0: rgba(221, 34, 70, 0);
$color-rongo: #e8c634;
$color-rongo-rgba-95: rgba(232, 198, 52, 0.95);
$color-rongo-rgba-75: rgba(232, 198, 52, 0.75);
$color-rongo-rgba-65: rgba(232, 198, 52, 0.65);
$color-rongo-rgba-50: rgba(232, 198, 52, 0.5);
$color-rongo-rgba-25: rgba(232, 198, 52, 0.25);
$color-rongo-rgba-0: rgba(232, 198, 52, 0);
$color-haumia: #895f49;
$color-haumia-rgba-95: rgba(137, 95, 73, 0.95);
$color-haumia-rgba-75: rgba(137, 95, 73, 0.75);
$color-haumia-rgba-65: rgba(137, 95, 73, 0.65);
$color-haumia-rgba-50: rgba(137, 95, 73, 0.5);
$color-haumia-rgba-25: rgba(137, 95, 73, 0.25);
$color-haumia-rgba-0: rgba(137, 95, 73, 0);
$color-tawhirimatea: #3e4247;
$color-tawhirimatea-rgba-95: rgba(62, 66, 71, 0.95);
$color-tawhirimatea-rgba-75: rgba(62, 66, 71, 0.75);
$color-tawhirimatea-rgba-65: rgba(62, 66, 71, 0.65);
$color-tawhirimatea-rgba-50: rgba(62, 66, 71, 0.5);
$color-tawhirimatea-rgba-25: rgba(62, 66, 71, 0.25);
$color-tawhirimatea-rgba-0: rgba(62, 66, 71, 0);
$color-hineahuone: #ce5633;
$color-hineahuone-rgba-95: rgba(206, 86, 51, 0.95);
$color-hineahuone-rgba-75: rgba(206, 86, 51, 0.75);
$color-hineahuone-rgba-65: rgba(206, 86, 51, 0.65);
$color-hineahuone-rgba-50: rgba(206, 86, 51, 0.5);
$color-hineahuone-rgba-25: rgba(206, 86, 51, 0.25);
$color-hineahuone-rgba-0: rgba(206, 86, 51, 0);
$color-whiro: #1f6b6e;
$color-whiro-rgba-95: rgba(31, 107, 110, 0.95);
$color-whiro-rgba-75: rgba(31, 107, 110, 0.75);
$color-whiro-rgba-65: rgba(31, 107, 110, 0.65);
$color-whiro-rgba-50: rgba(31, 107, 110, 0.5);
$color-whiro-rgba-25: rgba(31, 107, 110, 0.25);
$color-whiro-rgba-0: rgba(31, 107, 110, 0);
$color-ruaumoko: #e88a2b;
$color-ruaumoko-rgba-95: rgba(232, 138, 43, 0.95);
$color-ruaumoko-rgba-75: rgba(232, 138, 43, 0.75);
$color-ruaumoko-rgba-65: rgba(232, 138, 43, 0.65);
$color-ruaumoko-rgba-50: rgba(232, 138, 43, 0.5);
$color-ruaumoko-rgba-25: rgba(232, 138, 43, 0.25);
$color-ruaumoko-rgba-0: rgba(232, 138, 43, 0);

$color-kerepi: #413958;
$color-kerepi-warm: #562754;

$color-text: $color-grey-darkest;
$color-text-light: $color-grey-lighter;

$color-chrome-text: $color-grey-lighter;
$color-chrome-text-dark: $color-grey-dark;
$color-chrome-light: $color-grey-light;
$color-chrome-light-translucent: transparentize(
  $color-chrome-light,
  $opacity-translucent
);
$color-chrome: $color-grey-mid;
$color-chrome-dark: $color-grey-darkest;
$color-chrome-dark-semi-transparent: transparentize(
  $color-chrome-dark,
  $opacity-semi-transparent
);
$color-chrome-dark-translucent: transparentize(
  $color-chrome-dark,
  $opacity-translucent
);
$color-chrome-dark-semi-translucent: transparentize(
  $color-chrome-dark,
  $opacity-semi-translucent
);

$color-primary: $color-red;
$color-primary-dark: darken($color-red, 5%);

$color-highlight-light: $color-blue-light;
$color-highlight: $color-blue;
$color-highlight-dark: $color-blue-dark;

$colour-warning: $color-orange;

// social media colours
$color-facebook: #3b5998;
$color-twitter: #32a7d3;

$color-footer-background-color: #131424;

$color-homepage-nav-text: #d9d6df;

// --------  gradients  --------
$gradient-dark-overlay-left: linear-gradient(
  to right,
  transparent,
  $color-chrome-dark-semi-translucent 5vw,
  $color-chrome-dark-semi-translucent
);
$gradient-shadow-left: linear-gradient(
  to right,
  transparent,
  transparentize($color-black, $opacity-very-translucent)
);

// --------  font  --------

$font-family-base: 'Montserrat', Helvetica, sans-serif;
$font-family-serif: 'Montserrat', Jubilat, serif;

/* stylelint-disable-next-line unit-blacklist */
$font-size-base: 20px;
/* stylelint-disable-next-line unit-blacklist */
$font-size-base-small: 16px;
/* stylelint-disable-next-line unit-blacklist */
$font-size-base-standard: 18px;
/* stylelint-disable-next-line unit-blacklist */
$font-size-base-large: 20px;
/* stylelint-disable-next-line unit-blacklist */
$font-size-base-large: 20px;
/* stylelint-disable-next-line unit-blacklist */
$slide-mobile-portrait-width: 200px;
/* stylelint-disable-next-line unit-blacklist */
$slide-mobile-portrait-height: 356px;
/* stylelint-disable-next-line unit-blacklist */
$slide-mobile-landscape-width: 356px;
/* stylelint-disable-next-line unit-blacklist */
$slide-mobile-landscape-height: 200px;
/* stylelint-disable-next-line unit-blacklist */
$slide-tablet-portrait-width: 420px;
/* stylelint-disable-next-line unit-blacklist */
$slide-tablet-portrait-height: 546px;
/* stylelint-disable-next-line unit-blacklist */
$slide-tablet-landscape-width: 546px;
/* stylelint-disable-next-line unit-blacklist */
$slide-tablet-landscape-height: 420px;
/* stylelint-disable-next-line unit-blacklist */
$scroll-snap-points-x: 300px;

$font-size-xxxsmall: 0.56rem;
$font-size-xxsmall: 0.65rem;
$font-size-xsmall: 0.7rem;
$font-size-small: 0.8rem;
$font-size-standard: $font-size-base;
$font-size-medium: 1.2rem;
$font-size-large: 1.5rem;
$font-size-xlarge: 1.8rem;
$font-size-xxlarge: 2.8rem;
$font-size-logo: 1.8rem;

$line-height-base: 1.6;
$line-height-mid: 1.3;
$line-height-large: 1.1;
$line-height-button: 1.4;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-heavy: 800;

$heading-letter-spacing-1: -0.1rem;
$heading-letter-spacing-2: -0.08rem;
$heading-letter-spacing-3: -0.05rem;

// --------  sizes  --------

$gap-base: 2rem;

// todo move to utilities once depricated variables are removed
// use a multiple of the default gap size
@function gap-size($multiplier: 1) {
  @return $gap-base * $multiplier;
}

// Gap variables - deprecated included to prevent merge issues - use gap-size function directly
$gap-triple: gap-size(3);
$gap-double: gap-size(2);
$gap-full: gap-size(1);
$gap-three-quarters: gap-size(3/4);
$gap-half: gap-size(1/2);
$gap-quarter: gap-size(1/4);
$gap-eighth: gap-size(1/8);

$icon-size-inline: 1.4em; // relative to parent element font size
$icon-size-small: 1.15rem;
$icon-size-xs: 0.9rem;
$icon-size: 2.5rem;

$size-site-gutter: 3rem;
$size-side-nav: 17rem;
$size-side-nav-col-width: 3rem;
$size-side-nav-drawer: 18rem;
$size-footer-height: 15rem;

// --------  screens  --------

$screen-xxs-min: 28rem;
$screen-xs-min: 38rem;
$screen-sm-min: 44rem;
$screen-md-min: 52rem;
$screen-ml-min: 62rem;
$screen-lg-min: 74rem;
$screen-xl-min: 86rem;
$screen-xxl-min: 96rem;
$screen-xxxl-min: 104rem;
/* stylelint-disable unit-blacklist */
$screen-full-nav: 1280px;
$screen-max-container-width: 1600px;
/* stylelint-enable unit-blacklist */

// --------  containers  --------

$container-narrow: 41rem;
$container-standard: 49rem;
$container-large: 60rem;

// --------  ratios --------
$ratio-16x9: 56.25%;

// --------  shadows  --------

$shadow-standard: 0 0.1rem 0 0 $color-black;
$shadow-inset: $shadow-standard inset;

// --------  border  --------

/* stylelint-disable-next-line unit-blacklist */
$border-width: 1px;
$border-width-accent: 0.4rem;
$border-width-button: 0.2rem;
$border-standard: $border-width $color-chrome-dark solid;
$border-button: $border-width-button $color-primary solid;
$border-light: $border-width $color-chrome-light solid;
$border-solid-dark: 0.25rem $color-grey-darkest solid;
$border-accent: $border-width-accent $color-primary solid;
$border-divider: $border-width-accent $color-grey-lighter solid;
$border-radius-rounded: 0.5rem;
$border-radius-button: 0.25rem;
$border-radius-rounded-inner: 0.25rem;

// --------  transitions  --------

$transition-all: all 150ms ease-in;
$transition-slide: transform 150ms ease-in-out;
$transition-draw: max-width 500ms ease-in-out;
$transition-fade: opacity 150ms ease-in-out;

// --------  print  --------

$print-page: a4 !default;
$print-margin: 1.5cm 1cm !default;
$print-orphans: 3 !default;
$print-widows: 3 !default;

// --------- z-index --------
$z-index-homepage-hills: 2;
$z-index-header: 10;
$z-index-hamburger: 11;
$z-index-full-screen: 14; // full screen menu
$z-index-main: 7;
$z-index-sidenav: 2; // in relation to main
$z-index-footer: 8;
$z-index-mobile-header-control: 3;

// --------- header --------
$header-height: 3.35rem;

@mixin caption {
  font-family: $font-family-serif;
  color: $color-chrome-text-dark;
  margin: 1.5rem auto 0 auto;
  line-height: 1.3;
  font-style: italic;
  text-align: center;
}

@mixin luxPluginContent {
  display: none;
  padding-bottom: 3rem;

  &.toggle--open {
    display: block;
  }
}

@mixin subtitle {
  color: $color-grey-midlight;
  font-family: $font-family-serif;
  font-size: $font-size-large;
  line-height: 1.1;
  margin-top: -0.5rem;
}

@mixin luxPluginToggle {
  color: $color-red;
  font-weight: bold;
  font-size: 0.65rem;
  text-transform: uppercase;
  display: block;
  text-align: right;
  padding: 1.5rem 0;
  cursor: pointer;
  border-radius: 0.15rem;

  .icon--next {
    margin-left: 0.25rem;
    transform: rotate(90deg);
    transition: transform 0.1s ease-out;
  }

  &.toggle--open {
    .icon--next {
      transform: rotate(-90deg);
    }
  }
}

$block-spacing: 3.5rem;
$block-top-padding: $block-spacing 0 0 0;
$block-horizontal-spacing-mobile: 1.667rem; // 30px
$block-horizontal-spacing: 2.778rem; // 50px

// --------- card --------
$card-width: 11rem;
$card-height: 16.5rem;
$card-margin: 1.2rem 1.2rem 0 0 !important;

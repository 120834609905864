//moe video player

.ms-video {
    &-player,
    &-transcript {
        background-color: $white;
        p {
            font-size: 14px;
        }
        a {            
            text-decoration: underline;
        }
    }
    &-player {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto 2rem auto;   
        & h1, h2, h3 {
            font-size: 1.25rem;
        }
        &__details {
            padding: 1.5rem;
            background-color: #707070;
            color: $white;
            &__additional-content__text {
                font-weight: bold;
                font-size: 1rem !important;
                margin-bottom: 0.8rem;
            }
            & a {
                color: $white;
            }
        }
    }
    &__title-container,
    &-transcript {
        border: 1px solid #707070;
        padding: 1.5rem !important;
    }
}
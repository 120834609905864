:root {
    // Headings
    --msv-footer-heading-font-color: white;
    --msv-footer-heading-font-size: 10px;

    //Links
    --msv-footer-link-font-color: white;
    --msv-footer-link-font-size: 10px;
}

footer {
    background-color: var(--msv-footer-primary-bg-color);
    /*.moe-footer {
        & >*,
        & >*>* {
        padding-bottom: 0;
        }
        & > div {
            display: flex;
            flex-direction: column;
            align-content: center;
            min-width: 100%;
            padding: 0;
        }
        &__link div,
        &__link__text,
        & .ms-text-block {
            font-size: var(--msv-footer-link-font-size);
            font-size: 13px;
            color: var(--msv-footer-link-font-color);
        }
        &__heading__title {

            font-size: var(--msv-footer-heading-font-size);
            font-size: 14px;
            color: var(--msv-footer-heading-font-color);
        }
        & > * > *:nth-child(4) {
            & .ms-footer {
                &__list {
                    flex-wrap: wrap;
                    & .ms-footer__list-item:first-child {
                        position: relative;
                        width: auto;
                    }
                    & .ms-footer__list-item:nth-child(2) {
                        top: 0;
                    }
                    & .ms-footer__list-item:nth-child(3),
                    & .ms-footer__list-item:nth-child(4) {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
        & > * > *,
        & > * > *:last-child {
            max-width: none;
        }

    }
    & .footer-item-temahau-logo img {
        width: auto;
        height: 100px;
    }
    & .footer-item-moe-logo {
        margin-top: 11px;
        & img {
            width: auto;
            height: 86px;
            //Mobile Responsiveness
            @media screen and (max-width: $msv-breakpoint-m) {
                height: 4rem;
            }
        }
    }
    & .footer-second-containers {
        padding-top: 22px;
        & > div {
            width: 100%;
            align-items: center;
        }
    }
    & .footer-second-container-second-section {
        float: right;
    }*/
}

.footer-social-share {
    .ms-social-share-label {
        display: none !important;
    }
}

.moe-footer {
    & .ms-footer__link div,
    & .ms-footer__link__text,
    & .ms-text-block {
        font-size: var(--msv-footer-link-font-size);
        font-size: 13px;
        color: var(--msv-footer-link-font-color);
    }
    & .ms-footer__heading__title {
        font-size: var(--msv-footer-heading-font-size);
        font-size: 14px;
        color: var(--msv-footer-heading-font-color);
    }

    &__container-grey,
    &__container-black {
        padding: 1.5rem !important;
        & * {
            color: $white;
        }
        //Large Desktops
        @media only screen and (min-width: $msv-container-min-width-xl) {
            padding: 1.5rem 0 !important;
        }
    }
    &__container-grey {
        background: var(--msv-footer-secondary-bg-color);
        min-width: 100%;
    }
    &__row1 {
        border-bottom: 1px solid #49515c;
        width: 100%;
        margin-bottom: 1rem;
        padding-bottom: 1.25rem;
        //Mobile Responsiveness
        @media screen and (max-width: $msv-breakpoint-m) {
            & .row {
                & .col-4,
                .col-6,
                .col-8 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
        & .moe-footer__col2 {
            //Mobile and Tablet Responsive UI
            @media screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-mxl) {
                & > .row {
                    justify-content: flex-end;
                }
            }
            /*& .ms-footer__list,
            & .ms-footer__list-item {
                width: fit-content;
            }*/
            & .ms-footer__list{
                    &-item {
                        margin-right: 1rem;
                    }
                    //Mobile and Tablet Responsive UI
                    @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                        flex-direction: column;
                    }
                }
            & div[class^='col'] {
                flex: auto;
                max-width: unset;
                width: auto;
                //Mobile and Tablet Responsive UI
                @media screen and (max-width: $msv-breakpoint-m) {
                    width: 90%;
                }
            }

            @media only screen and (min-width: $msv-breakpoint-mxl) {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    &__row2 {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        //Mobile Responsiveness
        @media (min-width: $msv-breakpoint-xs), (min-width: $msv-container-min-width-sm) {
            & .col-sm-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        @media screen and (min-width: $msv-breakpoint-m) {
            & .col-sm-6 {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
        & .moe-footer__col1 {
            & > div > div,
            & .row .col-md-3 {
                flex: 0 0 100%;
                max-width: 100%;
                & .ms-footer {
                    &__list {
                        display: flex;
                        align-items: center;
                        height: 100%;
                        &-item {
                            margin-right: 3rem;
                        }
                        //Mobile Responsiveness
                        @media (max-width: $msv-breakpoint-m) {
                            display: block;
                        }
                    }
                }
            }
            & .ms-footer__link {
                white-space: nowrap;
            }
        }
    }
    &__col1,
    &__search {
        height: 100%;
        & .row {
            height: 100%;
        }
    }
    &__imgs {
        &.ms-footer__list {
            display: flex;
            flex-wrap: wrap;
            //Mobile Responsiveness
            @media only screen and (max-width: $msv-breakpoint-m) {
                margin-bottom: 2rem;
            }
            & .ms-footer__list-item {
                margin-right: 6.25rem;
                //Mobile Responsiveness
                @media (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-ml) {
                    margin-right: 1.5rem;
                    width: 100%;
                }
                //Mobile and Tablet Responsive UI
                @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                    margin-right: 2.5rem;
                }
            }
        }
    }
    & .temahau-logo img {
        width: auto;
        height: 6.25rem;
        //Mobile and Tablet Responsive UI
        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
            height: 5rem;
        }
    }
    & .moe-logo {
        margin-top: 0.6875rem;
        & img {
            width: auto;
            height: 5.375rem;
            //Mobile Responsiveness
            @media screen and (max-width: $msv-breakpoint-m) {
                height: 4rem;
            }
            //Mobile and Tablet Responsive UI
            @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                height: 5rem;
            }
        }
    }
    & .ms-search {
        margin: 4rem 1.5rem 1.5rem 0;
        min-width: 25rem;
        //Mobile Responsiveness
        @media screen and (max-width: $msv-breakpoint-m) {
            min-width: calc(100% - 1.5rem);
        }
        &__searchForm {
            margin: 0;
        }
    }
    & .ms-social-share {
        display: flex;
        justify-content: flex-end;

        //Mobile Responsiveness
        @media only screen and (max-width: $msv-breakpoint-m) {
            justify-content: flex-start;
            margin-top: 2rem;
        }
    }
    &__list li.ms-footer__list-item {
        font-size: 0.8rem;
        //Mobile Responsiveness
        @media only screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }
    &__container-black {
        & .ms-text-block {
            font-size: 1rem;
            color: $white;
            & > div {
                align-items: center;
            }
        }
        @media screen and (max-width: $msv-breakpoint-s) {
            & .row {
                & .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    text-align: center;
                }
            }
        }
        & .nz-gov-logo {
            float: right;
            & img,
            & .msc_image {
                height: 6rem;
                width: auto;
            }
            //Mobile Responsiveness
            @media only screen and (max-width: $msv-breakpoint-m) {
                float: none;
                & img,
                & .msc_image {
                    height: 5rem;
                }
            }
        }
    }
    &-social {
        display: flex;
        justify-content: flex-end;
        //Mobile Responsiveness
        @media only screen and (max-width: $msv-breakpoint-m) {
            justify-content: flex-start;
            margin-top: 2rem;
        }
        & .ms-footer {
            &__item {
                margin-right: 1.5rem;
                padding-right: 0;
            }
            &__link {
                height: 2rem;
                width: 2rem;
                & img,
                svg {
                    height: 2rem;
                    width: auto;
                }
            }
        }
    }

    //Single List group as row
    & .moe-list-row {
        & .col-md-3,
        & div[class^='col'] {
            flex: 0 0 auto;
            max-width: max-content;
        }
        & .ms-footer__list {
            display: flex;
            // Mobile Responsive UI
            @media (max-width: $msv-breakpoint-m) {
                flex-wrap: wrap;
            }
            &-item {
                margin-right: 2rem;
            }
        }
    }

    //need to update - for temporary
    .ms-back-to-top {
        display: none;
    }

    @media only screen and (max-width: $msv-breakpoint-m) {
        .ms-back-to-top {
            padding: 0;
            display: inline-block;
            border: none;
            border-radius: 4px;
            outline: none;
            position: fixed;
            bottom: 138px;
            right: 24px;
            opacity: 0;
            background-color: #000;
            align-items: center;
            width: 3rem;
            height: 3rem;
            z-index: 99;
            /* align-items: center; */
            display: flex;
            justify-content: center;

            &[aria-hidden='false'] {
                opacity: 0.43;
            }

            // font-size: 1.1rem;

            &::before {
                @include msv-icon();
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-weight: 900;
                text-rendering: auto;
                line-height: 1;
                font-size: 2rem;
                /* width: 1.5rem; */
                content: $moe-backtotop;
                -webkit-font-smoothing: antialiased;
                /* display: inline-block; */
                font-style: normal;
                font-variant: normal;
                font-weight: normal;
                /* text-rendering: auto; */
                /* line-height: 1; */
                font-weight: 900;
                /* margin-left: 6px; */
            }
        }
    }
}

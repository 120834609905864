//Key frames for Characters animation
$moe-images-path: '../../../images';

//Opening Frames
@keyframes korowaiOpeningFrame1{
    0%{
        opacity:1
    }
    40%{
        opacity:0
    }
    to{
        opacity:0
    }
}
@keyframes korowaiOpeningFrame2{
    20%{
        opacity:0
    }
    40%{
        opacity:1
    }
    to{
        opacity:0
    }
}
@keyframes korowaiOpeningFrame3{
    40%{
        opacity:0
    }
    60%{
        opacity:1
    }
    to{
        opacity:0
    }
}
@keyframes korowaiOpeningFrame4{
    60%{
        opacity:0
    }
    80%{
        opacity:1
    }
    to{
        opacity:0
    }
}
@keyframes korowaiOpeningFrame5{
    80%{
        opacity:0
    }
    to{
        opacity:1
    }
}

//Closing Frames
@keyframes korowaiClosingFrame5{
    0%{
        opacity:1
    }
    20%{
        opacity:0
    }
    to{
        opacity:0
    }
}
@keyframes korowaiClosingFrame4{
    0%{
        opacity:0
    }
    20%{
        opacity:1
    }
    40%{
        opacity:0
    }
    to{
        opacity:0
    }
}
@keyframes korowaiClosingFrame3{
    0%{
        opacity:0
    }
    40%{
        opacity:1
    }
    60%{
        opacity:0
    }
    to{
        opacity:0
    }
}
@keyframes korowaiClosingFrame2{
    0%{
        opacity:0
    }
    60%{
        opacity:1
    }
    80%{
        opacity:0
    }
    to{
        opacity:0
    }
}
@keyframes korowaiClosingFrame1{
    0%{
        opacity:0
    }
    60%{
        opacity:1
    }
    to{
        opacity:1
    }
}

//Base Character Styles
.atua__backdrop {
    display: block;
    width: 100%;
    height: 100vh;
    transition: opacity .2s;
    position: absolute;
    top: 0;
    pointer-events: none;
    opacity: 0;    
    background-repeat: no-repeat !important;
    background-position: 0 3rem !important;
    background-size: calc(100% - 23rem) !important;
}
/*.search-page__overlay {
    background: rgba(42,47,56,.75);
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
}*/

/*.atua__backdrop{
    transition:opacity .2s;
    display:block;
    width:100%;
    background-repeat:no-repeat;
    background-size:calc(100% - 23rem);
    position:absolute;
    top:0;
    background-position:0 3rem;
    pointer-events:none;
    opacity:0;
    height:100vh
}*/

@media (min-width:52rem) and (max-width:74rem){
    .atua__backdrop{
        height:150vh
    }
}
@media (min-width:74rem){
    .atua__backdrop{
        height:200vh
    }
}
@media (max-width:44rem){
    .atua__backdrop{
        background-image:none!important
    }
}
@media (min-width:44rem) and (max-width:52rem){
    .atua__backdrop{
        background-size:100%
    }
}
.atua__backdrop--frame1{
    opacity:1
}

.page--atuaLandingPage {
    //Opening
    &--korowaiOpening {
        & .atua__backdrop--frame1{
            animation:korowaiOpeningFrame1 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame2{
            animation:korowaiOpeningFrame2 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame3{
            animation:korowaiOpeningFrame3 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame4{
            animation:korowaiOpeningFrame4 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame5{
            animation:korowaiOpeningFrame5 .5s ease-in .4s forwards
        }
    }

    //Closing
    &--korowaiClosing {
        & .atua__backdrop--frame1{
            opacity:0;
            animation:korowaiClosingFrame1 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame2{
            opacity:0;
            animation:korowaiClosingFrame2 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame3{
            opacity:0;
            animation:korowaiClosingFrame3 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame4{
            opacity:0;
            animation:korowaiClosingFrame4 .5s ease-in .4s forwards
        }
        & .atua__backdrop--frame5{
            opacity:1;
            animation:korowaiClosingFrame5 .5s ease-in .4s forwards
        }
    }
}

//Papa Character
.kwr-atua {
    background-color: $color-grey-mid;
    position: relative;
    z-index: 12;
    &-haumia {
        & .atua__backdrop--frame1 {
            background: imageHaumia('Haumia_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageHaumia('Haumia_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageHaumia('Haumia_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageHaumia('Haumia_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageHaumia('Haumia_phase5.png') no-repeat;
        }
    }
    &-hineahuone {
        & .atua__backdrop--frame1 {
            background: imageHineahuone('Hine_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageHineahuone('Hine_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageHineahuone('Hine_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageHineahuone('Hine_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageHineahuone('Hine_phase5.png') no-repeat;
        }
    }
    &-papa {
        & .atua__backdrop--frame1 {
            background: imagePapa('Papa_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imagePapa('Papa_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imagePapa('Papa_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imagePapa('Papa_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imagePapa('Papa_phase5-v2.png') no-repeat;
        }
    }
    &-ranginui {
        & .atua__backdrop--frame1 {
            background: imageRanginui('Rangi_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageRanginui('Rangi_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageRanginui('Rangi_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageRanginui('Rangi_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageRanginui('Rangi_phase5.png') no-repeat;
        }
    }
    &-rongo {
        & .atua__backdrop--frame1 {
            background: imageRongo('Rongo_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageRongo('Rongo_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageRongo('Rongo_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageRongo('Rongo_phase4-v2.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageRongo('Rongo_phase5.png') no-repeat;
        }
    }
    &-ruaumoko {
        & .atua__backdrop--frame1 {
            background: imageRuaumoko('Ruamoko_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageRuaumoko('Ruamoko_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageRuaumoko('Ruamoko_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageRuaumoko('Ruamoko_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageRuaumoko('Ruamoko_phase5.png') no-repeat;
        }
    }
    &-tane {
        & .atua__backdrop--frame1 {
            background: imageTane('Tane_phase1-v2.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageTane('Tane_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageTane('Tane_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageTane('Tane_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageTane('Tane_phase5-v2.png') no-repeat;
        }
    }
    &-tangaroa {
        & .atua__backdrop--frame1 {
            background: imageTangaroa('Tangaroa_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageTangaroa('Tangaroa_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageTangaroa('Tangaroa_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageTangaroa('Tangaroa_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageTangaroa('Tangaroa_phase5-v2.png') no-repeat;
        }
    }
    &-tawhirimatea {
        & .atua__backdrop--frame1 {
            background: imageTawhirimatea('Tawhiri_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageTawhirimatea('Tawhiri_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageTawhirimatea('Tawhiri_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageTawhirimatea('Tawhiri_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageTawhirimatea('Tawhiri_phase5.png') no-repeat;
        }
    }
    &-tumatauenga {
        & .atua__backdrop--frame1 {
            background: imageTumatauenga('Tumatauenga_phase1-v2.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageTumatauenga('Tumatauenga_phase2-v2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageTumatauenga('Tumatauenga_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageTumatauenga('Tumatauenga_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageTumatauenga('Tumatauenga_phase5.png') no-repeat;
        }
    }
    &-whiro {
        & .atua__backdrop--frame1 {
            background: imageWhiro('Whiro_phase1.png') no-repeat;
        }
        & .atua__backdrop--frame2 {
            background: imageWhiro('Whiro_phase2.png') no-repeat;
        }
        & .atua__backdrop--frame3 {
            background: imageWhiro('Whiro_phase3.png') no-repeat;
        }
        & .atua__backdrop--frame4 {
            background: imageWhiro('Whiro_phase4.png') no-repeat;
        }
        & .atua__backdrop--frame5 {
            background: imageWhiro('Whiro_phase5.png') no-repeat;
        }
    }
}


//moe audio player

.ms-audio {
    &-player,
    &-transcript {
        background-color: $white;
        max-width: $msv-breakpoint-xl;
        border: 1px solid #707070;
        padding: 1.5rem !important;
        margin: 0 auto;  
        p {
            font-size: 14px;
        }
        a {            
            text-decoration: underline;
        }
        & h1, h2, h3 {
            font-size: 1.25rem;
        }
        .ms-audio-transcript__top-left {
            .ms-video-transcript__icon, span {
                color: var(--msv-font-primary-color) !important;
            }
        }
    }
    &__top-container,
    &__main-container,
    &__btm-container {
        font-size: 14px;
    }

    &-transcript {
        &__top-container,
        &__btm-container {
            font-size: 14px;
        }
    }
}
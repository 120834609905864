//kwr-search-result-container

//Base Search Results styles override for KWR sites starts
.ms-search-result-container {
    position: relative;
    z-index: 10;
    & .moe-local-search-container,
    & .moe-local-search-form {
        width: 100%;
        max-width: 100% !important;
        & .moe-local-search__input {
            padding-left: 4rem;
            background-color: transparent;
            color: $white;
            text-overflow: ellipsis;
        }
    }
    & .moe-local-search-form {
        position: relative;
        background-color: $color-grey-dark !important;
        & .moe-local-search__submit--btn {
            position: absolute;
            left: 0;
            background: none;
            &::before {
                color: $white !important;
            }
        }
    }
    &__Sort-by-category {
        max-width: 15rem;
        & .msc-l-dropdown {
            height: 2.5rem;
        }
    }
    & .moe-listview__button-container {
        margin-right: 0.625rem;
    }
    &__Products {
        & .ms-product-search-result__item {
            flex: 1 1 20rem;
            max-width: 20rem;
            //Medium Desktops 1280px and above Responsive UI
            @media only screen and (min-width: $msv-breakpoint-mxl) {
                flex: 1 1 20.25rem;
                max-width: 20.25rem;
                padding: 0.5rem;
            }
            & .msc-product__text,
            & .moe-product-text-arrow {
                color: $black;
                line-height: 140%;
            }
            &:hover {
                opacity: unset;
            }
        }
    }
    &__Products .list-unstyled {
        //Small mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-ml) {
            justify-content: center;
        }
    }
    &__refine-product-section {
        justify-content: space-between;
    }
    &__refiner-section {
        & .ms-refine-submenu.list-group {
            a,
            a:hover {
                text-decoration: none;
            }
        }
    }
    &__category-nav-section {
        max-width: $msv-breakpoint-xl;
        align-items: center;
        margin: 0 auto;
    }
    & #search-result-modal {
        float: none;
    }
}

.msc-choice-summary {
    &__item {
        background-color: $kwr-tags-bgdrk !important;
        border: 0.1rem solid $kwr-tags-bgdrk !important;
        color: $white !important;
        padding: 0.2rem 0.2rem 0.3rem 0.8rem;
        &:hover {
            border-color: $kwr-red-active !important;
        }
    }
    &__clear-all {
        padding: 0.2rem 0.5rem;
        border-radius: 3px;
        &:hover {
            color: $kwr-red-active !important;
        }
    }
}
//Base Search Results styles override for KWR sites ends

//KWR Search Result with dark background
.kwr-search-result-container {
    &--dark {
        background-color: transparent;
        & > .row {
            margin-right: -15px;
        }
        //Mobile and Tablet
        @media only screen and (max-width: $msv-container-max-width-ml) {
            margin-top: 0;
            background-color: $color-grey-mid;
            min-height: 26rem;
        }
        & .msc-l-dropdown {
            background-color: $color-grey-lighter;
        }
        // & .msc-choice-summary {
        //     display: none;
        // }
        & .kwr-tabs--underline {
            border-bottom: 0.25rem solid #151324;
        }
        & .moe-tabs--underline {
            margin-top: 4rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            & .moe-tabs__item {
                //margin-bottom: -0.2rem;
                color: $white;
                padding: 1rem !important;
                &.active {
                    border-bottom: 0.25rem solid $color-red;
                }
            }

            .ms-search-result__collection-title-count {
                font-size: 0.9rem !important;
            }
        }
        .ms-search-result-container {
            &__category-nav-section,
            &__title {
                display: none;
            }
            &__no-results-message {
                font-weight: bold;
                color: $color-grey-light;
                text-align: center;
            }
            &__Products {
                //Mobile and Tablet
                @media only screen and (max-width: $msv-container-max-width-ml) {
                    margin-top: 2rem;
                }
                color: $black;
                //Medium Desktops 1280px and above Responsive UI
                @media only screen and (min-width: $msv-breakpoint-mxl) {
                    margin-left: 16rem;
                }
            }
            &__product-section {
                position: relative;
                min-height: 100vh;
                width: calc(100% - 20rem);
                padding: 1.5rem;
                //Mobile and Tablet
                @media only screen and (max-width: $msv-container-max-width-ml) {
                    width: 100%;
                }
                & .msc-alert {
                    border: none;
                    background-color: transparent;
                    color: $color-grey-light;
                }
            }
            &__Sort-by-category {
                display: block !important;
                top: 0;
                max-width: 15rem;
                padding-top: 0;
                margin-right: 0.625rem;
            }
            &__refiner-section {
                width: 20rem;
                background-color: $color-grey-dark;
                margin-top: 0;
                &-ttl,
                & h1,
                h2,
                h3,
                & .ms-refine-submenu-item.multi-select-checked:before,
                & .ms-refine-submenu-item__label,
                & .ms-refine-submenu.list-group .refine-submenu-item__rating {
                    color: $white !important;
                }
                &-expand__collapse {
                    & button,
                    & .expand-all-seperator {
                        color: $kwr-txt-format;
                    }
                }
            }
        }
        .ms-search-result-container__Sort-by-category,
        .ms-search-result-wrapper-sort-by-category {
            & select {
                background-color: $color-grey-dark !important;
                color: $white;
                border: 1px solid #707070 !important;
                height: 2.5rem !important;
                font-weight: normal;
            }
        }

        & .ms-search-result,
        & span.ms-search-result {
            &__collection-title {
                &-prefix,
                &-text,
                &-count {
                    color: $white !important;
                }
            }
            &-container__title h2 {
                color: #707070 !important;
            }
        }
        & .msc-l-dropdown__label,
        & .ms-refine-submenu-item.multi-select:before,
        & .msc-rating__count,
        & .ms-refine-submenu__toggle_expanded,
        & button.ms-refine-submenu__toggle_collapsed {
            color: $color-grey-light !important;
        }
        & a,
        label,
        .msc-button,
        & .ms-refine-submenu__toggle_collapsed:after {
            color: $white;
        }
        & ul.ms-refine-submenu__list {
            border-top: 1px solid $color-grey-light;
        }

        //Pagination on dark background
        & .msc-pagination {
            //Medium Desktops 1280px and above Responsive UI
            @media only screen and (min-width: $msv-breakpoint-mxl) {
                margin-left: 8rem;
            }
            & * {
                color: $white;
            }
        }
        & .moe-search-curated-collections__container {
            margin-top: 0;
        }
        & .msc-alert-warning {
            background-color: transparent;
        }
    }
    & .ms-search-result-container__refiner-section {
        & .ms-refine-submenu.list-group {
            & .ms-refine-submenu-item {
                & .ms-rating-component__current {
                    & .msc-rating__star {
                        color: $white;
                    }
                }
            }
        }
    }
}

.kwr-search-result-container {
    .moe-tabs {
        max-width: none;
        margin-left: 0;
        margin-top: 0;
    }

    .ms-search-result-wrapper-sort-by-category,
    .ms-search-result-container__title,
    .moe-listview__button-container {
        display: none;
    }

    /*.msc-choice-summary {
        display: block;
    }*/
}

.kwr-search-result-container.default-container .row .col-12:empty {
    height: 25rem;
}

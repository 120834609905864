//style presets
:root {
    // rating star
    --msv-product-collection-rating-star: #FFD444;
}

.ms-product-collection {
    max-width: $msv-breakpoint-xl;
    margin: 0 auto 2rem auto;
    //Large Desktops
    @media only screen and (min-width: $msv-breakpoint-xl) {
        max-width: 87.5rem;
    }
    &__heading {
        font-weight: bold;
        /*Responisve UI Fixes*/
        @media (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-l) {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }
    &__item {
        min-width: 20rem; //320px
        max-width: 28.125rem; //450px
        //Large Desktops
        @media only screen and (min-width: $msv-breakpoint-xl) {
            padding-left: 0.625rem;
            padding-right: 0.625rem;
        }
        //Small Mobile Responsive UI
        @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
            min-width: 19rem;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
        }
    }
    .msc-ss-carousel {
        //Large Desktops
        @media only screen and (min-width: $msv-breakpoint-xl) {
            max-width: 87.5rem;
            margin-left: auto;
            margin-right: auto;
        }
        &-slide li {
            height: 100%;
            min-width: 22.5rem;
            //Large Desktops
            @media only screen and (min-width: $msv-breakpoint-xl) {
                min-width: 21.9rem;
            }
            //Mobile Portrait Responsive UI
            @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                min-width: 100%;
            }

            //Base to Large Tablet Portrait Responsive UI
            @media only screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-l) {
                min-width: 50%;
            }

            //Base to Large Tablet landscape Responsive UI
            @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-container-max-width-l) {
                min-width: 33.3%;
            }
        }
    }
}

// product collection item motion effect style
.ms-product-collection__item {
    transition: all 0.3s;
    &:hover {
        color: #1e2229;
        opacity: 0.8;
        transform: translate(0%, -1%);
    }
}

//Poduct Item Tile content main container
.product-item-main-container {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 10px 30px #0000001a;
    border-radius: 9px;
    height: 100%;
    overflow: hidden;

    .msc-product {
        height: 100%;
        text-decoration: none;
        .ms-product-item-top {
            height: 1rem;
            width: 100%;
            background-color: var(--moe-tile-top-background-color);
            border-radius: 7px 7px 0 0;
            transition: all 0.2s ease-in-out;
            cursor: default;
        }
        &:hover .ms-product-item-top {
            background-color: var(--moe-tile-top-hover-color);
        }
        .msc-product__image {
            position: relative;
            height: 11.25rem; //180px
            display: block;
        }
        .msc_image,
        .msc-empty_image {
            width: 100%; //Temp fix
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        &__title {
            height: 2.6rem;
            font-weight: bold;
            margin-top: 1rem;
            margin-left: 0;
        }
        &__text {
            display: block;
            height: 5rem;
            white-space: normal;
            word-break: break-word;
            margin-bottom: 1rem;
            overflow: hidden;
        }
    }

    .msc-rating {
        margin-left: 14px;
        &__star {
            color: var(--msv-product-collection-rating-star);
        }
        &__count {
            color: var(--msv-font-primary-color);
            font-size: 13px;
            margin-left: 0.5rem;
        }
    }

    .moe-product-text-arrow {
        align-self: flex-end;
        padding: 0.8rem 1rem;
        & svg {
            width: 1.875rem;
            height: 1.875rem;
        }
    }
}

//Product Detail container
.moe-msc-product-details-container {
    padding: 0px 1rem 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .msc-price__actual {
        display: none;
    }

    .msc-product__details {
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: column;
    }

    .moe-product-icons {
        align-self: flex-end;
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
        margin-bottom: 0.3125rem;
        & .ms-quickView {
            margin-right: 1rem;
        }
        & svg {
            font-size: 2rem !important;
        }
        & .moe-btn-iconlink__txt {
            display: none;
        }
    }

    .moe-product-quick-view {
        margin-right: 10px;
    }
}

.ms-quickView__button {
    height: 1.5rem;
    background-color: transparent;
    color: black;
    font-size: 0px;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    align-items:center;
    &::before {
        @include msv-icon();
        content: $msv-shopping-bag;
        font-size: 1.5rem;
        width: 1.5rem;
        vertical-align: text-bottom;
        text-align: center;
    }
}

.msc-add-to-wishlist:after {
    margin-right: 8px;
}

.msc-dv-product-attribute {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 0.5rem);
    margin: -0.25rem;
    margin-bottom: 0;
    align-self: center;
}

.msc-spn-product-attribute {
    display: flex;
    align-items: center;
    height: 1.67rem;
    font-size: 0.65rem;
    line-height: 1;
    padding: 0 0.5rem;
    background-color: #f5f5f5;
    color: black;
    border: 1px solid #e4e6e2bc;
    border-radius: 0.25rem;
    margin: 0.25rem;
    min-width: 2.75rem;
    max-width: 12rem;
    overflow: hidden;
}

.moe-product-icons-container {
    display: flex;
    justify-content: space-between;
}

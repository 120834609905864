// Variables
$collection-item-width: 250px;
$collection-item-height: 250px;

:root {
    --msv-collection-btn-color: var(--msv-accent-brand-color);
    --msv-collection-btn-hover-color: var(--msv-accent-brand-alt-color);
}

.msc__view-collection {
    width: 100%;
    padding: 0 2rem;

    .msc-collection {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;
            margin-bottom: 1rem;            
            background-color: $white;
            border-bottom: 1px solid $moe-grey;
            
            h5 {
                font-size: 1.5rem;
            }
    
            .msc-collection__close {
                cursor: pointer;
                font-size: 1.2rem;
            }
        }

        &__body {
            display: flex;
            align-items: center;
            max-width: 70rem;
            margin: 0 auto;
            .msc-collection__backbtn,
            .msc-collection__forwardbtn {
                cursor: pointer;
                font-size: 2.2rem;
            }
    
            .msc-collection__collections {
                display: flex;
                flex-wrap: wrap;
                //max-height: 44rem;
                //overflow-y: auto;
                margin: 0 auto;
    
                .moe-collection-tile {
                    margin: 0.8rem;
                    .moe-collection-tile-item--container {
                        position: relative;
    
                        .checkbox-container {
                            display: block;
                            position: absolute;
                            cursor: pointer;
                            font-size: 22px;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            margin-left: 0px;
                            z-index: 15;
    
                            input {
                                position: absolute;
                                cursor: pointer;
                                height: 25px;
                                width: 25px;
                                top: 2px;
                                opacity: 0;
                            }
    
                            .checkmark {
                                position: absolute;
                                top: 0.25rem;
                                left: 0.5rem;
                                height: 25px;
                                width: 25px;
                                background-color: white;
                                border: #767676 2px solid;
                                border-radius: 0.25rem;
    
                                &:hover {
                                    border: #6a6a6a 2px solid;
                                }
                            }
    
                            input:checked ~ .checkmark {
                                background-color: $moe-blue-active;
                                border: 2px solid $moe-blue-active;
                            }
    
                            // input:checked .checkbox-container {
                            //     display: block;
                            // }
    
                            .checkmark::after {
                                content: '';
                                position: absolute;
                                display: none;
                            }
    
                            input:checked ~ .checkmark::after {
                                display: block;
                            }
    
                            .checkmark::after {
                                left: 0.4rem;
                                top: 0.063rem;
                                width: 0.469rem;
                                height: 0.938rem;
                                border: solid white;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
    
                        .moe-collection-tile-item {
                            border: 2px solid #cacaca;
                            border-radius: 9px;
                            cursor: pointer;
                            position: relative;
                            height: $collection-item-height;
                            width: $collection-item-width;
                        }
                    }
    
                    .msc-collection__collection {
                        max-width: $collection-item-width;
                    }
                }
    
                .msc-collection-tile-item:hover .checkmark {
                    display: block;
                }
            }
        }
        
        &__footer {
            margin-top: 2.5rem;
            display: flex;
            justify-content: center;
    
            button {
                font-size: 16px;
                border-radius: 5px;
                padding: 0.4rem 1rem;
                background-color: #1470e1;
                transition: all 0.3s;
                color: white;
                border: none;
                outline: none;
    
                &:hover {
                    background-color: #0e5cba;
                }
    
                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
    }

    .moe-approveCancel {
        display: flex;
        vertical-align: middle;

        svg.approve {
            margin-right: 12px;
        }
    }
}

.ms-view-collection-container,
.msc-collection__body {
    max-width: 1244px;

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper.msc-ss-carousel__flipper--next {
        right: -30px;
        left: auto;
    }

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper {
        top: 50%;
        left: -10px;
        font-size: 30px;
        border: rgba(0, 0, 0, 0);
        border-radius: 50%;
        background: var(--msv-bg-color);
    }

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper.disabled {
        visibility: hidden;
    }

    .msc-ss-carousel-slide li {
        height: 100%;
    }
}

.ms-share-collection-container .msc-modal__content {
    border-radius: 12px;
    min-height: 350px;
    justify-content: center;

    .moe-share-collection-email--emoji {
        text-align: center;
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    h1 {
        margin-bottom: 2rem;
        text-align: center;
    }

    .share-email-input {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        z-index: 22;

        .share-email {
            border-radius: 5px;
            width: 80%;
            height: 43px;
            border: 1px solid #707070;
            text-indent: 25px;
            margin-bottom: 2rem;

            &:focus-visible {
                outline: none;
            }
        }

        span {
            color: #dc291e;
            margin-top: 6px;
            height: 20px;
        }
    }

    .moe-collection-share-email-footer {
        display: flex;
        justify-content: center;

        .moe-share-collection-close__button {
            margin-left: 1rem;
        }

        button {
            min-width: 11rem !important;
        }

        .msc-share-collection-modal__button {
            cursor: not-allowed;
            background-color: #1470e185;
            color: #ffffff9c;

            &:hover {
                cursor: not-allowed;
                background-color: #1470e185 !important;
                color: #ffffff9c;
            }
        }
    }

    .msc-btn {
        align-self: center;
        font-weight: 700;

        &:hover {
            background-color: var(--msv-collection-btn-hover-color);
        }
    }
}

.ms-moe-audio-player {
    box-sizing: border-box;
    width: 100%;
    // padding: 1rem;
}

.ms-audio-player {
    width: 100%;
    border: 1px solid #a7a7a767;
    padding: 0.8rem 1.4rem;

    --ms-top-download-color: #2b69b1;
    --ms-slider-color: #939393;
    --ms-play-btns-color: #020202;
    --ms-top-volume-color: #a9a8ad;
    --ms-duration-color: #606060;
    --ms-main-title-color: #363636;
    --ms-top-size-color: #cccccc;
    /* range slider colors */
    --ms-slider-bg: #dfdfdf;
    --ms-slider-active: #919191;

    --ms-seek-before-color: #8e8e8e;
    --ms-seek-thumb-color: #5e5e5e;
}

/* Audio: Top container styles*/
.ms-audio__top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ms-audio__top-container .ms-audio__top-download {
    display: flex;
    align-items: center;
}

.ms-audio__volume-icon {
    height: 40px;
    width: 40px;
    color: var(--ms-top-volume-color);
    font-weight: 100;
}

.ms-audio__top-container .ms-audio__top-download .ms-audio__top-download-left {
    display: flex;
    align-items: center;
}

.ms-audio__top-container .ms-audio__top-download .ms-audio__top-download-left a {
    text-decoration: none;
    background: none;
    border: none;
    margin-right: 1rem;
    font-size: 1rem;
    color: var(--ms-top-download-color);
    font-weight: 700;
    cursor: pointer;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid white;
    transition: all 0.3s ease;
}

.ms-audio__top-container .ms-audio__top-download .ms-audio__top-download-left a:hover {
    border-bottom: 1px solid var(--ms-top-download-color);
}

.ms-audio__top-container .ms-audio__top-download .ms-audio__top-download-left a svg {
    margin-left: 0.4rem;
}

.ms-audio__top-container .ms-audio__top-download-right {
    padding-bottom: 0.4rem;
}

.ms-audio__top-container .ms-audio__top-download-right span {
    font-size: 0.8rem;
    margin-right: 0.7rem;
    color: var(--ms-top-size-color);
}

/* Audio: Main container styles */
.ms-audio__main-container {
    margin-top: 2rem;
}

.ms-audio__main-container h4 {
    margin-bottom: 1rem;
    color: var(--ms-main-title-color);
}

/* Audio: bottom container styles */
.ms-audio__btm-container {
    margin-top: 2rem;
}

.ms-audio__btm-container .ms-audio__duration {
    display: flex;
    justify-content: space-between;
}

.ms-audio__btm-container .ms-audio__duration p {
    font-size: small;
    color: var(--ms-duration-color);
}

.ms-audio__btm-container .ms-audio__slider {
    width: 100%;
    height: 0.3rem;
}

.ms-audio__btm-container .ms-audio__controls {
    display: flex;
    justify-content: space-between;
}

.ms-audio__control-btn-left {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ms-audio__controls {
    margin-top: 1.3rem;
}

.ms-audio__btm-container .ms-audio__backward-icon {
    margin-right: 0.8rem;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.3s;
}

.ms-audio__btm-container .ms-audio__backward-icon:active {
    transform: translateX(-10px) scale(1.06);
}

.ms-audio__btm-container .ms-audio__pause-icon,
.ms-audio__btm-container .ms-audio__play-icon {
    padding: 0.4rem;
    box-sizing: content-box;
    transition: color 0.3s linear;
    transition: background 0.3s linear;
    border-radius: 5px;
    cursor: pointer;
}

.ms-audio__btm-container .ms-audio__pause-icon:hover,
.ms-audio__btm-container .ms-audio__play-icon:hover {
    background: var(--ms-top-download-color);
    color: white;
}

.ms-audio__btm-container .ms-audio__forward-icon {
    margin-left: 0.8rem;
    font-size: 1.3rem;
    cursor: pointer;
    transition: all 0.3s;
}

.ms-audio__btm-container .ms-audio__forward-icon:active {
    transform: translateX(10px) scale(1.06);
}

.ms-audio__btm-container .ms-audio__bottom-volume-icon {
    font-size: 1.2rem;
    cursor: pointer;
}

.ms-audio__btm-container .ms-audio__slider {
    appearance: none;
    background: var(--ms-slider-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 0.4rem;
    outline: none;

    --ms-seek-before-width: 0;
}

.ms-audio__btm-container .ms-audio__slider::-moz-range-track {
    appearance: none;
    background: var(--ms-slider-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 0.4rem;
    outline: none;
}

.ms-audio__btm-container .ms-audio__slider::-moz-focus-outer {
    border: 0;
}

.ms-audio__btm-container .ms-audio__slider::before {
    content: '';
    height: 0.4rem;
    width: var(--ms-seek-before-width);
    background: var(--ms-seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
}

.ms-audio__btm-container .ms-audio__slider::-moz-range-progress {
    background: var(--ms-seek-before-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 0.4rem;
}

.ms-audio__btm-container .ms-audio__slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: var(--ms-seek-before-color);
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    z-index: 5;
}

.ms-audio__btm-container .ms-audio__slider:active::-webkit-slider-thumb {
    transform: scale(1.3);
    background: var(--ms-seek-thumb-color);
    z-index: 5;
}

.ms-audio__btm-container .ms-audio__slider::-moz-range-thumb {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--ms-seek-before-color);
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    z-index: 5;
}

.ms-audio__btm-container .ms-audio__slider:active::-moz-range-thumb {
    transform: scale(1.3);
    background: var(--ms-seek-thumb-color);
    z-index: 5;
}

/* 
  Name: Transcript styles 
  Styled container: transcript
  */
.ms-moe-audio-player .ms-audio-transcript {
    width: 100%;
    height: 100%;
    border: 1px solid #a7a7a767;
    padding: 0.8rem 1.4rem;

    --ms-play-btns-color: #020202;
}

/* Transcript: top container styles */
.ms-moe-audio-player .ms-audio-transcript__top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ms-moe-audio-player .ms-audio-transcript .ms-audio-transcript__top-left {
    display: flex;
    align-items: center;
}

.ms-moe-audio-player .ms-audio-transcript .ms-audio-transcript__top-left {
    span {
        font-size: 1.2rem;
        margin-left: 0.5rem;
    }

    svg {
        font-size: 1.4rem;
        margin-top: 0.3rem;
    }
}

.ms-moe-audio-player .ms-audio-transcript .ms-audio-transcript__top-left .ms-audio-transcript__icon {
    font-size: 1.5rem;
}

/* Transcript: bottom container styles */
.ms-moe-audio-player .ms-audio-transcript__btm-container {
    width: 98%;
}

.ms-moe-audio-player .ms-audio-transcript__btm-container p {
    color: var(--ms-play-btns-color);
    font-size: 0.8rem;
    //width: 70%;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    margin-left: 0.2rem;
}

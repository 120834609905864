//kwr-navigation-menu

//Base theme override for KWR site
.overlay.open {
    padding-top: 5rem;
    //mobile and Tablet responsive UI with max-width 1024px and below
    @media only screen and (max-width: $msv-breakpoint-ml) {
        max-height: 79vh;
        overflow-y: auto;
    }
    & .headerMenuMobile {
        &__list--child-active {
            height: 65vh;
        }
        & .i18n-toggle__button {
            top: -2rem;
        }
        &__child-main-title i {
            left: 0.5rem;
            top: 0.2rem;
        }
        &__child-sub-heading {
            margin-top: 2rem;
        }
        &__child-item,
        &__child-link {
            margin-left: 1.2rem;
        }
        &__atua-image-wrapper {
            text-align: center;
        }
    }
}
.button_container.active {
    z-index: 18;
    color: $moe-gray-300;
}
.ms-nav.mobile-vp {
    padding-top: 0 !important;
}

.ms-nav > .ms-nav__list > .ms-nav__list__item .ms-nav__list__item__link,
.ms-nav__list__item__span {
    // color: $moe-grey;
    &:hover {
        color: black;
    }
}

.ms-nav__list__item .ms-nav__list__item__link[title="Search"] {
    height: 90%;
    align-items: center !important;
    & .icon--search-light {
        display: none;
    }
    &::after {
        @include msv-icon();
        content: $msv-magnifying-glass;
        width: 1rem;
        height: 1rem;
        color: black;
        margin-left: 0.5rem;
        margin-top: 2px;
    }
}

//Responsive UI for 992px and above
@media only screen and (min-width: $msv-breakpoint-l) {
    .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item {
        &__button, &__link, &__span {
            &:hover, &:focus {
                &::before {
                    background-color: $kwr-red-active;
                    height: 3px;
                }
            }
        }
    }
}

.ms-header__collapsible-hamburger {
    background-color: $kwr-menu-bg;
    & .ms-nav,
    & .ms-header__mobile-hamburger-menu-links {
        background-color: $kwr-menu-bg
    }
    & .ms-nav.mobile-vp {
        height: 78vh;
    }
    & .ms-search__form {
        display: none;
    }
    //mobile and Tablet responsive UI with max-width 1024px and below
    @media only screen and (max-width: $msv-breakpoint-ml) {
        left: auto !important;
        right: 0;
        width: 20rem !important;
        height: 100vh;
        background-color: $kwr-menu-bg !important;
        & .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button {
            padding-left: 2rem;
            padding-right: 2rem;
        }
        & .ms-header__divider {
            display: none;
        }
        & .ms-header__account-info.account-mobile .drawer__button, 
        & .ms-header__account-info .ms-header__signin-button,
        & .moe-support-btn .moe-support-btn--link {
            background: transparent !important;
            color: $white;
            & .drawer__glyph__end {
                color: $kwr-red-active;
            }
        }
        & .ms-header__account-info.account-mobile {
            & .collapse.show {
                width: 18rem;
                position: absolute;
                left: 0;
                top: 3rem;
                background-color: $kwr-tags-bgdrk;
                color: $moe-white;
                & .ms-signin-info__account-link-button,
                & a {
                    color: $moe-white;
                }
            }
        }
    }
    & .overlay.open {
        //mobile and Tablet responsive UI with max-width 1023px and below
        @media only screen and (max-width: $msv-container-max-width-ml) {
            position: relative;
            padding-top: 1rem;
            & .headerMenuMobile__list--child-active {
                height: 90vh;
            }
            & .headerMenuMobile__back-link-active {
                opacity: 1;
            }
        }
    }
}

//kwr header menu decorative as per live site
.mega-dropdowns,
.kwr-mega-dropdowns {
    z-index: 16;
    & .close-text {
        top: 2.5rem !important;
        right: 6.5rem !important;
    }
    &  .modal-button-close {
        height: 2.5rem;
        border-radius: 1.2rem;
        cursor: pointer;
    }
} 

//kwr nav
.kwr-nav {
    & ul.ms-nav__list>li {
        flex-direction: unset;
    }
    //large desktops 1280px and above Responsive UI
    @media only screen and (min-width: $msv-breakpoint-mxl) {
        padding-left: 8rem;
    }
}
//moe-epub-viewer.view
.epub-nav-controls {
    display: flex;
    justify-content: center;
    align-items: center;
}
.epub-autoturn-label {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        &[disabled] + span {
            opacity: 0.5;
        }
    }
    span {
        font-size: 14px;
        font-weight: 700;
    }
}
.epub-startend-btn,
.epub-page-count,
.epub-read-loud-btn {
    display: inline-flex;
    position: relative;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.7rem;
    line-height: 1.4;
    text-transform: uppercase;
    align-items: center;
    color: $moe-black;
}
.epub-startend-btn,
.epub-page-count {
    margin-left: 1.15rem;
    margin-right: 1.15rem;
    //Tablet and Mobile 768px and below
    @media only screen and (max-width: $msv-breakpoint-m) {
        margin-left: 0;
        margin-right: 0;
    }
}
.epub-page-count {
    white-space: nowrap;
}
.epub-startend-btn,
.epub-read-loud-btn {
    border: none;
    border-radius: 0.2rem;
    font-weight: 700;
    background-color: transparent;
    & svg {
        font-size: 1rem;
        margin: 0 0.5rem;
    }
}
.epub-startend-btn--inactive {
    background-color: transparent;
    color: $moe-gray-300;
    cursor: not-allowed;
    pointer-events: none;
}
.epub-read-loud-btn {
    background-color: $moe-gray-200;
}
.epub-read-loud-btn--active {
    background: $moe-red;
    color: $moe-white;
}

// Loading animation
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    & div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;
        &::after {
            content: ' ';
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #000;
            margin: -4px 0 0 -4px;
        }
        &:nth-child(1) {
            animation-delay: -0.036s;
            &::after {
                top: 63px;
                left: 63px;
            }
        }
        &:nth-child(2) {
            animation-delay: -0.072s;
            &::after {
                top: 68px;
                left: 56px;
            }
        }
        &:nth-child(3) {
            animation-delay: -0.108s;
            &::after {
                top: 71px;
                left: 48px;
            }
        }
        &:nth-child(4) {
            animation-delay: -0.144s;
            &::after {
                top: 72px;
                left: 40px;
            }
        }
        &:nth-child(5) {
            animation-delay: -0.18s;
            &::after {
                top: 71px;
                left: 32px;
            }
        }
        &:nth-child(6) {
            animation-delay: -0.216s;
            &::after {
                top: 68px;
                left: 24px;
            }
        }
        &:nth-child(7) {
            animation-delay: -0.252s;
            &::after {
                top: 63px;
                left: 17px;
            }
        }
        &:nth-child(8) {
            animation-delay: -0.288s;
            &::after {
                top: 56px;
                left: 12px;
            }
        }
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//moe image list

.ms-image-list {
    max-width: $msv-breakpoint-xl;
    margin: 0 auto 2rem auto;
    & h1,
    h2,
    h3 {
        font-size: 1.25rem;
    }
    &__list {
        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
        }
        .msc-ss-carousel {
            .msc-flipper {
                &.msc-ss-carousel {
                    &__flipper {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        &--next {
                            right: -30px;
                            left: auto;
                        }
                        top: 50%;
                        left: -10px;
                        font-size: 30px;
                        border: rgba(0, 0, 0, 0);
                        border-radius: 50%;
                        background: var(--msv-bg-color);
                        /*&.disabled {
                            visibility: hidden;
                        }*/
                    }
                }
            }
            &-slide li {
                margin: 0 0.5rem;
                width: 20rem;
                //Mobile Responsiveness
                @media screen and (max-width: $msv-breakpoint-s) {
                    width: 18rem;
                }
            }
        }
    }
    &__title {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    &__link {
        position: relative;
        height: auto;
        padding-top: 56.25%;
        display: block;
        overflow: hidden;
        margin-bottom: 0.8rem;
        //Mobile Responsiveness
        @media screen and (max-width: $msv-breakpoint-s) {
            width: 18rem;
        }
        img {
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            height: 100%;
        }
    }
    &__text {
        white-space: normal;
    }
}

.moe-image-list-container.img-list-vertical ul {
    flex-direction: column;
}

.moe-imglist {
    &--nomarginpadding {
        margin: 0;
        padding: 0;

        .msc-ss-carousel {
            padding: 0;
        }
    }

    &--center {
        .msc-ss-carousel-slide {
            justify-content: center;
        }
    }
}

//Image Thumbnails Links List
.moe-imglinks {
    display: flex;
    justify-content: center;
    & .ms-image-list {
        &__list {
            padding: 0;
            margin: 0 auto;
            &-item {
                display: flex;
                align-items: center;
            }
        }
        &__link,
        &__link img {
            padding: 0 !important;
            width: 6.25rem;
            height: 6.25rem;
        }
        &__text {
            margin: 0 1rem;
            font-size: 1.25rem;
        }
    }
}

#moe-lightbox {
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: none;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

//Single Image module styles
.moe-image {
    &-wrap {
        display: flex;
        width: 100%;
        align-items: center;
        height: 28rem;
        max-width: 48rem;
        //background-color: $moe-gray-200;
        margin: 1rem auto 2rem auto;
        overflow: hidden;
        //Responsive UI between 768px and 1280px
        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
            height: 22rem;
            max-width: 40rem;
        }
        //Mobile Responsiveness
        @media screen and (max-width: $msv-container-max-width-s) {
            height: 18rem;
            max-width: 36rem;
        }
    }
    width: auto;
    height: 100%;
    padding: 0;
    margin: 0 auto;
}

//Images look with Aspect ratio test CSS code temporary
/*.moe-container-row {
    & div.col-12 {
        width: 650px;
        max-width: 650px;
        height: 450px;
        margin: 0 auto;
        overflow: hidden;
        & .moe-image-wrap {    
            & img {
                width: 100%;
                display: block;
                width: inherit;
                height: inherit;
                object-fit: cover;
              }
              width: 100%;
              height: 0;
              padding-top: 100%;
              //to make the iamge 16/9 proportion
              padding-top: calc(100% * (9 / 16));
              position: relative;
              & .moe-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
                position: absolute;
                top: 0;
              }
        }
    }
}*/

// Local variables
$moe-wishlist-gray-heading: $moe-grey;

.moe-wishlist-product-collection {
    margin: 1.5rem;

    .moe-btn-iconlink--addtocollection {
        display: none;
    }

    .moe-collection-products--heading {
        color: $moe-wishlist-gray-heading;
        font-size: 18px;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    .moe-wishlist-products-remove-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        margin-top: 1rem;
        min-width: 0 !important;
        padding: 0.2rem 1rem;

        svg {
            margin-left: 0.5rem;
            font-size: 1rem;
        }
    }

    .product-item-main-container {
        .msc-product {
            display: block;
        }
    }
}

//moe-form

//Base theme overrides for MOE sites

//Label,Input, Select and Textarea
label, input, select, textarea  {
    font-size: 1rem !important;
}

//input checkbox
input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.moe-form {
    &__validation {
        display: block;
        font-size: 0.875rem;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        color: $moe-red-drk;
    }
    &__control{
        &--checkout {
            display: inline-flex;
        }
    } 
}


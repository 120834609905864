//kwr-hero
/* To allow for required ordering of Whata/hills etc, we need to push the container down so that it doesn't obstruct the
Whata links - and then push up the panel1 content into position.
*/

@mixin backdrop-hill-left {
    &::after {
        background: image('header-hill-left-taller.svg') no-repeat;
        background-position: 0 -1.67rem;
        background-size: 70rem;
        content: '';
        height: 100%;
        min-height: 55rem;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 7;

        @media screen and (min-width: 1280px) {
            background-position: -2.94rem -1.67rem; // -53px -30px;
        }
    }
}

@mixin backdrop-hill-right {
    &::before {
        background: image('header-hill-right-taller.svg') no-repeat;
        background-position: right 11rem;
        background-size: auto 33rem;
        content: '';
        height: 100%;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 7;
    }
}

$header-container-offset: 12.5rem;

.header-menu {
    list-style-type: none;
    position: relative;
    margin-top: 0;

    @media screen and (max-width: 1279px) {
        display: none;
    }

    &__item {
        background: image('whata-deselected.svg') no-repeat;
        background-size: cover;
        height: 11.0556rem; //199px;
        width: 8.6667rem; //156px;
        position: absolute;

        &:hover {
            background: image('whata-hover.svg') no-repeat;
        }

        &.current {
            background: image('whata-active.svg') no-repeat;
        }

        &--learning-areas {
            top: 1.6667rem; //30px;
            left: 19.16667rem; //345px;
        }

        &--atua {
            top: 1.9444rem; //35px;
            left: 1.4444rem; //26px;
        }

        &--whata-patoro {
            top: 0.2778rem; //5px;
            left: 10.2778rem; //185px;
        }

        &--search {
            top: 0.2778rem; //5px;
            left: 28rem; //504px;
        }

        a:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    .atua-mega__item-link {
        display: block;
        width: 100%;
        height: 100%;
        color: $color-grey-light;
        //font-size: 0.8rem;
        padding: 1rem;
    }

    &__mega-nav-toggler,
    .header-menu__item--search a {
        color: $color-white;
        background: none;
        border: 0;
        padding: 1rem 2.2rem 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.2;
        font-weight: 700;
        height: 11.0556rem; //199px;
        width: 8.6667rem;

        &:hover {
            text-decoration: underline;
        }

        > span {
            display: block;
            margin-bottom: 2.5rem;
            pointer-events: none;
            text-align: center;
        }
    }

    .mega {
        display: none;
    }
}

.body--global-nav {
    .header {
        background-color: $color-grey-darkest;
        background: image('background-wide.png') no-repeat;
        background-size: cover;
        background-position: 7rem 0;
        box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2);
        color: $color-chrome-text-dark;
        height: auto;
        //font-size: 0.7rem;
        min-height: 16rem;
        transition: all 0.1s ease-out;
        width: 100%;
        z-index: $z-index-header;

        @media screen and (min-width: 1280px) {
            background-position-y: -11rem;
        }

        &--hidden {
            height: 0;
            min-height: 0;
            overflow: hidden;
        }

        &__container {
            padding: 0;
            position: relative;
            height: 100%;
            z-index: 7;
            top: $header-container-offset;
        }

        &__panel1 {
            margin-top: 0;
            flex: 0 0 30rem; //22.22rem; //400px;
            top: -$header-container-offset;
            position: relative;

            @media screen and (min-width: 1280px) {
                margin-top: 2rem;
            }
        }

        &__panel2 {
            flex: 0 0 35.83rem; //645px;
            margin-bottom: 12rem;
            margin-top: -12rem;

            @media screen and (min-width: 1280px) {
                margin-top: 0;
                max-width: 46rem;
            }
        }

        &__explore-by {
            height: 12.11rem; //218px;
            position: absolute;
            right: 38rem;
            z-index: 6;

            @media screen and (max-width: 1279px) {
                display: none;
            }
        }

        &__backdrop-hills {
            @include backdrop-hill-left();
            @include backdrop-hill-right();
        }

        &__inner {
            display: block;
            max-width: 32rem;
            padding: 2rem 1rem 2rem;
            position: relative;
            z-index: 5;

            @media screen and (min-width: 1280px) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0 3rem 2rem;
                max-width: unset;
                min-width: 100%;
            }
        }

        &__logo {
            z-index: 0;
            height: 1.11rem; //20px;
            margin-bottom: 4rem;

            img {
                width: 6.6667rem;
                height: auto;
            }
        }

        &__crumbs {
            margin-left: 0.2rem;
            text-transform: uppercase;
            //font-size: 0.6rem;
            opacity: 0.75;
            letter-spacing: 0.005rem;
            font-weight: 700;
            color: $color-grey-light;

            &__icon {
                display: inline-block;
                width: 0.4rem;
                margin: 0 0.25rem;
            }
        }

        &__title-block {
            h1 {
                margin: 0 0 2rem;
            }
            @media screen and (min-width: 1280px) {
                display: flex;
            }
        }

        &__thumbnail {
            flex: 0 0 4.44rem; //80px;
            margin-bottom: 1.5rem;
        }

        &__title {
            color: $color-white;

            &--curatedtext {
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: initial;
                text-decoration: none;
                color: #fff;

                &:hover {
                    text-decoration: underline;
                }
            }

            &--curatedproduct {
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: initial;
            }

            img {
                width: 198px;
                height: 111px;
                border-radius: 0.5rem;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }

            &--curatedproductheading {
                max-width: 37.5rem;
            }
        }

        &__description {
            color: $color-white;
            //font-family: $font-family-serif;
            width: 100%;
            max-width: 22.22rem; //400px;
            font-size: 1rem;
            padding: 0;
        }

        &__i18n-toggle {
            float: left;
            display: block;
            position: absolute;
            left: 12rem;
            border-left: 0.125rem solid $color-grey-lighter;
            padding-left: 1.15rem;
            line-height: 3;
            margin: 0.5rem !important;
            height: 2rem;
            top: 0;

            @media (min-width: 74rem) {
                left: 15.1rem;
            }

            &__list {
                li {
                    padding: 0 0.3rem 0 0.3rem;
                    font-weight: 900;
                    border-bottom: 0.3rem solid transparent;
                    height: 2.5rem;

                    &__link {
                        &:hover,
                        &:focus,
                        &:active {
                            text-decoration: none;
                        }
                    }
                }
            }

            @media screen and (max-width: 1279px) {
                display: none;
            }

            @media (min-width: 44rem) {
                margin-right: auto;
            }
        }

        &__additional {
            display: block;
            position: relative;
        }

        &__sideInfo {
            color: $color-white;
            margin-bottom: 1rem;
            width: 100%;

            @media screen and (min-width: 1280px) {
                padding: 1rem 0 0 12rem;
            }

            &::before {
                content: '';
                width: 2rem;
                height: 0.2rem;
                background: $color-grey-mid;
                display: block;
                margin: 1rem 0;

                @media screen and (min-width: 1280px) {
                    display: none;
                }
            }

            &__title {
                font-size: 1.25rem;
                font-weight: bold;
            }

            &__description {
                //font-size: 0.65rem;
                color: $color-grey-light;
                line-height: 1.4;
                margin-top: 0.5rem;
            }

            &__action {
                margin-top: 0.5rem;
                //font-size: 0.65rem;
            }
        }

        &__search {
            padding: 0 1.5rem !important;
        }

        &__learning-areas,
        &__atua {
            & > .header__mega-nav-toggler {
                text-transform: uppercase;
                font-weight: 700;
                border: 0;
                background: none;
                margin-top: 0.3rem;

                .mega__list__link {
                    text-transform: uppercase;
                }
            }

            &.toggle--open .mega,
            &.active .mega {
                opacity: 1;
                visibility: visible;
            }

            .container > .header__mega-nav-toggler {
                margin: 0.4rem;
                width: 50%;
            }

            &:hover {
                & > .header__mega-nav-toggler {
                    color: $color-red;
                }
            }

            &.toggle--open {
                background-color: $color-grey-dark;

                & > .header__mega-nav-toggler {
                    color: $color-white;
                }

                .mega .container {
                    visibility: visible;
                }

                .icon--chevron-red {
                    background: image('chevron-light.svg') no-repeat;
                    transform: rotate(180deg);
                }
            }
        }

        &__nav-toggle {
            @include hover-focus-active {
                cursor: pointer;
            }

            .icon {
                background-position: center;
            }
        }

        &__nav {
            display: none;
            overflow: hidden;

            .main-nav {
                transition: $transition-slide;
                transform: translate3d(0, -100%, 0);
            }

            &.toggle {
                &--open,
                &--opening,
                &--closing {
                    display: block;
                }

                &--closing {
                    z-index: 0;
                }

                &--open {
                    z-index: 10;

                    .main-nav {
                        transform: translate3d(0, 0, 0);
                    }
                }
            }
        }

        .skip-links__link {
            height: 0.1rem;
            padding: gap-size(1/4);
            position: absolute;
            left: -100%;
            overflow: hidden;
            top: -100%;
            width: 0.01rem;

            &:focus,
            &:active {
                display: inline-block;
                height: auto;
                left: 0;
                margin-bottom: gap-size(1/4);
                overflow: visible;
                position: static;
                top: 0;
                width: auto;
            }
        }

        .header__search {
            a:hover {
                .icon--search-red {
                    background: image('search.svg') no-repeat;
                }
            }
        }

        .title {
            &__link {
                color: $color-chrome-dark;

                &:visited {
                    color: $color-chrome-dark;
                }
            }

            &__text {
                font-family: $font-family-serif;
                font-size: $font-size-logo;
                font-weight: $font-weight-bold;
                letter-spacing: 0;
                margin: 0;
                padding: 0;
            }
        }

        &__static-text {
            text-transform: uppercase;
            font-weight: $font-weight-light;
            //font-size: 0.7rem;
            color: $color-grey-midlight;
        }
    }
}

.kwr-hero-banner {
    @media only screen and (max-width: $msv-container-max-width-l) {
        width: 100%;
    }
}


//Heros specific to KWR Group1 and Group2 Landing pages
.kwr-hero {
    &-wrap {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;    
        & .ms-container-background-image {
            min-height: 100% !important;
            width: auto;
            background-repeat: no-repeat;
            background-size: auto 100% !important;  
            //Responsive UI for 1280px and below
            @media only screen and (max-width: $msv-breakpoint-mxl) {
                background-size: 100% auto !important;
            }          
        }
        & .moe-hero, .kwr-hero {
            &__content {
                background: transparent !important;
                max-width: 48rem;
                margin-bottom: 2rem;
                //Responsive UI for 568px and below
                @media only screen and (max-width: $msv-breakpoint-s) {
                    height: auto !important;
                    background: $moe-gray-drkst !important;
                    top: 11rem;  
                    margin-bottom: 0;                  
                }
            }
            &__text {
                max-height: 18rem;
                //Responsive UI for 568px and below
                @media only screen and (max-width: $msv-breakpoint-s) {
                    margin: 1.5rem !important;
                }
            }
        }
        //Group1 & Group2 Landing Heros common
        &--hillsback, 
        &--hillsfront {
            & .moe-hero, .kwr-hero {
                &__content {
                    height: auto !important;
                    overflow: unset;
                }
            }
            & .ms-breadcrumb {
                position: relative;
                justify-content: left;
                margin-left: 7.6rem;
                margin-right: 2rem;
                z-index: 1;
                //Responsive UI for 992px and below
                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-left: 5rem;
                }
                //Responsive UI for 568px and below
                @media only screen and (max-width: $msv-breakpoint-s) {
                    top: 10rem;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
        //Group1 Landing Hero specific
        &--hillsback {
            height: auto;
            max-height: 64rem;
            margin-bottom: 1rem;
            //Responsive UI for 568px and below
            @media only screen and (max-width: $msv-breakpoint-s) {
                height: 36rem;
                background-color: $moe-gray-drkst;
            }
            & .ms-container-background-image {
                background-position: top center !important;
                aspect-ratio: 1000 / 552;
            }
        }
        //Group2 Landing Hero specific
        &--hillsfront {
            height: 32rem;
            max-width: $msv-breakpoint-xl;
            margin: 0 auto 1rem auto;
            position: relative;            
            &::before {
                background-image: url(../../../images/header-hill-left-taller.svg); 
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: auto 90%;
                content: " ";        
                min-height: 55rem;
                height: 100%;
                width: 100%;
                pointer-events: none;
                position: absolute;        
                top: 0;
                left: 0;
                z-index: 1;
                //Responsive UI for 1280px and below
                @media only screen and (max-width: $msv-breakpoint-mxl) {
                    z-index: 0;
                }
            }
            & .ms-container-background-image {
                background-size: contain !important;
                //Responsive UI for 1280px and below
                @media only screen and (max-width: $msv-breakpoint-mxl) { 
                    background-size: contain !important;               
                    background-position: bottom center !important;
                    background-color: $moe-gray-drkst !important;
                }
            }
            & .moe-hero, .kwr-hero {
                &__content {
                    max-width: 34rem;
                    z-index: 1;
                    //Responsive UI for 1280px and below
                    @media only screen and (max-width: $msv-breakpoint-mxl) {
                        max-width: 100%;
                        margin-top: 0;
                        top: 0;
                        background: $moe-gray-drkst !important;
                    }
                }
                &__text {
                    //Responsive UI for 1280px and above
                    @media only screen and (min-width: $msv-breakpoint-mxl) {
                        margin-top: 3.5rem;
                    }
                }
            }
        }
    }
}

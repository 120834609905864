// moe-header

//Base Theme override for MOE sites headers

//SignIn User Account control
.ms-header {
    &__logo {
        & a,
        & a img {
            width: 6.25rem; //100px
            height: 6.25rem; //100px
            // object-fit: cover added under CR002 change
            object-fit: cover;
            //Responsive UI
            @media (max-width: $msv-container-max-width-ml) {
                width: 4rem !important;
                height: 4rem !important;
            }
        }
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            margin-right: 0.8rem !important;
        }
    }
    &__account-info {
        display: inline-flex;
        position: relative;
        & .ms-header__profile-button {
            display: flex;
            align-items: center;
        }
        & .msc-popover {
            min-width: 12rem;
            top: 2.5rem !important;
            left: auto !important;
            right: 0rem;
            transform: none !important;
            & .msc-arrow {
                visibility: hidden;
            }
            & a {
                font-size: 0.9rem;
                height: auto;
                padding: 0.625rem 1rem !important;
                transition: all 0.3s;
                &:hover {
                    background-color: $moe-gray-100;
                }
            }
        }
        &.account-mobile {
            display: flex !important;
            justify-content: flex-start;
            margin-bottom: 1.5rem;
            //Mobile Responsive UI
            @media (max-width: $msv-breakpoint-s) {
                max-width: calc(100% - 2rem);
            }
            & .ms-search__autoSuggest {
                width: calc(100% - 2rem);
                max-width: 30rem;
                top: 3px;
                //Mobile Responsive UI min-width 576px
                @media (min-width: $msv-container-min-width-sm) {
                    left: 2rem;
                }
                &__keyword,
                &__product,
                &__category,
                &__curricula {
                    border-left: 1px solid $moe-gray-300;
                    border-right: 1px solid $moe-gray-300;
                }
                &__keyword {
                    border-top: 1px solid $moe-gray-300;
                }
                & > div:last-child {
                    border-bottom: 1px solid $moe-gray-300;
                }
            }
            & .drawer__button {
                & .drawer__glyph__end {
                    right: 1.5rem !important;
                    color: $moe-blue-active;
                    margin-left: 1rem;
                }
            }
            & .collapse {
                width: 18rem;
                position: absolute;
                left: 0;
                top: 3rem;
                background-color: $moe-gray-100;
                margin-left: 1.5rem;
                & a {
                    font-size: 0.9rem;
                }
            }
        }
    }
    &__modal {
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            & .msc-modal {
                &__backdrop,
                &__header {
                    display: none;
                }
                &__dialog {
                    max-width: 100%;
                    top: 4rem;
                    transform: unset;
                }
            }
        }
        @media (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-l) {
            & .msc-modal {
                &__dialog {
                    top: 6.3rem;
                }
            }
        }
    }
    &__nav-icon[title='Hamburger menu']::before {
        font-size: 1.5rem !important;
    }

    //Mobile Hamburger menu
    &__mobile-hamburger-menu-links {
        & .ms-search {
            &__label {
                display: none !important;
            }
            &__form {
                margin: 1rem !important;
            }
            &__searchForm {
                min-width: 16rem !important;
                align-items: center;
            }
        }
        & .moe-site-controls {
            display: flex;
            justify-content: space-between;
            &__lbl {
                display: inline-block;
                margin: 0.8rem 0.8rem 0.2rem 0.8rem;
            }
            & .msc-btn {
                white-space: nowrap;
            }
            .ms-header__account-info.account-mobile {
                & .drawer__button {
                    position: relative;
                    padding-right: 5px !important;
                    & .drawer__glyph__end {
                        right: -0.8rem !important;
                    }
                    &text {
                        display: block;
                        line-height: 120%;
                        padding-right: 20px;
                        position: absolute;
                        left: 3rem;
                        top: 1.1rem;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                //Mobile and tablet Responsiveness 568px and above
                @media only screen and (min-width: $msv-breakpoint-s) {
                    width: 15rem;
                    & .drawer__button {
                        &text {
                            max-width: 13.125rem;
                        }
                    }
                }
                //Small Mobile Responsiveness 567px and below
                @media only screen and (max-width: $msv-container-max-width-xs) {
                    width: 10.625rem;
                    & .drawer__button {
                        &text {
                            max-width: 6.25rem;
                        }
                    }
                }
            }
        }
    }
}

//Header specifc Switch curriculum form control
.moe-form {
    &__control {
        &--switchcurriculum {
            display: none !important;
            align-items: center;
            justify-content: center;
            width: 13rem;
            height: 2.625rem;
            background-color: #eeeeee;
            padding-left: 0.5rem;
            margin: 0.3125rem 1.5rem 0.3125rem 0;
            position: relative;
            z-index: 1100;
            & button {
                background: none;
                border: none;
            }
            & .moe-select {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                height: 2.5rem;
                background: none;
                border: none;
                &__button {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 10rem;
                }
                &__chevrondown {
                    display: inline-block;
                    margin: 0.25rem 0.25rem 0 0.25rem;
                    & svg {
                        stroke-width: 0.09375rem;
                    }
                }
                &__list {
                    width: 13rem;
                    background-color: $white;
                    border: 1px solid #707070;
                    position: relative;
                    left: -0.5rem;
                    top: 0.1rem;
                    &.hide {
                        display: none;
                    }
                    &.show {
                        display: block;
                    }
                }
                &__item {
                    padding: 0.5rem;
                    & button {
                        color: $moe-blue-active;
                    }
                }
            }
        }
        &-gobtn {
            margin: 0 0.25rem;
            & svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}

//MOE Global Hamburger Menu for Mobile and Tablet
@media (max-width: $msv-breakpoint-l) {
    .ms-header__collapsible-hamburger {
        & .ms-nav {
            & > .submenu_link {
                & .ms-nav__list__item__link {
                    padding-left: 1.5rem !important;
                }
            }
        }
        & .ms-nav > .ms-nav__drawer {
            border-top: 1px solid $moe-gray-400;
            & .collapse,
            & .ms-nav__drawer {
                border-top: none;
            }
            & .drawer__button {
                display: block;
                width: 100% !important;
                padding-right: 4rem !important;
                & .drawer__glyph__end {
                    position: absolute;
                    right: 2rem;
                }
            }
            & .drawer__button,
            & .ms-nav__list__item__link {
                padding-left: 1.5rem !important;
                line-height: 120% !important;
                &:focus {
                    outline: 1px dashed $moe-grey;
                    background-color: $moe-gray-100;
                }
            }
            & > .collapse .submenu_link + .ms-nav__drawer .drawer__button,
            & + .ms-nav__drawer .collapse .drawer__button,
            & .collapse .ms-nav__drawer .ms-nav__list__item__link {
                padding-left: 2.25rem !important;
            }
            & .submenu_link {
                margin-left: 0.75rem;
            }

            /*& .submenu_link,
            & .submenu_link + .ms-nav__drawer {
                padding-left: 0.8rem !important;
            }*/
        }
    }
}

//moe header ui elements
.moe-header {
    padding: 0;
    margin: 0;
    &-wrap {
        box-shadow: 0px 2px 10px #00000029;
        //margin-bottom: 2px;
    }
    &-actions {
        display: flex;
        align-items: center;
        & .moe-btn {
            margin-left: 0.8rem;
            margin-right: 0.8rem;
            justify-content: center;
        }

        & .ms-header__signin-button-text {
            white-space: nowrap;
        }
    }
    &__logo-wrap {
        display: flex;
        align-items: center;
        width: max-content;
        margin-right: 2rem;
    }
    &__logo-txt {
        flex-grow: 999;
        font-weight: 900;
        font-size: 1.31rem;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        //Responsive UI
        @media (max-width: $msv-container-max-width-l) {
            white-space: normal;
            font-size: 1.25rem;
            margin-right: 1rem;
        }
        //Mobile Responsive UI
        @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
            font-size: 0.95rem;
            max-width: 60%;
        }
    }
    & .ms-search {
        margin-top: 0 !important;
        &__searchForm {
            min-width: 18.75rem;
            height: 2.125rem;
            outline: none !important;
            align-items: center;
            & .msc-btn {
                height: 2rem;
            }
        }
        &__form-control {
            height: 2rem;
        }
    }
    & .msc-cart-icon {
        width: auto;
        height: 1.5rem;
        & img,
        svg {
            height: 1.8rem;

            @media only screen and (max-width: $msv-breakpoint-mxl) {
                height: 1.4rem;
            }
        }
    }
    &__desktop {
        display: block;
        width: 100%;
        &-container {
            display: flex;
            background: #fff;
            width: 100%;
            justify-content: space-between;
            &-left,
            &-right {
                display: flex;
                align-items: center;
            }
        }
        & .ms-header__desktop-view {
            display: flex;
        }
        & .moe-search-wrap {
            margin-left: 1.25rem;
            margin-right: 1.25rem;
            align-items: center;
            display: inline-flex;
        }
    }
    &__mobile {
        display: none;
    }

    .ms-header__collapsible-hamburger {
        .moe-support-btn {
            .moe-support-btn--link {
                text-align: left;
                display: flex;
                align-items: center;
                padding-top: 0 !important;
                justify-content: center;
                display: inline-flex;
                font-size: 16px;
                i {
                    margin: 0 !important;
                    padding-right: 0rem;

                    &::before {
                        font-size: 1rem;
                    }
                }
            }
        }

        & .moe-switchsite {
            margin: 0.5rem 1.5rem 0.5rem 0.8rem;

            &__link {
                width: 4rem;
                height: 4rem;
                margin-right: 1rem;
                background-color: $moe-white;
                //background-color: $moe-grey;
                border: none;
                //border-radius: 50%;
                //border-top-right-radius: 0;
                //border: 1px solid $moe-grey;
                align-items: center;
                overflow: hidden;
            }
            &__img {
                width: 4.2rem;
                height: 4.2rem;
                max-width: unset;
                background-size: cover;
                padding: 0;
                border: none;
            }
        }
    }

    // & .ms-cart-icon-wrapper {
    //     @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
    //         padding-left: 0 !important;
    //     }
    // }
}

.moe-nav-wrap {
    display: flex;
    justify-content: space-between;
    max-width: $msv-breakpoint-xl;
    //border-top: 1px solid #707070;
    margin: 0 auto 0 auto;
    .moe-nav {
        margin: 0 !important;
    }

    //Switch Sites Links
    .moe-switchsite {
        display: flex;
        align-items: center;
        &__link {
            width: 3.125rem;
            height: 3.125rem;
            margin: 0.2rem 0.5rem;
            padding: 0;
            background-color: $moe-white;
            //background-color: $moe-grey;
            border: none;
            //border-radius: 50%;
            //border-top-right-radius: 0;
            //border: 1px solid $moe-grey;
            align-items: center;
            overflow: hidden;
        }
        &__img {
            width: 3.2rem;
            height: 3.2rem;
            max-width: unset;
            background-size: cover;
            padding: 0;
            border: none;
        }
    }
}

//Responsive UI
.ms-header__collapsible-hamburger {
    box-shadow: 0px 2px 10px #00000029;
    padding-bottom: 3rem;
    & .moe-form__control--switchcurriculum {
        margin-left: 1.25rem;
    }
}

@media (max-width: $msv-breakpoint-l) {
    .moe-header {
        & .moe-nav-wrap {
            display: none;
        }
        &__desktop {
            display: none !important;
        }
        &__mobile {
            display: block;
            width: 100%;
            & .moe-header-actions {
                margin-right: 0.5rem;
            }
            &-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &-left {
                    display: flex;
                    align-items: center;
                }

                &-right {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

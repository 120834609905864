//moe-play-store

.moe-playstore {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    &__lftcol {
        width: 30%;
        //small devices below 1023px
        @media only screen and (max-width: $msv-container-max-width-ml) {
            width: 100%;
        }
    }
    &__rgtcol {
        width: 70%;
        //small devices below 1023px
        @media only screen and (max-width: $msv-container-max-width-ml) {
            width: 100%;
        }
    }
    &__thumbnail {
        display: block;
        width: 4.3rem;
        height: 4.3rem;
        border-radius: 0.75rem;
        overflow: hidden;      
    }
    &__ttl {
        font-size: 1.5rem;
        margin-top: 1rem;
    }
    &__desc {
        font-size: 1rem;
    }
    &__devname {
        font-size: 0.8rem;
        color: $moe-grey;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__btns {
        display: flex;
        gap: 2rem;
        & .moe-btn-appstore,
        & .moe-btn-googleplay {
            width: 10rem;
            &__img {
                width: 10rem;
            }
        }
    }
    &__list {
        display: flex;
        //small devices below 1023px
        @media only screen and (max-width: $msv-container-max-width-ml) {
            flex-wrap: wrap;
        }
        &__item {
            width: 12rem;
            height: 24rem;
            margin-left:2.5rem;
            //small devices below 1023px
            @media only screen and (max-width: $msv-container-max-width-ml) {
                margin-left: 0;
                margin: 0.5rem;
            }
        }
    }
    &__images {
        display: flex;
        & .moe-playstore-img {
            width: auto;
            height: 100%;
        }
    }
}

.moe-mobileframe {
    &-portrait,
    &-landscape {
        background: image('moe-mobileframe.svg') no-repeat;
    }
    &-portrait {
        height: 24rem;        
        background-size: 100% auto;
        padding: 2.5rem 0.8rem;
    }
    &-landscape {
        &-wrap {
            position: relative;
            width: 24rem;
            height: 12rem;
            & .moe-playstore-img {
                width: auto;
                height: 10.5rem;
                position: absolute;
                top: 0.7rem;
                left: 4rem;
            }
        }
        position: absolute;
        height: 24rem;
        width: 12rem;       
        background-size: 100% auto;
        transform: rotate(90deg);
        padding: 0;
        left: 30%;
        top: -50%;
    }    
}

$msv-alert-close-button-color: var(--msv-font-primary-color);
$msv-alert-close-button-right: 0.625rem;
$msv-alert-border-radius: 0.625rem;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-border: 1px solid transparent;

$msv-alert-icon: $msv-IncidentTriangle;
$msv-alert-icon-margin: 8px;
$msv-alert-line-margin-top: 8px;

.msc-alert {
    @include font-content-m(600);
    border: $msv-alert-border;
    border-radius: $msv-alert-border-radius;
    display: block;
    margin-bottom: $msv-alert-margin-bottom;
    padding: $msv-alert-padding;
    position: relative;
    text-transform: none;
    text-align: center;
    min-width: 17rem;
    max-width: 80%;
    margin: 2rem auto;

    &::before {
        color: $msv-alert-close-button-color;
        position: absolute;
        right: $msv-alert-close-button-right;
    }

    .msi-exclamation-triangle {
        margin-right: $msv-alert-icon-margin;

        @include add-icon($msv-alert-icon, before);
    }

    .msc-alert__line {
        margin-top: $msv-alert-line-margin-top;
    }

    .close {
        @include add-icon($msv-x-shape);
    }
    &__actions {
        text-align: center;
    }
}

.msc-alert__header {
    background-color: transparent;
    color: var(--msv-error-color);
    border-color: transparent;
    padding: 0;

    @include font-content-m(600);
    border: $msv-alert-border;
    border-radius: $msv-alert-border-radius;
    display: block;
    margin-bottom: $msv-alert-margin-bottom;
    position: relative;
    text-transform: none;

    .msi-exclamation-triangle {
        margin-right: $msv-alert-icon-margin;

        @include add-icon($msv-alert-icon, before);
    }
}

//Alerts Info, Success, Warning and Error
.msc-alert-info {
    color: $moe-info-color;
    background-color: $moe-info-bg-color;
    border-color: $moe-info-color;
}
.msc-alert-success {
    color: $moe-success-color;
    background-color: $moe-success-bg-color;
    border-color: $moe-success-color;
}
.msc-alert-warning {
    color: $moe-warning-color;
    background-color: $moe-warning-bg-color;
    border-color: $moe-warning-color;
}
.msc-alert-danger {
    color: var(--msv-error-color);
    background-color: $msv-pink;
    border-color: var(--msv-error-color);
}

.msc-alert-noborder {
    background-color: transparent;
    color: var(--msv-error-color);
    border-color: transparent;
    margin-bottom: 0;
    padding: 0;
}


//MOE Styles override
.moe-alert{
    //Warning type alerts
    &-emptycart,
    &-downloading {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
        &__redirectionUrl{
            cursor: pointer;
        }
        &__txt {
            font-size: 1.25rem;
            padding: 2rem;
            margin-bottom: 0 !important;
            text-align: center;
            color: $moe-orange !important;
        }
    }
    &__actions {
        padding: 0.5rem 1.5rem;
        text-align: center;
    }
}
$video-player-width: 100%;

// Style presets
:root {
    --msv-video-player-width: #{$video-player-width};
    --ms-transcript-btns-color: $moe-gray-drkst;
}

.ms-video-player {
    .msc-videoplayer {
        width: var(--msv-video-player-width);
    }
}

/* 
  Name: Transcript styles 
  Styled container: transcript
*/
.ms-video-transcript {
    width: 100%;
    height: 100%;
    border: 1px solid $moe-gray-400;
    padding: 0.8rem 1.4rem;
    /* Transcript: top container styles */
    &__top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__top-left {
        display: flex;
        align-items: center;
        & span {
            font-size: 1.2rem;
            margin-left: 0.5rem;
        }
    }
    &__icon {
        font-size: 1.5rem !important;
    }
    &__top-right {
        .ms-video-transcript__download-icon {
            margin-right: 0.8rem;
        }

        svg {
            font-size: 1.8rem !important;
            margin-top: 0.3rem;
            cursor: pointer;
        }
    }
    /* Transcript: bottom container styles */
    &__btm-container {
        display: flex;
        width: 98%;
        & p {
            color: var(--ms-transcript-btns-color);
            font-size: 0.8rem;
            margin: 0;
            padding: 0;
            margin-top: 1rem;
            margin-left: 0.2rem;
        }
    }
}

//Vimeo vedio player
.moe-vimeo-player {
    margin-top: 2rem;
    margin-bottom: 2rem;
    &__ttl {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    &__iframe {
        &-cont {
            background-color: $moe-gray-400;
        }
        width: var(--msv-video-player-width);
        min-height: 12.5rem;
        //Tablet Responisve UI
        @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-breakpoint-m) {
            min-height: 25rem !important;
        }
        //Tablet Responisve UI
        @media only screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-ml) {
            min-height: 27rem !important;
        }
        //Medium devices between 1025px and 1439px
        @media only screen and (min-width: $msv-container-min-width-lxl) and (max-width: $msv-container-max-width-l) {
            min-height: 30rem !important;
        }
        //Large devices above 1440px
        @media only screen and (min-width: $msv-breakpoint-xl) {
            min-height: 36rem !important;
        }
    }
}

@media print {
    .ms-search,
    .ms-breadcrumb,
    .ms-cookie-compliance,
    .moe-sensitivebnr {
        display: none !important;
    }

    .ms-search-result-container__Sort-by-category {
        display: none !important;
    }

    button.moe-listview__button-desktop {
        border-color: #000;
    }

    .moe-header-wrap {
        box-shadow: none;
    }

    .ms-footer__list-item img {
        background-color: #000 !important;
    }
}
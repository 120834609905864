//kwr-form

//Base theme overrides for MOE sites
label.reviews-list-sort-by {
    font-weight: bold;
}

input[type='date'], input[type='email'], 
input[type='password'], input[type='reset'], 
input[type='text'], select, 
textarea {
    &:focus {
        border: 0.125rem solid $kwr-red-active !important;
    }
}


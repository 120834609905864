//kwr-atua-search

.kwr-category-body {
    //Filter section bg for min width 1500px and above resolutions
    @media only screen and (min-width: $msv-container-min-width-xl) {
        position: relative;
        &::after {
            content: " ";
            display: block;
            width: 20rem;
            height: 100%;
            background-color: $color-grey-dark;
            position: absolute;
            right: 0;
            margin-top: -1rem;
        }
    }
    & .kwr-search-wrap,
    & #renderPage,
    & main {
        //Filter section bg for min width 993px and above resolutions
        @media only screen and (min-width: $msv-container-min-width-l) {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 20rem;
                height: 100%;
                background-color: $color-grey-dark;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    & .ms-search-result-container {
        & .moe-local-search-form {
            max-width: 28rem !important;
        }
        &__refine-product-section {
            margin-bottom: 0;
        }
        &__refiner-section {
            margin-bottom: -15px;
        }
        &__product-section {
            & .ms-search-result-wrapper-title-choice-summary {
                left: 20rem;
                max-width: 70%;
                //Devices 1280px and above
                @media only screen and (min-width: $msv-breakpoint-mxl) {
                    left: 20%;
                }
                //Devices 1500px and above
                @media only screen and (min-width: $msv-container-min-width-xl) {
                    left: 25%;
                }
                //Tablet Responsive UI
                @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                    left: 15rem;
                }
                //Mobile Responsive UI
                @media only screen and (max-width: $msv-container-max-width-s) {
                    left: 2rem;
                }
            }
        }
        &__Products {
            //Devices 1280px and above
            @media only screen and (min-width: $msv-breakpoint-mxl) {
                margin-top: 10%;
                margin-left: 20%;
                & .ms-product-search-result__item {
                    flex: 1 1 18rem;
                    max-width: 18rem;
                }
                & .product-item-main-container {
                    & .msc-product {
                        &__image {
                            height: 9rem;
                            padding-top: 54%;
                        }
                        &__title {
                            margin-top: 0.5rem;
                        }
                        &__text {
                            height: 4rem;
                            margin-bottom: 0.5rem;
                        }
                    }
                    & .moe-product-text-arrow {
                        padding-top: 0;
                    }
                }
            }
            //Devices 1500px and above
            @media only screen and (min-width: $msv-container-min-width-xl) {
                margin-top: 12%;
                margin-left: 25%;
            }
        }
    }

    & .ms-breadcrumb {
        margin-left: -0.5rem;
        & *,
        &_link {
            color: $white !important;
        }
        &_link {
            &:hover {
                text-decoration: underline;
            }
        }
        &_item-current {
            & .ms-breadcrumb_link:hover {
                text-decoration: none;
            }
        }
    }
    
    //Footer position for Atua view specific
    footer {
        position: relative;
        z-index: 15;
        bottom: -24rem;
        //Larger devices 1440px and above
        @media only screen and (min-width: $msv-container-min-width-xl) {
            position: absolute;
            width: 100%;
            bottom: -80%;
        }
    }
}

.kwr-atua {
    //Small devices below 1023px width
    @media only screen and (max-width: $msv-container-max-width-ml) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

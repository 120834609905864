.ms-moe-doc {
    display: flex;
    flex-direction: column;

    li.ms-moe-doc-list-item {
        margin-right: 0.5rem;
        & a {
            width: 2rem;
            height: 2.5rem;
            &[title='Image File'] {
                margin-left: 0.3125rem;
                margin-right: 0.3125rem;
            }
        }
    }

    ul.ms-moe-doc-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        max-height: 170px;
        overflow: auto;
    }

    .ms-moe-doc-list_title {
        display: block;
        font-weight: unset !important;
        padding-left: 4px;
        font-size: 0.875rem;
        margin-top: 0.3125rem;
        margin-bottom: 0.5rem;
    }
}

.moe-common-catridge {
    max-height: 13rem;
    overflow-y: auto;

    .moe-download-common-catridge {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-left: 0.3rem;
        margin-top: 1.1rem;

        &:hover {
            text-decoration: none;
        }

        svg {
            width: 1.5rem;
        }

        span {
            margin-left: 0.4rem;
        }
    }
}

$msv-order-history-padding-bottom: 28px;
$msv-order-history-heading-margin-bottom: 8px;
$msv-order-history-header-padding-bottom: 20px;
$msv-order-history-order-count-margin-left: 5px;
$msv-order-history-sales-order-padding-top: 40px;
$msv-order-history-sales-order-padding-bottom: 40px;
$msv-order-history-order-information-sales-id-padding-right: 5px;
$msv-order-history-order-information-receipt-id-padding-right: 5px;
$msv-details-order-information-channel-name-margin-bottom: 4px;
$msv-order-history-sales-lines-margin-top: 8px;
$msv-order-history-sales-line-picture-margin-right: 12px;
$msv-order-history-sales-line-picture-margin-top: 12px;
$msv-order-history-sales-line-picture-width: 81px;
$msv-order-history-sales-line-picture-height: 81px;
$msv-order-history-empty-image-width: 81px;
$msv-order-history-btn-order-details-margin-top: 20px;
$msv-order-history-order-information-created-date-padding-right: 5px;
$msv-order-history-order-information-created-date-padding-left: 5px;
$msv-order-history-order-information-count-padding-right: 5px;
$msv-order-history-order-information-count-padding-left: 5px;
$msv-order-history-order-information-amount-padding-left: 5px;
$msv-container-padding-left: 60px;
$msv-container-padding-right: 60px;
$msv-container-width: 100%;
$msv-order-history-group-delivery-heading-margin-right: 3px;
$msv-order-history-btn-keep-shopping-margin-top: 20px;
$msv-order-history-btn-keep-shopping-margin-bottom: 20px;
$msv-order-history-btn-more-margin-top: 20px;
$msv-order-history-alert-margin-top: 20px;
$msv-order-history-alert-margin-bottom: 20px;

//style presets
:root {
    --msv-order-history-border: #{$msv-gray-300};

    // heading
    --msv-order-history-heading-font-color: var(--msv-font-primary-color);
    --msv-order-history-heading-font-size: var(--msv-body-font-size-xl);

    // title
    --msv-order-history-title-font-color: var(--msv-font-primary-color);
    --msv-order-history-title-font-size: var(--msv-body-font-size-l);

    // text
    --msv-order-history-text-font-color: var(--msv-font-primary-color);
    --msv-order-history-text-font-size: var(--msv-body-font-size-m);

    // secondary button
    --msv-order-history-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-order-history-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-order-history-secondary-btn-border: var(--msv-accent-brand-color);

    // view mode
    --msv-order-history-view-mode-selected-btn-background: var(--msv-selected-button-background);
    --msv-order-history-view-mode-selected-hovered-btn-background: var(--msv-selected-hovered-button-background);
}

.ms-order-history {
    padding-bottom: $msv-order-history-padding-bottom;

    &__heading {
        @include font-content(
            $font-weight: var(--msv-font-weight-normal),
            $font-size: var(--msv-order-history-heading-font-size),
            $line-height: $msv-line-height-xl
        );
        color: var(--msv-order-history-heading-font-color);
        display: inline-block;
        margin-bottom: $msv-order-history-heading-margin-bottom;
    }

    &__header {
        padding-bottom: $msv-order-history-header-padding-bottom;
        border-bottom: 1px solid var(--msv-order-history-border);
        display: flex;
        flex-wrap: wrap;
    }

    &__ms-order-history-view-modes {
        @media screen and (min-width: $msv-breakpoint-m) {
            border-left: 1px solid var(--msv-order-history-border);
            margin-left: 12px;
            padding-left: 12px;
            float: right;
        }

        button {
            padding: 0 14px;
            color: $msv-primary;
            background: none;

            &:hover,
            &.msc-view-modes__selected {
                background: var(--msv-order-history-view-mode-selected-btn-background);
            }

            &:hover.msc-view-modes__selected {
                background-color: var(--msv-order-history-view-mode-selected-hovered-btn-background);
            }
        }

        &__list-view {
            @include add-icon($msv-List);
        }

        &__detailed-view {
            @include add-icon($msv-TH-Large);
        }
    }

    &__ms-order-history-filter {
        margin-left: auto;

        @media screen and (min-width: $msv-breakpoint-m) {
            margin-right: 5px;
        }

        &__expand-filter-button {
            background-color: unset;

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                text-align: center;
                vertical-align: text-bottom;
                line-height: 1.2;
                margin: 0 8px;
            }
        }

        &__expand-filter-button[aria-expanded='true'] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            button {
                align-items: center;
                background-color: transparent;
                border: transparent;
                display: flex;
                font-size: 16px;
                line-height: 20px;
                padding: 0 20px;
                height: 48px;
                cursor: pointer;
                width: 100%;

                &:hover {
                    text-decoration: none;
                }
            }

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: '';
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    &__order-count {
        margin-top: 10px;
        margin-left: $msv-order-history-order-count-margin-left;
        margin-right: $msv-order-history-order-count-margin-left;
    }

    &__sales-order {
        @include font-content-m();
        padding-top: $msv-order-history-sales-order-padding-top;
        padding-bottom: $msv-order-history-sales-order-padding-bottom;
        position: relative;
        border-bottom: 1px solid var(--msv-order-history-border);

        display: flex;
        flex-wrap: wrap;

        &__expand-products-button {
            background-color: unset;

            text-decoration-line: underline;
            font-size: 16px;
            line-height: 21px;

            @media screen and (min-width: $msv-breakpoint-m) {
                margin-top: 35px;
            }
        }

        &__expand-products-button-wrapper {
            flex-basis: 100%;
        }
    }

    &__order-information {
        flex-basis: 100%;

        &-channel-name {
            color: var(--msv-order-history-title-font-color);
            display: block;
            font-size: var(--msv-order-history-title-font-size);
            line-height: $msv-line-height-l;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
        }

        &-sales-id,
        &-receipt-id,
        &-channel-reference-id {
            display: block;
        }

        &-created-date {
            padding-right: $msv-order-history-order-information-created-date-padding-right;
            border-right: 1px solid var(--msv-order-history-border);
        }

        &-count {
            display: inline-block;
            padding-right: $msv-order-history-order-information-count-padding-right;
            padding-left: $msv-order-history-order-information-count-padding-left;
            border-right: 1px solid var(--msv-order-history-border);
        }

        &-amount {
            padding-left: $msv-order-history-order-information-amount-padding-left;
        }

        &-sales-id,
        &-receipt-id,
        &-channel-reference-id,
        &-created-date,
        &-count,
        &-amount {
            font-size: var(--msv-order-history-text-font-size);
            color: var(--msv-order-history-text-font-color);
        }
    }

    &__sales-lines {
        margin-top: $msv-order-history-sales-lines-margin-top;
        display: flex;
        flex-wrap: wrap;
    }

    &__sales-line-picture {
        margin-right: $msv-order-history-sales-line-picture-margin-right;
        margin-top: $msv-order-history-sales-line-picture-margin-top;
        width: $msv-order-history-sales-line-picture-width;
        height: $msv-order-history-sales-line-picture-height;
    }

    &__btn-order-details {
        @include secondary-button(
            $bg-color: var(--msv-order-history-secondary-btn-bg),
            $color: var(--msv-order-history-secondary-btn-font-color),
            $border-color: var(--msv-order-history-secondary-btn-border)
        );
        margin-top: $msv-order-history-btn-order-details-margin-top;
    }

    &__groups {
        display: flex;
    }

    &__empty-message,
    &__alert {
        display: block;
        margin-top: $msv-order-history-alert-margin-top;
        margin-bottom: $msv-order-history-alert-margin-bottom;
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__btn-order-details {
            position: unset;
            width: 100%;
        }

        &__btn-keep-shopping {
            width: 100%;
        }
    }

    @include image($msv-order-history-empty-image-width);

    &__btn-keep-shopping {
        margin-top: $msv-order-history-btn-keep-shopping-margin-top;

        @include primary-button();
    }

    &__btn-more {
        &.is-busy {
            @include add-spinner(before);
        }

        margin-top: $msv-order-history-btn-more-margin-top;

        @include primary-button();
    }

    &__table-list {
        width: 100%;
        border-collapse: collapse;

        &__header {
            text-align: left;

            @include divider(bottom);

            &__row {
                height: 50px;

                th {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 21px;
                }

                &__column-with-number {
                    text-align: right;
                }
            }
        }

        &__row {
            height: 54px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 21px;

            @include divider(bottom);

            &__view-details-button {
                @include secondary-button();
            }

            &__mobile-cell {
                &__open-details {
                    display: grid;
                    cursor: pointer;
                    padding: 10px 0;
                }

                &__mobile-order-id {
                    font-weight: bold;
                }

                &__mobile-created-date {
                    font-size: 14px;
                    line-height: 18px;
                    padding-top: 8px;
                }

                &__view-details-button {
                    @include secondary-button();
                }
            }

            &__column-with-number {
                text-align: right;
            }
        }

        th,
        td {
            padding: 20px;

            &.ms-order-history__table-list__header__row__mobile-cell,
            &.ms-order-history__table-list__row__mobile-cell {
                padding: unset;
            }
        }
    }

    .moe__download-history {
        margin: 2rem 2rem 0 2rem;

        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            margin: 2rem 1rem 0rem 1rem;
        }

        &__container {
            &__items {
                display: flex;
                // grid-auto-flow: column;
                // grid-gap: 1.5rem;

                //Mobile Responsive UI
                @media only screen and (max-width: $msv-container-max-width-s) {
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }

        .download-item {
            border-bottom: 0.2rem solid lightgray;
            margin-bottom: 5rem;
            width: 40.625rem;

            //Tablet Responsive UI
            @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: 30rem;
            }

            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                width: auto;
                margin-bottom: unset;
            }

            &__header {
                display: flex;
                justify-content: space-between;
                border-bottom: 0.2rem solid lightgray;

                h1, h2, h3, h4,
                & .download-id, 
                & .download-date {
                    font-size: 1.125rem; //18px
                    line-height: 120%;
                    margin-bottom: 1rem;
                    color: $moe-gray-drkst !important;
                }
            }

            &__resources {
                display: grid;
                grid-gap: 1rem;

                &:last-child {
                    margin-bottom: 1rem;
                }

                &__item {
                    display: flex;
                    margin-top: 1rem;

                    .resource-image {
                        width: 6rem;
                        margin-right: 1rem;
                    }

                    .resource-content {
                        margin-top: 1rem;
                        & .resource-title {
                            h1, h2, h3, h4 {
                                font-size: 1.025rem; //16.4px
                                line-height: 120%;
                                color: $moe-gray-drkst !important;
                            }
                        }
                        .resource-selected-articles {
                            &__lbl {
                                font-weight: bold;
                                color: $moe-grey;
                                margin-top: 0.5rem;
                            }
                            ul {
                                list-style: none;

                                @media only screen and (max-width: $msv-container-max-width-s) {
                                    padding: unset;
                                }

                                .article-item {
                                    font-weight: 600;
                                    margin: 5px 0px;

                                    a {
                                        pointer-events: none;
                                    }

                                    svg {
                                        margin-right: 0.5rem;
                                        font-size: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .download-button {
            margin-left: 5rem;
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                margin-left: unset;
                margin-bottom: 4rem;
            }

            //Tablet Responsive UI
            @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                margin-left: 3rem;
            }

            // &__btn {
            //     color: #fff;
            //     min-width: 8rem;
            //     padding: 0.8rem;
            //     font-weight: 900;
            //     font-size: 0.7rem;
            //     border-radius: 3rem;
            //     background: #1470e1 0% 0% no-repeat padding-box;
            //     border: 1px solid #1470e1;
            //     cursor: pointer;

            //     &:hover {
            //         background-color: var(--msv-selected-button-hover-background) !important;
            //     }
            // }
        }
    }

    .ms-order-history {
        &__btn-more {
            margin-left: 2rem;
            margin-top: unset;
            letter-spacing: 0.05rem;
            font-weight: 500;
            text-transform: uppercase;
            border-radius: 1.563rem;

            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                margin-left: 1rem;
            }
        }
    }

    .ms-order-history__header {
        margin-left: 2rem;

        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            margin-left: 1rem;
        }
    }
}

//moe-page

.moe-page {
    max-width: $msv-breakpoint-xl;
    margin: 0 auto;
    &__content {
        & .moe-vimeo-player{
            &__iframe{
                &-cont {
                    max-width: $msv-breakpoint-l;
                }
            }
        }
    }
}

//Right body container adjustable width for Left Side menu toggle
.row > {
    & div.col-auto:has(.show) + div.col-auto {
        width: calc(100% - 22rem);
    }
    & div.col-auto:has(.fade) + div.col-auto {
        width: calc(100% - 8rem);
        & .moe-vimeo-player{
            &__iframe-cont{
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    & div.col-auto:has(.show),
    & div.col-auto:has(.fade) {
        & + div.col-auto {
            //Responsive UI 991px and below
            @media only screen and (max-width: $msv-container-max-width-m) {
                width: 100%;
                padding: 4rem 1.5rem 1.5rem 1.5rem;
            }
        }
    }
}

//Side menu toggle Responsive UI 991px and below
@media only screen and (max-width: $msv-container-max-width-m) {
    .moe-page {
        & > .row {
            position: relative;        
            & div.col-auto:has(.show),
            & div.col-auto:has(.fade) {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;                
                & .moe-side-nav-menu {
                    position: absolute;
                    min-height: auto;
                    height: 4rem;
                    width: 100%;
                    &__btn {
                        top: 0.5rem;
                        left: 0.5rem;
                    }
                }
                & .moe-side-nav {
                    padding-top: 4rem;
                }
            }
        }
    }
}

//The above used pseudo-class :has() does not support Firfox. Hence provided alternate fix below  
@-moz-document url-prefix() {
    .moe-page {
        & .row > {
            & div.col-auto + div.col-auto {
                width: calc(100% - 22rem);
                @media only screen and (max-width: $msv-container-max-width-m) {
                    width: 100%;
                    padding: 4rem 1.5rem 1.5rem 1.5rem;
                }
            }
            //Side menu toggle Responsive UI 991px and below
            @media only screen and (max-width: $msv-container-max-width-m) {
                & div.col-auto:first-child {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;                
                    & .moe-side-nav-menu {
                        position: absolute;
                        min-height: auto;
                        height: 4rem;
                        width: 100%;
                        &__btn {
                            top: 0.5rem;
                            left: 0.5rem;
                        }
                    }
                    & .moe-side-nav {
                        padding-top: 4rem;
                    }
                }
            }
        }
    }
}
//kwr-icons

//Base theme overrides for MOE sites
.moe-side-nav-wrap {
    & .moe-icon {
        &-audio, &-video, &-mobileapp, &-dg-interactive, &-web {
            width: 1rem;
            height: 1rem;
        }
    }
}


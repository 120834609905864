.moe-checkout {
    &-welcome {
        background-color: #27253e;
        margin-bottom: 0px;
    }
}
.ms-checkout {
    background-color: #27253e;
    margin-top: 0px;
    padding: 40px;
}
.moe-form__label {
    color: white;
}

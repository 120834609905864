//moe section

.moe-section {
    display: flex;
    padding: 0;
    margin-bottom: 3.5rem;
    position: relative;
    &__aside,
    &__body {
        height: 100%;
        position: relative;
        & > .row,
        & > div {
            height: 100%;
        }
        //Mobile and Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            padding-top: 3rem;
        }
    }
    &__aside {
        display: flex;
        padding: 2rem 2rem 2rem 6.05rem;
        background-color: #707070;
        //Mobile, Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            padding: 1.5rem 1.5rem 1.5rem 4.8rem !important;
        }
        //Tablet landscape and Base Desktop Responsive UI
        @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
            padding-left: 1.5rem !important;
        }
        &--bgwhite {
            background-color: #ffffff;
        }
        &--bgpurple {
            background-color: #83249c;
        }
        &--bgblue {
            background-color: #2d5299;
        }
        &--bgclay {
            background-color: #945440;
        }
        &--bgyaleblue {
            background-color: #13498d;
        }

        & > .row {
            width: 100%;

            & .msc-tag-divider {
                width: 100%;
            }
        }

        //Sticky Aside Content
        &-content {
            position: sticky;
            top: 2rem;
        }

        //Mobile and Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            width: 100%;
            height: auto;
            padding: 0 !important;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }
    &__content {
        padding: 1rem;

        a {
            text-decoration: underline;
        }

        h4 {
            font-weight: bold;
        }
    }
    &__actions {
        display: flex;
    }
    &--resourcedetail {
        & > .row {
            & > div[class^='col'] {
                //Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-l) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
        //Mobile and Tablet
        @media only screen and (max-width: $msv-breakpoint-l) {
            margin-bottom: 0;
        }
    }
    &--mx-w974 {
        max-width: 60.875rem !important;
        padding: 0 1.5rem !important;
        margin: 0 auto !important;
        & .moe-image-wrap {
            max-width: 100%;
            height: auto;
            & .moe-image, img {
                width: 100%;
                height: auto;
            }
        }
    }
}

//moe field
.moe-field {
    padding: 1.5rem !important;
    &__col-lft,
    &__col-rgt {
        height: 100%;
        padding: 0;
        margin: 0;
    }
    &__col-rgt {
        padding-left: 1rem;
    }
    & .ms-ratings-histogram__ratings__heading {
        display: none;
    }
    & .ms-ratings-histogram__ratings {
        display: flex;
    }
    & .ms-ratings-histogram__ratings__reviews {
        margin-left: 0.8rem;
    }
    &__actions {
        & .row > .col-12 {
            flex: 0 0 24%;
            max-width: 24%;
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-xs) {
                flex: 0 0 90%;
                max-width: 90%;
            }
            //Tablet and Base Desktop Responsive UI
            @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-breakpoint-xl) {
                flex: 0 0 45%;
                max-width: 45%;
            }
            & .col-12 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        &-item {
            max-width: fit-content;
            //Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-container-max-width-ml) {
                margin-bottom: 2rem;
            }
        }

        //Bug 69845 fix start
        & .moe-btn-iconlink--addtocart,
        & .moe-btn-iconlink--downloadresources {
            position: relative;
            width: 10.5rem;
            & .ms-quickView__button {
                width: 10.5rem;
                z-index: 1;
            }
            & .moe-btn-iconlink__txt {
                position: absolute;
                left: 2.25rem;
                white-space: nowrap;
            }
        }
        //Bug 69845 fix end
    }
    &--mx-w974 {
        max-width: 60.875rem !important;
        margin: 0 auto !important;
        & .moe-image-wrap {
            max-width: 100%;
            height: auto;
            & .moe-image, img {
                width: 100%;
                height: auto;
            }
        }
    }
}

// moe Cateogry Landing view summary section styles starts
.moe-la-summary {
    max-width: $msv-breakpoint-xl;
    margin: 0 auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    //Responsive UI 1280px and above
    @media only screen and (min-width: $msv-breakpoint-mxl) {
        padding-left: 10rem;
        padding-right: 10rem;
    }
    //Accordion section
    .ms-accordion-item__drawer {
        & .collapse.show {
            min-height: auto !important;
        }
    }
    & .moe-image-wrap {
        justify-content: center;
        height: 26rem;
        margin-left: auto;
        margin-right: auto;
    }
    & .moe-container-row {
        text-align: center;
    }
    & .default-container-2-slots,
    & .moe-container-2col {
        & .moe-image-wrap {
            width: calc(100% - 2rem);
            height: 15rem;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }
}

.ms-moe-doc {
    padding: 0 0.6rem;
}

//Bug 89510 Issues Fix
.moe-section--resourcedetail {
    & .moe-section__body {
        & .moe-field {
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-breakpoint-s) {
                 .container-heading, .ms-text-block,
                 .ms-accordion, .moe-container-2col, 
                 .msc-ss-carousel {
                    max-width: 85%;
                    margin-left: 0;
                    margin-right: 0;
                }
                 .ms-tab-content-section {
                    padding-left: 0;
                    padding-right: 0;
                    & .ms-accordion {
                        max-width: 100%;
                        & .drawer__buttontext {
                            margin-left: 0;
                        }
                    }
                 }
            }
        }
    }
}
//moe ebook

.moe-ebook {
    position: relative;
    height: 34rem;
    max-width: $msv-breakpoint-xl;
    border: 1px solid $moe-grey;
    padding-top: 2rem;
    //Tablet and Mobile 768px and below
    @media only screen and (max-width: $msv-breakpoint-m) {
        padding-top: 6rem;
    }
    margin: 2rem auto;
    &-controls {
        width: 100%;
        display: flex;
        position: absolute;
        top: 0;
        background-color: $moe-white;
        text-align: center;
        text-transform: uppercase;
        justify-content: space-between;
        z-index: 1;
    }    
    & button {
        font-family: $msv-primary-font-family;
        font-weight: 700;
        color:$moe-gray-drkst !important;
        &:hover {
            background-color: $white;
            color: $moe-blue-active !important;
        }
        &[disabled],
        &[disabled]:hover {
            background-color: #ebebeb;
            color: $moe-gray-drkst !important;
            cursor: not-allowed;
            text-decoration: none !important;
            opacity: 0.5;
        }
    }
    & > div {
        & > div {
            & > button:first-child {
                & span {
                    background: $moe-gray-drkst !important;
                }
            }
        }
    }
    &__page {
        position: absolute;
        bottom: 1rem; 
        right: 1rem;
        left: 1rem;
        text-align: center;
        z-index: 1;
    }
}

//Responsive UI 1440px and below
@media only screen and (max-width: $msv-breakpoint-xl) {
    .moe-ebook {
        height: 38rem;
        margin: 1.5rem;
    }
}
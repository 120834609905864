// desktop
$moe-sensitivebnr_container-padding-l: 60px;
$moe-sensitivebnr_container-padding-m: 20px;
$moe-sensitivebnr_container-padding-s: 16px;
$moe-sensitivebnr_container-max-width: $msv-breakpoint-xl;
$moe-sensitivebnr_content-width: 100%;
$moe-sensitivebnr_accept-btn-margin-left: 48px;
$moe-sensitivebnr_accept-btn-width: 160px;

// mobile
$moe-sensitivebnr_accept-btn-width-mobile: 243px;
$moe-sensitivebnr_accept-content-margin-bottom-mobile: 24px;

// style preset
:root {
    // background
    --msv-moe-sensitivebnr-bg-color: var(--msv-bg-color);

    // font
    --msv-moe-sensitivebnr-font-color: var(--msv-font-primary-color);
    --msv-moe-sensitivebnr-font-size: var(--msv-body-font-size-m);

    // link
    --msv-moe-sensitivebnr-link-color: var(--msv-font-primary-color);

    // primary button
    --msv-moe-sensitivebnr-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-moe-sensitivebnr-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-moe-sensitivebnr-primary-btn-border: var(--msv-accent-brand-color);
}
.moe-sensitivebnr {
    background-color: var(--msv-moe-sensitivebnr-bg-color);
    color: var(--msv-moe-sensitivebnr-font-color);
    font-size: var(--msv-moe-sensitivebnr-font-size);

    &__container {
        display: flex;
        font-weight: normal;
        max-width: $moe-sensitivebnr_container-max-width;
        margin: 0 auto;

        @media screen and (min-width: $msv-breakpoint-s) {
            padding-left: $moe-sensitivebnr_container-padding-s;
            padding-right: $moe-sensitivebnr_container-padding-s;
        }

        @media (min-width: $msv-breakpoint-m) {
            padding-left: $moe-sensitivebnr_container-padding-m;
            padding-right: $moe-sensitivebnr_container-padding-m;
        }
    }

    &__content {
        width: $moe-sensitivebnr_content-width;
    }

    &__btn {
        float: right;
        margin-left: $moe-sensitivebnr_accept-btn-margin-left;
        width: $moe-sensitivebnr_accept-btn-width;

        @include primary-button(
            var(--msv-moe-sensitivebnr-primary-btn-bg),
            var(--msv-moe-sensitivebnr-primary-btn-font-color),
            var(--msv-moe-sensitivebnr-primary-btn-border)
        );
    }

    // Responsive UI for 768px and above

    p {
        font-size: var(--msv-moe-sensitivebnr-font-size);
        @media (min-width: $msv-breakpoint-m) {
            text-align: left;
        }
        margin: 0.625rem 0.5rem;
    }
}

// moe-sensitivebnr Styles Override for all MOE sites starts
.moe-sensitivebnr {
    background-color: #ffc744;
    border: 2px dotted #000;
    margin-bottom: 1px;
    &__container {
        align-items: center;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // Responsive UI for 767px and below
        @media (max-width: $msv-container-max-width-s) {
            display: block;
        }
        // Responsive UI for 768px and above
        @media (min-width: $msv-breakpoint-m) {
            flex-wrap: nowrap;
        }
    }
    &__btn {
        height: fit-content;
        padding: 0.625rem 0.9375rem;
        line-height: 120%;
        font-size: 20px;
        font-weight: 600;
        background-color: #000;
        border-color: #000;

        button {
            background: transparent;
            color: white;
            box-shadow: none;
            border: none;
        }
    }
}
// moe-sensitivebnr Styles Override for all MOE sites ends

@media (max-width: $msv-breakpoint-m) {
    .moe-sensitivebnr {
        &__content {
            margin-bottom: $moe-sensitivebnr_accept-content-margin-bottom-mobile;
        }
        &__btn {
            float: none;
            margin: 0;
            width: $moe-sensitivebnr_accept-btn-width-mobile;
        }
        &__container {
            align-items: center;
            flex-direction: column;
        }
    }
}

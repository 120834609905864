//moe utilities

.moe-boxshadow {
    -webkit-box-shadow: 3px 6px 9px #00000029;
    -moz-box-shadow: 3px 6px 9px #00000029;
    box-shadow: 3px 6px 9px #00000029;
}
.moe-txt-maori,
.moe-txt-english {
    display: block;
    width: 100%;
}
.fw-normal {
    font-weight: normal !important;
}
.fw-bold {
    font-weight: bold !important;
}

.p {
    &-auto {
        padding: auto !important;
    }
    &-t-0 {
        padding-top: 0 !important;
    }
    &-r-0 {
        padding-right: 0 !important;
    }
    &-b-0 {
        padding-bottom: 0 !important;
    }
    &-l-0 {
        padding-left: 0 !important;
    }
}

.m {
    &-auto {
        margin: auto !important;
    }
    &-t-0 {
        margin-top: 0 !important;
    }
    &-r-0 {
        margin-right: 0 !important;
    }
    &-r-2 {
        margin-right: 1rem !important;
    }
    &-b-0 {
        margin-bottom: 0 !important;
    }
    &-b-1 {
        margin-bottom: 0.5rem !important;
    }
    &-b-2 {
        margin-bottom: 1rem !important;
    }
    &-b-3 {
        margin-bottom: 1.5rem !important;
    }
    &-b-4 {
        margin-bottom: 2rem !important;
    }
    &-l-0 {
        margin-left: 0 !important;
    }
    &-l-2 {
        margin-left: 1rem !important;
    }
}

.mx {
    &-974 {
        max-width: 60.875rem;
    }
    &-w650 {
        max-width: 40.625rem;
    }
}

//Learning Area Background Classes
.bg-la-english {
    background-color: $la-english-color !important;
}
.bg-la-arts {
    background-color: $la-arts-color !important;
}

.bg-la-health-physical-education {
    background-color: $la-health-physical-education-color !important;
}

.bg-la-learning-languages {
    background-color: $la-learning-languages-color !important;
}

.bg-la-mathematics-statistics {
    background-color: $la-mathematics-statistics-color !important;
}

.bg-la-science {
    background-color: $la-science-color !important;
}

.bg-la-social-sciences {
    background-color: $la-social-sciences-color !important;
}

.bg-la-technology {
    background-color: $la-technology-color !important;
}
//Non-Learning Area Background Classes
.bg-la-unifying-curriculum {
    background-color: $la-unifying-curriculum-color !important;
}

//Static pages unifying curriculum main & complementory Background Classes 
.bg-unifying-curriculum-darkblue {
    background-color: $unifying-curriculum-darkblue !important;
}
.bg-unifying-curriculum-lightgreen {
    background-color: $unifying-curriculum-lightgreen !important;
}
.bg-unifying-curriculum-orange {
    background-color: $unifying-curriculum-orange !important;
}

//Actionable items color in learning areas background colored content sections (bug-69842)
.bg-la {
    &-english, &-arts, &-health-physical-education,
    &-learning-languages, &-mathematics-statistics, &-science,
    &-social-sciences, &-technology, &-unifying-curriculum {
        & a,
        a:hover {
            color: $white !important;
        }
    }
}

//Display types
.d {
    &-none {
        display: none !important;
    }
    &-block {
        display: block !important;
    }
    &-flex {
        display: flex !important;
        &-wrap {
            flex-wrap: wrap !important;
        }
        &-nowrap {
            flex-wrap: nowrap !important;
        }
    }
}


//Element disabled state

.disabled,
.disabled * {
    opacity: 0.7;
    pointer-events: none !important;
    cursor: not-allowed !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
.text-right {
    text-align: right !important;
}

.moe-link {
    cursor: pointer;
    text-decoration: underline;
}

.moe-epub-sync {
    color: red !important;
}

//All MOE sites Imagery Assets base path from public folder
$moe-images-path: '../../../images';

@function image($file) {
  @return url($moe-images-path + '/' + $file);
}

//Atua animated characters sequencing images paths functions
@function imageHaumia($file) {
  @return url($moe-images-path + '/Haumia/' + $file);
}
@function imageHineahuone($file) {
  @return url($moe-images-path + '/Hineahuone/' + $file);
}
@function imagePapa($file) {
  @return url($moe-images-path + '/Papa/' + $file);
}
@function imageRanginui($file) {
  @return url($moe-images-path + '/Ranginui/' + $file);
}
@function imageRongo($file) {
  @return url($moe-images-path + '/Rongo/' + $file);
}
@function imageRuaumoko($file) {
  @return url($moe-images-path + '/Ruaumoko/' + $file);
}
@function imageTane($file) {
  @return url($moe-images-path + '/Tane/' + $file);
}
@function imageTangaroa($file) {
  @return url($moe-images-path + '/Tangaroa/' + $file);
}
@function imageTawhirimatea($file) {
  @return url($moe-images-path + '/Tawhirimatea/' + $file);
}
@function imageTumatauenga($file) {
  @return url($moe-images-path + '/Tumatauenga/' + $file);
}
@function imageWhiro($file) {
  @return url($moe-images-path + '/Whiro/' + $file);
}




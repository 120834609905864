//moe promo banner
.ms-promo-banner {
    max-width: $msv-breakpoint-xl;
    background-color: $moe-yellow-accent;
    margin: 0 auto;
    padding: 0 1rem;
    &__carousel {
        max-height: 6.875rem;
        padding: 1rem;
        background-color: transparent !important;
    }
    &__content,
    &__text, 
    &__link {
        font-size: 1rem;
        font-weight: bold !important;
        color: $white;
    }
    &__content {
        display: flex;
        //flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 80%;
        margin: 0 auto;
        //Responsive UI
        @media (max-width: $msv-breakpoint-l) {
            max-width: 100%;
        }
        .ms-promo-banner__gotobtn {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            color: $white;
            margin: 0 0 0 2.5rem;
            & svg {                
                width: 1.5rem;
                height: 1.5rem;
                stroke-width: 1;
                fill: $white;
            }
            /*&::after {
                font-family: 'Font Awesome 5 Free';
                content: $msv-arrow-right;
                display: inline-block;
                width: 1.5rem;
                height: 1.5rem;
                color: $white;
                font-weight: bold;
                margin-left: 1rem;
                margin-right: 1rem;
            }*/
        }
    }
    .msc-carousel__inner {
        height: 100% !important;
        background-color: transparent !important;
    }
    &__close-button {
        display: none;
    }
}
//kwr-search-page
//Base theme override for KWR search results view
.ms-search-result-container {
    &__Products {
        & ul .moe-och-srchresults {
            &__link {
                background: transparent;
                color: $kwr-yellow-active;
                border: 1px solid $kwr-txt-format;
                &:hover {
                    color: $kwr-red-active;
                }
            }
        }
    }
}


//KWR Search Results
.kwr-body-search {
    & main {
        background-color: $color-grey-mid;
        position: relative;
        z-index: 10;
        & .products-list-view__container {
            & .product-list__title,
            & .ms-quickView__button,
            & .moe-btn-iconlink--addtocollection, 
            & .moe-btn-iconlink--addtocart,         
            & .moe-btn-iconlink--downloadresources,
            & .moe-search-ext-resource--source,
            & p {
                color: $white;
            }
        }

        & .moe-search-artifacts,
        & .moe-search-ext,
        & .product-list-view {
            &-resources,
            &__item-wrap {
                & .moe-search-artifacts-resource,
                & .moe-search-ext-resource,
                & .product-list-view-item {
                    border-bottom: 1px solid $color-grey-light;
                    color: $moe-white;
                }
            }
        }

        & .collectionCard__details {
            padding: 1rem;
            &__title {
                font-size: 1.25rem;
            }
        }

        & .ms-search-result-container {
            & .moe-local-search-container {
                display: none;
            }
            &__product-section {
                padding-top: 0;
            }
            &__refiner-section {
                margin-top: 0;
                margin-right: 0.8rem;
            }
        }

        & .ms-search-result-container__Products {
            margin-top: 1.5rem;
            margin-left: 0;
        }
        & .product-list-view-item {
            width: 100% !important;
        }
    }
}

//kwr-resource-page
.kwr-page-wlcmcontentwrap {
    max-width: $msv-breakpoint-l;
    margin: auto;
}
.kwr-body {
    &--smplpgrs,
    &--smplflxrs,
    &--mltpgrs,
    &--mltpgsqrs,
    &--nolftcol,
    &--myaccount,
    &--g1landing,
    &--g2landing,
    &--statpg
     {
        background-color: $white;
        & .mega-dropdowns {
            margin-top: 3.7rem;
            width: 100%;
            height: 100vh;
            left: 0;
            & .mega.active,
            & .mega__title,
            & .mega .menu-close .close-text,
            & .mega__home-icon {
                position: absolute;
            }
            //Responsive UI between min-width 993px and max width 1280px
            @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
                & .mega__home-icon {
                    width: 20rem;
                    height: 28rem;
                }
                & .mega__title {
                    width: 12rem;
                    top: 10rem;
                    margin-left: 2rem;
                    text-align: center;
                    font-size: 1.5rem;
                }
                & .mega__menu-wrapper--atuas {
                    margin-left: 15rem;
                }
                & .mega__menu-wrapper {
                    margin-left: 20rem;
                }
            }
        }
        & .ms-breadcrumb {
            margin-top: 0.5rem;
        }
        & main {
            margin-top: 0.5rem !important;
            & > .moe-section {
                margin-bottom: 0;
            }
        }
        & .kwr-banner {
            background-color: $kwr-banner-bgdrk;
            & .ms-container-background-image {
                min-height: 16rem !important;
                background-size: 100% auto;
                //Resource pages Banner Responsive UI for max width 1280px and below
                @media only screen and (max-width: $msv-breakpoint-mxl) {
                    background-size: auto 100%;
                }
            }
        }
    }
    &--smplpgrs,
    &--mltpgrs,
    &--mltpgsqrs {
        & main {
            max-width: $msv-breakpoint-xl;
            margin: auto;
            //Side Menu Toggle implemented so, below section not required.
            /*Left aside Tags section dark bg for all Resource pages with min width 993px and above resolutions
            @media only screen and (min-width: $msv-container-min-width-l) {
                position: relative;
                &::after {
                    content: " ";
                    display: block;
                    width: 16rem;
                    height: 100%;
                    background-color: $color-grey-dark;
                    position: absolute;
                    top: 0;
                    left: 0;
                    //z-index: 0;
                }
            }
            //Left aside Tags section dark bg for all Resource pages with min width 1280px and above resolutions
            @media only screen and (min-width: $msv-breakpoint-mxl) {
                &::after {
                    width: 20rem;
                }
            }*/
        }
    }
    &--smplflxrs {
        & .msc-active-image {
            margin-bottom: 0;
            &-container .msc_image {
                width: auto;
            }
        }
        & .kwr-section,
        & .kwr-section-alt {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
    &--nolftcol {
        & .ms-tab-content-section {
            //Mobile only
            @media only screen and (max-width: $msv-breakpoint-s) {
                max-width: calc(100% - 5rem);
            }
            & .ms-tab-item-content__content {
                & .ms-image-list__link {
                    height: 30rem;
                    padding-top: 0;
                    //Mobile only
                    @media only screen and (max-width: $msv-breakpoint-s) {
                        height: 22rem;
                    }
                }
            }
        }
        & .ms-image-list {
            &__link {
                margin: 0 auto;
            }
        }
    }
}

//Side Menu Toggle base theme override and KWR specific styles
.moe-side-nav {
    &__item {
        &-des {
            color: $moe-gray-300;
        }
    }
}

.kwr-page {
    &__content {
        & .moe-playstore {
            margin: 2rem 0rem;
        }
    }

    & .moe-side-nav-wrap {
        margin-right: 0rem;
    }
}

//Small Mobile portrait only
@media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
    .ms-sequence-product-collection {
        width: 22.5rem;
    }
}
//Medium Mobile
@media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-s) {
    .ms-sequence-product-collection {
        width: 35rem;
    }
}

//kwr landing page

//Video player module dimentions override inside 2col container
.default-container-2-slots {
    & .moe-vimeo-player {
        &__iframe {
            min-height: 18rem !important;
            max-height: 28.125rem;
        }
    }
}

//Group 1,2,3 type Category Landing Pages 
.kwr-body {
    &--g1landing,
    &--g2landing {
        & .mega-dropdowns {
            margin-top: 3.7rem;
        }
    }
}

//News and Events Landing page
.kwr-body-newsevents {
    & main {
        background-color: $kwr-banner-bgdrk;
        &::after {
            display: none;
        }
    }
    & footer {
        position: relative;
        z-index: 10;
    }
}
.kwr-newsevents {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    background-color: $kwr-banner-bgdrk;
    position: relative;
    z-index: 10;
    //Responsive UI 1024px and above
    @media only screen and (min-width: $msv-breakpoint-ml) {
        min-height: 48rem;
        height: 100%;
    }
    &__content {
        display: block;
        width: $msv-breakpoint-l;
        max-width: $msv-breakpoint-xl;
        height: auto;
        margin: 0 auto;
        padding: 6rem 1rem;  
        position: relative;
        //Responsive UI for 991px and below
        @media only screen and (max-width: $msv-container-max-width-m) {
            width: 100%;
            padding-top: 0;
        }
        &::before {
            background-image: url(../../../images/whata-patoro.png); 
            background-repeat: no-repeat;
            background-size: contain;
            content: " ";
            display: block;
            width: 1235px;
            height: 3rem;
            height: 836.7650121486px;
            left: calc(50% - 617.5px);
            position: absolute;
            top: -6rem;
            border-radius: 20rem;
            overflow: hidden; 
            //Responsive UI for 991px and below
            @media only screen and (max-width: $msv-container-max-width-m) {
                display: none;
            }          
        }
        & > div.row {
            max-width: 43rem;
            margin: 0 auto;
            position: relative;
            top: 10rem;
            color: $moe-white;
        }
        /*& .ms-tab {
            &-content-section {
                max-height: calc(100vh - 190px);
                overflow: auto;
            }
        }*/
        & .ms-article-list {
            &__item {
                display: flex;
                margin-bottom: 2rem;
                border-bottom: 0.05rem solid $kwr-tags-bgdrk;
                & > div,
                &-container {
                    display: flex;
                    color: $moe-white;
                    //Mobile Responsive UI
                    @media only screen and (max-width: $msv-breakpoint-s) {
                        flex-wrap: wrap;
                    } 
                }
                &-image {
                    width: 13.75rem;
                    height: 7.75rem;                    
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                    & picture {
                        display: block;
                        width: 13.75rem;
                        height: 7.75rem;
                        overflow: hidden;
                    }
                    & img {
                        width: 100%;
                        height: auto;
                        border: none;
                    }
                }
                &-title {
                    margin-bottom: 0.5rem;
                }
                &-title, &-text {
                    color: $moe-white;
                }
                &-text, &-text:hover {
                    text-decoration: none;
                    cursor: default;
                }
                &-link {
                    color: $kwr-red-active;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

//Tabs section news articles
.kwr-tabs {
    & .card {
        width: 100%;
        &:hover, &:focus, &:active {
            box-shadow: none;
        }
        &-header {
            font-size: 1.5rem;            
        }
        &-body {
            font-size: 0.875rem;
        }
    }
}
//moe search
//Base styles override for MOE sites
.ms-search {
    &__autoSuggest {
        background-color: $white;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        & .msc-autoSuggest {
            &__productResults-title,
            &__categoryResults-title {
                font-size: 1.25rem;
                color: $moe-border-peach;
                // text-transform: capitalize;
            }
            &__productResults-item {
                display: flex;
                align-items: center;
                & img {
                    width: 5rem;
                    height: 3rem;
                    max-width: unset;
                }
            }
        }
        // &__product {
        //     & .msc-autoSuggest__productResults-title {                
        //         position: relative;
        //         color: transparent;
        //         &::before {
        //             content: "Go to resources";
        //             display: block;
        //             //color: $msv-gray-500;
        //             color: $moe-border-peach;
        //             position: absolute;
        //         }
        //     }
        // }
    }

    // & .msc-autoSuggest__keywordResults-items {
    //     display: none;
    // }
}

//Search
.moe-search {
    &-wrap {
        max-width: $msv-breakpoint-xl;
        margin: 0 auto;
        min-height: 5.3125rem;
        background-color: transparent;
        & .ms-search__searchForm {
            border-radius: 9px;
            outline: 5px solid $moe-grey;
            overflow: hidden;
        }
        & .ms-search {
            padding: 1.5rem;
            margin: 1.5rem auto 0 auto;
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                margin-top: 0;
            }
        }
        & .ms-search__form-control {
            max-width: 100%;

            &:focus {
                border: 1px dashed #2c262d;
            }
        }
        & .ms-search__form-submitSearch {
            background-color: $white;
        }
        &--english {
            & .ms-search__searchForm {
                outline: 5px solid $la-english-color !important;
            }
        }
        &--arts {
            & .ms-search__searchForm {
                outline: 5px solid $la-arts-color !important;
            }
        }
        &--health-physical-education {
            & .ms-search__searchForm {
                outline: 5px solid $la-health-physical-education-color !important;
            }
        }
        &--learning-languages {
            & .ms-search__searchForm {
                outline: 5px solid $la-learning-languages-color !important;
            }
        }
        &--mathematics-statistics {
            & .ms-search__searchForm {
                outline: 5px solid $la-mathematics-statistics-color !important;
            }
        }
        &--science {
            & .ms-search__searchForm {
                outline: 5px solid $la-science-color !important;
            }
        }
        &--social-sciences {
            & .ms-search__searchForm {
                outline: 5px solid $la-social-sciences-color !important;
            }
        }
        &--technology {
            & .ms-search__searchForm {
                outline: 5px solid $la-technology-color !important;
            }
        }
        &--unifying-curriculum {
            & .ms-search__searchForm {
                outline: 5px solid $la-unifying-curriculum-color !important;
            }
        }
    }

    &-communities {
        padding: 0;
        margin: 0;
        max-height: 28rem;
        &-col-lft,
        &-col-rgt {
            min-height: 23rem;
            height: 28rem;
            padding: 0;
            overflow: hidden;
            & .ms-container-background-image {
                min-height: 100% !important;
                background-size: auto 90% !important;
                background-position: bottom -0.8rem right !important;

                //Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-s) {
                    background-size: 90% auto !important;
                }
            }
        }
        &-col-lft {
            background-color: #00acbd;
        }
        &-col-rgt {
            background-color: #006f79;
        }
        &-cont {
            width: 50%;
            max-height: 18rem;
            color: #fff;
            font-size: 1rem;
            margin: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & h2,
        & h3 {
            font-size: 1.25rem;
        }
        & .ms-btn-tag {
            max-width: fit-content;
            margin: 1.5rem;
        }
        & .moe-btn,
        & a {
            min-width: fit-content;
            background: #fcb116 !important;
            border-radius: 0.625rem;
            box-shadow: 0px 2px 5px #00000080;
            cursor: pointer;
            margin: 1.5rem;
            color: $white !important;
            border: none;
            &:hover {
                background: #cc8f11 !important;
            }
            & .moe-btn-text, text {
                color: $white !important;
            }
        }
        & .moe-btn-text {
            width: 100%;
            white-space: nowrap;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
        }
        & .moe-btn-img-container {
            display: none;
        }
    }

    &-results-wrap {
        & .ms-search-result-container {
            &__category-nav-section {
                display: none;
            }
            &__refine-product-section {
                margin-bottom: 0;
            }
            &__refiner-section {
                background-color: #fff;
                border-right: 1px solid #707070;
                padding: 1.5rem;
            }
            &__product-section {
                background-color: $moe-green-lgter;
                padding: 1.5rem;
            }
        }
    }
}

/*Responisve UI Fixes*/
@media (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-l) {
    .moe-search-communities {
        max-height: max-content;
        div[class^='col'] {
            flex: 0 0 100%;
            max-width: 100%;
        }
        & .row {
            & .col-sm-6,
            & .col-md-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        //Responsive UI
        &-cont,
        & .moe-btn,
        & a {
            width: auto;
            max-width: 80%;
        }
    }
}

@media (min-width: $msv-breakpoint-s) and (max-width: $msv-breakpoint-ml) {
    .moe-search-communities {
        //Responsive UI
        &-cont,
        & .moe-btn,
        & a {
            width: auto;
            max-width: 50% !important;
        }
    }
}

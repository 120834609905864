//moe-seq-product-collection

.ms-sequence-product-collection {
    width: 50rem;
    padding: 0;
    margin: 1.5rem auto;
    //Responsive UI between 768px and 1280px
    @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
        width: 45rem;
    }
    //Mobile Responsive UI between 568px and 767pxv
    @media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-s) {
        width: 30rem;
    }
    //Mobile Responsive UI between 320px and 567px
    @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
        width: 24rem;
    }
    & .ms-sequence-product__heading {
        display: none;
    }
    & .msc-ss-carousel {
        &-strip {
            width: 100%;
            margin: 0 auto;
            outline: 1rem solid $white;
        }
        &__flipper {
            top: 10rem;
            left: -5rem;
            right: auto;
            border: 2px solid $moe-gray-300;
            &--next {
                left: auto;
                right: -5rem;
            }
            &:hover {
                border-color: $moe-gray-drkst;
            }
        }
        & .msc-flipper:not(.disabled):hover,
        & .msc-flipper:not(.disabled):focus {
            outline: none;
        }
    }
    .moe-seq-products {
        &__item {
            width: 50rem;
            height: 20rem;
            background-color: $white;
            //Responsive UI between 768px and 1280px
            @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                width: 45rem;
            }
            //Mobile Responsive UI between 568px and 767pxv
            @media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-s) {
                width: 30rem;
                height: auto;
            }
            //Mobile Responsive UI between 320px and 567px
            @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                width: 24rem;
                height: auto;
            }
            &-lft {
                width: 45%;
                padding: 1rem;
                position: relative;
                //Mobile Responsive UI between 320px and 567px
                @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                    width: 92%;
                }
            }
            &-rgt {
                width: 55%;
                padding: 0;
                position: relative;
                background-color: $msv-white;
                border: 1px solid $moe-gray-100;
                text-align: center;
                & img,
                .msc_image {
                    width: 100%;
                    height: auto;
                    margin: auto;
                }
                //Responsive UI for 767px and below
                @media only screen and (max-width: $msv-container-max-width-s) {
                    width: 45%;
                }
                //Mobile Responsive UI between 320px and 567px
                @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                    width: 92%;
                    max-height: 19rem;
                    position: absolute;
                    top: 0;
                    overflow: hidden;
                }
            }
        }
        &__ttl {
            font-family: $msv-primary-font-family;
            font-size: 1.25rem;
            line-height: 120%;
            margin-bottom: 1rem;
            color: $la-social-sciences-color;
            white-space: normal;
        }
        &__link {
            width: 50rem;
            height: 100%;
            //Responsive UI between 768px and 1280px
            @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                width: 45rem;
            }
            //Mobile Responsive UI between 568px and 767pxv
            @media only screen and (min-width: $msv-breakpoint-s) and (max-width: $msv-container-max-width-s) {
                width: 30rem;
            }
            //Mobile Responsive UI between 320px and 567px
            @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
                width: 24rem;
                position: relative;
                padding-top: 20rem;
            }
            &:hover {
                @extend .moe-boxshadow;
            }
        }
        &__des {
            font-size: 1rem;
            white-space: normal;
            padding-bottom: 3rem;
        }
        &__info {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            width: calc(100% - 3rem);
            font-size: 1rem;
            padding: 1rem 0;
            color: $la-social-sciences-color;
            border-top: 2px solid $la-social-sciences-color;
        }
    }
}

// Local variables
$moe-wishlist-gray-heading: $moe-grey;

.moe-wishlist-product-collection,
.moe-curated-product-collection {
    margin: 1.5rem;
    //Large devices 1440px and above
    @media only screen and (min-width: $msv-breakpoint-xl) {
        margin-left: 0;
        margin-right: 0;
    }

    .moe-btn-iconlink--addtocollection {
        display: none;
    }

    .moe-collection-products--heading {
        color: $moe-wishlist-gray-heading;
        font-size: 18px;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    .moe-wishlist-products-remove-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        margin-top: 1rem;
        min-width: 0 !important;
        padding: 0.2rem 1rem;

        svg {
            margin-left: 0.5rem;
            font-size: 1rem;
        }
    }

    .product-item-main-container {
        .msc-product {
            display: block;
        }
    }

    .msc-product__text {
        color: black;
    }
}

.moe-curated-product-collection {
    min-height: 25rem;

    .moe-btn-iconlink--addtocollection {
        display: block !important;
        color: black !important;
    }

    .ms-product-collection__items {
        .ms-product-collection__item {
            .moe-curated-product-collection {
                color: black !important;
            }
        }
    }
}

.moe-search-curated-collections__container {
    margin-top: 3rem;

    .collectionCard__thumbnail {
        width: 14rem;
        // border: 0.1px solid #e3e3e3;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

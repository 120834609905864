//moe-checkout

//Base theme override for MOE sites

.ms-checkout {
    padding: 0;
    max-width: $msv-breakpoint-xl;
    margin: 0 auto;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    &__line-items {
        &-header {
            margin-bottom: 1rem;
            text-align: right;
            & a {
                float: none;
                height: auto;
            }
        }
        /*&-edit-cart-link {
            float: none !important;
            height: auto;
            font-size: 1rem !important;
        }
        &-group-title-multiple-pickup-shipicon {
            margin: 5px 0 !important;
            font-size: 1.5rem;
        }
        &-group-title-multiple-pickup-heading,
        &-group-title-multiple-pickup-subheading {
            margin-left: 3.75rem;
        }
        &-group-title-multiple-pickup-subheading {
            margin-bottom: 2rem;
        }*/
        &-edit-cart-link,
        &-group-title-multiple-pickup-shipicon,
        &-group-title-multiple-pickup-heading,
        &-group-title-multiple-pickup-subheading {
            display: none;
        }
    }
    
    &__main {
        display: flex;
        //Responisve UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            flex-wrap: wrap;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            justify-content: center;
        }

        max-width: 100%;
        justify-content: space-between;
        padding-right: 0;
        &-control {
            flex-direction: row;
            align-items: center;
            margin-bottom: 4rem;
            //Mobile only
            @media only screen and (max-width: $msv-container-max-width-xs) {
                justify-content: center;
                & .moe-form__label,
                & .msc-btn {
                    margin-right: 0;
                }
            }
            //Responisve UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                flex-wrap: wrap;
                & .moe-form__control--checkout {
                    margin-bottom: 1.5rem;
                }
                & .msc-btn {
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;
                }
            }
            & label {
                margin-right: 3rem;
            }
        }
    }
    &__line-items {
        background: none !important;
        padding: 0;
        margin-right: 1.5rem;
        border-top: 1px solid $moe-grey;
        border-bottom: 1px solid $moe-grey;
        margin-bottom: 1rem;
    }
    &__line-item {
        display: flex;
        flex-direction: column;
        min-height: 9.375rem;
        padding: 1rem !important;
        margin-bottom: 1.25rem;
        background-color: $moe-gray-100;
        //border-bottom: 1px solid var(--msv-mini-cart-icon-header-border-color);
        position: relative;
        //Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            .msc-cart-line {
                flex-wrap: wrap;
                margin-top: 0;
                &__product-image {
                    margin-bottom: 1rem;
                }
                &__content {
                    width: 100% !important;
                }
            }
        }
        .msc-cart-line__product-edit{
            display: none;
        }
    }
}

//moe-checkout

.moe-checkout {
    padding: 0;
    &-welcome {
        padding: 3rem !important;
        background-color: $moe-orange;
        max-width: $msv-breakpoint-xl;
        margin: 0 auto 1.5rem auto;

        & .ms-text-block {
            border-bottom: 1px solid $moe-white;
            margin-top: 0;
        }
        & h1, h2, h3 {
            font-size: 2rem !important;
            font-weight: bold;
            color: $moe-white;
        }
        & p {
            font-size: 1rem !important;
            color: $moe-white;
        }       
    }
    &-wrap {
        width: $msv-breakpoint-m;
        //Responsiv UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            width: 100%;
        }
    }
    &-confirm {
        width: 26.25rem;
        height: max-content;
        //margin-right: 1.5rem;
        background-color: $moe-white;
        border: 2px solid $moe-grey;        
        @extend .moe-boxshadow;
        //Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            width: 100%;
            max-width: 26.25rem;
            position: relative;
            top: -2rem;
        }
        &__header,
        &__content {
            padding: 1.5rem;
            & p {
                line-height: 130%;
            }
        }
        &__header {
            font-size: 1.25rem;
            font-weight: normal;
            color: $moe-white;
            background-color: $moe-yellow-accent;
            text-align: center;
        }
        &__content {
            text-align: center;
        }
        &__signin {
            padding-bottom: 1rem;
            border-bottom: 1px solid $moe-grey;
            margin-bottom: 1.5rem;
            & .moe-btn-esl {
                margin: 0.5rem auto;
            }
        }
        &__email {
            & input {
                min-width: 18.75rem;
                margin-top: 0.8rem;
                //Responsive UI
                @media only screen and (max-width: $msv-breakpoint-l) {
                    min-width: 100%;
                }
            }
        }
        &__actions {
            margin-top: 1.5rem;
        }
        &.disabled {
            & .moe-form__validation {
                height: 0;
                padding: 0;
                margin: 0;                
                overflow: hidden;
            }
        }
    }
}
// kwr-breadcrumb

//Bse theme override for KWR breadcrumb
.ms-breadcrumb {
    & .ms-breadcrumb_list {
        display: flex;
        flex-wrap: wrap;
    }
    & .ms-breadcrumb_item,
    & .ms-breadcrumb_item-current {
        & .ms-breadcrumb_link {
            color: $kwr-red-active;
            white-space: nowrap;
        }
    }
    & .ms-breadcrumb {
        &_link {
            &:hover {
                text-decoration: underline;
            }
        }
        &_item-current {
            & .ms-breadcrumb_link:hover {
                text-decoration: none;
            }
        }
    }
}

.header {
    &__crumbs {
        & .ms-breadcrumb {
            margin-left: -0.5rem;
            &_item, &_item-current {
                color: $moe-white;
                & .ms-breadcrumb_link {
                    color: $moe-white !important;
                }
            }
        }
    }
}

//Breadcrumb on Category landing views heros
.kwr-hero-wrap {
    &--hillsback,
    &--hillsfront {
        & .ms-breadcrumb {
            &_item, &_item-current {
                color: $moe-white;
                & .ms-breadcrumb_link {
                    color: $moe-white;
                }
            }
        }
    }
}
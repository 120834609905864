//Base Tabs classes override for MOE Tabs
.ms-tab {
    & h1, h2, h3 {
        font-size: 1.25rem;
    }
    &-header-section__heading {
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-breakpoint-m) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    &-tabs-section {
        background-color: transparent;
        //Tablet Landscape and Base Desktop
        @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
            max-width: 97%;
        }
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-breakpoint-m) {
            padding: 0 0.5rem !important;
            flex-wrap: wrap;
        }
        &-li {
            //background-color: #707070;
            background-color: transparent;
            //color: $white;
            //border-radius: 0.25rem;
            overflow: unset;
            padding: 0.625rem 1.5rem;
            margin: 0 0.5rem;
            text-align: center;
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-breakpoint-m) {
                margin: 0.3rem;
            }
            .ms-tab-item-header {
                font-weight: bold;
                //color: $white;
            }
            &[aria-selected="true"] {
                //background-color: $white;
                background-color: transparent;
                color: #707070;
                //border-radius: 0.25rem;
                padding: 0.625rem 1.5rem;
                outline: none;
                //border: 1px solid $msv-gray-600;
                border-bottom: none;
                z-index: 1;
                //top: -2px;
                .ms-tab-item-header {
                    color: $black;
                }
                ::before {
                    height: 0.1875rem;
                    width: 100%;
                    top: auto;
                    left: 0;
                    bottom: 0;                    
                    background-color: $msv-primary;
                   //display: none;
                }                
                //Mobile Responsive UI
                /*@media only screen and (max-width: $msv-breakpoint-m) {
                    ::before {
                        display: none;
                    }
                    border: 1px solid $msv-primary;
                    top: 0;
                }*/
            }
        }
    }

    &-content-section {
        margin-top: 0px;
        padding: 1.5rem;
        background-color: transparent;
        //border: 1px solid $msv-gray-600;
        //position: relative;
        //top: -3px;

        //Mobile Responsive UI
        /*@media only screen and (max-width: $msv-breakpoint-m) {
            top: 0;
            margin: 0.3rem 0.8rem;
        }*/
    }
    &-item-content {
        .msc-ss-carousel {
           //Mobile Responsive UI
           @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-container-max-width-xs) {
               max-width: 18.75rem;
           }
           //Tablet Responsive UI
           @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-breakpoint-m) {
               max-width: 37.5rem;
           }
           //Tablet landscape adn base Desktop Responsive UI
           @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
            max-width: 41rem;
            }
        }
    }
}


//MOE Tabs
.moe-tabs {
    &-wrap {
        &--dark {
            padding: 0;
            background-color: #83249C;
            max-width: $msv-breakpoint-xl;
            margin: 0 auto 2rem auto;
            .ms-tab {
                max-width: 50rem;
                margin-left: auto;
                margin-right: auto;
                & h1, h2, h3 {
                    font-size: 1.25rem;
                }
                &-tabs-section {
                    &-li {
                        background-color: transparent;
                        color: $white;
                        border: none;
                        border-radius: 0;
                        text-align: center;
                        margin-left: 1rem;
                        margin-right: 1rem;
                        .ms-tab-item-header {
                            color: $white;
                            font-size: 1rem;
                        }
                        &[aria-selected="true"] {
                            background-color: transparent;
                            color: $white;
                            border: none;
                            //border-bottom: 3px solid $white;
                            .ms-tab-item-header {
                                color: $white;
                                font-size: 1rem;
                            }
                            ::before {
                                height: 0.1875rem;
                                width: 100%;
                                top: auto;
                                left: 0;
                                bottom: 0;                    
                                background-color: $white;
                            }
                        }
                    }
                }
                &-content-section {
                    background-color: transparent;
                    border: none;
                }
                .ms-text-block {
                    color: $white;
                }
            }
        }
    }
    & * {
        font-size: 1rem;
    }
    max-width: $msv-breakpoint-xl;       
    margin: 0 auto 2rem auto;
    background-color: transparent;
    &__item {
        &-content {
            padding: 0;
            margin: 0;
            .ms-text-block {
                margin-top: 0;
            }
        }
    }

    //Tabs with gray underline selected state
    &--underline {
        margin-bottom: 0;
        //Mobile and Tablet 
        @media only screen and (max-width: $msv-container-max-width-ml) {
            margin-top: 3rem;
        }
        & .moe-tabs__item {
            white-space: nowrap;
            background-color: transparent;
            border: none;
            border-bottom: 3px solid transparent;
            cursor: pointer;
            padding: 0.5rem 1rem;
            margin-bottom: 0.5rem;
            margin-right: 1rem;
            font-size: 0.9rem;
            font-weight: 500;
            text-decoration: none;
            &.active {
                border-bottom: 3px solid $moe-grey;
            }
        }
    }
    & .ms-article-list__heading {
        & .ms-tile-list__title {
            display: none;
        }
    }
}

//fix: bug 99582/99565 - fix tab no focus issue
.ms-tab-tabs-section-li[aria-selected="true"]:focus-visible {
    outline: 1px solid;
}
// END
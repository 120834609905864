.moe__account-preferences-cont {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-right: 2rem;

    // .moe__account-preferences-container {
    //     display: flex;
    // }

    .moe__account-preferences {
        // min-width: 35rem;

        &__header {
            width: 80%;

            h1 {
                font-size: 2.5rem;
                font-weight: 900;
                margin-bottom: 0.2rem;
                line-height: 1;
            }

            p {
                padding-bottom: 1rem;
                color: $moe-grey;
                font-size: 1rem;
                border-bottom: 1px solid black;
                padding-top: 0.5rem;
            }
        }

        &__personal-information {
            margin-top: 1rem;
            margin-bottom: 2rem;

            h2 {
                font-size: 1.6rem;
                font-weight: 900;
                margin-bottom: 0.8rem;
                line-height: 1;
            }

            .personal-info-item {
                margin-bottom: 0.3rem;

                .title {
                    font-size: 1rem;
                    font-weight: 500;
                }

                .value {
                    font-size: 1rem;
                    margin-left: 5px;
                    color: $moe-grey;
                }
            }
        }

        &__user-preferences {
            display: flex;
            margin-bottom: 2rem;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                flex-direction: column;
            }
            // base Desktop 1024px and above resolutions Responsive UI
            @media only screen and (min-width: $msv-breakpoint-ml) {
                justify-content: space-between;
            }
            .user-content {
                margin-bottom: 1rem;
                min-width: 35rem;

                // Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-l) {
                    min-width: calc(100vw - 3rem);
                }

                &__header {
                    margin-bottom: 0.8rem;
                    display: flex;
                    align-items: center;

                    h2 {
                        font-size: 1.6rem;
                        font-weight: 900;
                        line-height: 1;
                    }

                    button {
                        margin-left: 1.4rem;
                        border: 0;
                        background: #707070;
                        width: 10rem;
                        color: white;
                        border-radius: 50px;
                        padding: 0.3rem 0.4rem 0.4rem 0.4rem;
                        cursor: pointer;
                        &:hover {
                            background-color: $moe-white;
                            color: $moe-grey;
                            border: 1px solid $moe-grey;
                        }
                    }
                }

                .preferences {
                    display: flex;
                    margin-bottom: 1.5rem;
                    flex-wrap: wrap;
                    gap: 10px;

                    // Mobile and Tablet Responsive UI
                    @media only screen and (max-width: $msv-breakpoint-l) {
                        margin-bottom: unset;
                    }

                    .preference {
                        margin-right: 0.5rem;
                        color: $moe-blue-active;
                        border: 1px solid $moe-blue-active;
                        border-radius: 0.188rem;
                        display: inline-flex;
                        align-items: center;
                        font-weight: 500;
                        padding: 0.2rem 0.4rem;

                        svg {
                            margin-left: 0.8rem;
                            cursor: pointer;
                        }
                    }
                }
            }

            .moe__account-preferences__subscribe-status {
                margin-top: -9rem;
                max-width: 36rem;
                // Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-top: -2rem;
                    margin-bottom: 2rem;
                }
                .subscribe-status__header {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;
                    margin-bottom: 0.5rem;

                    .status-header {
                        span {
                            font-weight: 700;
                        }

                        .status {
                            &-subscribed {
                                color: #009115;
                                white-space: nowrap;
                            }

                            &-unsubscribed {
                                color: #de6420;
                                white-space: nowrap;
                            }
                        }
                    }

                    .subscribe-status__image {
                        position: relative;
                        bottom: -2rem;
                        width: 12rem;

                        //Mobile Responsiveness
                        @media only screen and (max-width: $msv-breakpoint-s) {
                            bottom: -2rem;
                        }
                    }
                }

                .subscriber-status__terms {
                    display: flex;
                    background-color: #e56621;
                    color: #fff;
                    padding: 1rem 0.5rem;

                    input {
                        width: 3.5rem;
                    }
                }

                .subscriber-status__note {
                    margin-top: 1rem;
                    color: $msv-red;
                }
            }
        }

        &__more-preferences {
            .more-preferences__header-title {
                h2 {
                    font-size: 1.6rem;
                    font-weight: 900;
                    margin-bottom: 0.8rem;
                    line-height: 1;
                }
            }

            .search {
                width: 100%;
                max-width: 34rem;
                height: 2.5rem;
                margin-bottom: 2rem;
                border: 1px solid #707070;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.3rem 0.4rem 0.4rem 0.6rem;
                background: white;
                border-radius: 7px;

                input {
                    border: none;
                    outline: none;
                    border-radius: 7px;
                    padding: 0.2rem;
                    width: calc(100% - 1.25rem);
                    &::placeholder {
                        font-size: 0.8rem;
                        color: #a2a2a2;
                    }
                }

                svg {
                    width: 1.125rem;
                    height: 1.125rem;
                    color: $moe-black;
                    cursor: pointer;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }

            .preferences-list {
                margin-top: 1rem;

                .preference-type {
                    margin-bottom: 2rem;

                    h3 {
                        font-size: 1.25rem;
                        font-weight: 900;
                        margin-bottom: 1rem;
                        line-height: 1;
                    }

                    .more-preferences-tags {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                    }

                    // .preference {
                    //     cursor: pointer;
                    //     margin-right: 0.5rem;
                    //     color: $moe-blue-active;
                    //     border: 1px solid $moe-blue-active;
                    //     border-radius: 0.188rem;
                    //     display: inline-flex;
                    //     align-items: center;
                    //     font-weight: 500;
                    //     padding: 0.2rem 0.4rem;
                    //     transition: all 0.3s;

                    //     &:hover {
                    //         background-color: $moe-blue-active;
                    //         color: white;
                    //     }

                    //     svg {
                    //         margin-left: 0.8rem;
                    //         cursor: pointer;
                    //     }
                    // }

                    // .preference-selected {
                    //     background-color: $moe-blue-active !important;
                    //     color: white !important;
                    // }
                }
            }
        }
    }
}

.moe__account-preferences__privacy-policy {
    background-color: #de6420;
    display: flex;
    justify-content: space-between;
    margin: unset;
    width: 100%;

    //Mobile Responsiveness
    @media only screen and (max-width: $msv-breakpoint-s) {
        flex-direction: column;
    }

    .privacy-policy__message {
        padding: 5rem 0 5rem 5rem;

        //Mobile Responsiveness
        @media only screen and (max-width: $msv-breakpoint-s) {
            padding: 2rem;
        }

        &__title {
            font-size: 1.5rem;
            font-weight: normal;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            line-height: 2rem;
        }

        &__button {
            background-color: rgba(#ffd444, 0.9);
            color: #000000;
            margin-top: 2rem;
            min-width: 15rem;
            height: 4.5rem;
            border-radius: 16px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            font-size: 1.3rem;
            cursor: pointer;
            border: 0;
            transition: all 0.3s;
            box-shadow: 0px 8px 15px rgba(#000000, 0.1);
            padding: 1.5rem;
            text-decoration: none;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: rgba(#ffd444, 1);
                transform: translateY(-5px);
            }

            //Mobile Responsiveness
            @media only screen and (max-width: $msv-breakpoint-s) {
                width: 12rem;
                height: 3.5rem;
            }
        }
    }

    .privacy-policy__image {
        display: flex;
        align-items: center;

        .privacy-policy-image {
            width: 78rem;
        }
    }
}

.preference {
    cursor: pointer;
    margin-right: 0.5rem;
    color: $moe-blue-active;
    border: 1px solid $moe-blue-active;
    border-radius: 0.188rem;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    padding: 0.2rem 0.4rem;
    transition: all 0.3s;

    &:hover {
        background-color: $moe-blue-active;
        color: $moe-white !important;
    }

    svg {
        margin-left: 0.8rem;
        cursor: pointer;
    }
}

.preference-selected {
    background-color: $moe-blue-active !important;
    color: $moe-white !important;
}

//moe-cart

//Base theme override for MOE sites
.ms-cart {
    margin-bottom: 3rem;
    &-icon{
        &__minicartmodal-container {
            min-width: 42rem;
            //Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                min-width: 100%;
            }
            //Mobile Responsive UI
            @media only screen and (max-width: $msv-container-max-width-s) {
                & .msc-cart-line {
                    flex-wrap: wrap;
                    &__content {
                        //Mobile landscape and tablet portrait
                        @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-container-max-width-s) {
                            width: 52% !important;
                        }
                    }
                    &__product-image {
                        margin-bottom: 1rem;
                        & .msc_image {
                            width: auto;
                            height: 100%;
                        }
                    }
                }
            }
            & .ms-cart-icon__btn-close {
                margin-right: 1.5rem;
                padding: 0.3rem !important;
            }
        }
        &__cart-lines {
            & .msc-cart-lines-item {
                min-height: 9.375rem;
                padding: 1rem !important;
                background-color: $moe-gray-100;
            }
        }
        &__footer {
            display: flex !important;
            padding: 1.5rem;
            & .msc-btn,
            & .ms-cart-icon__btn-section {
                width: 12.5rem;
            }
        }
    }
    & .msc-btn {
        padding: 0.625rem 1.5rem;
        font-size: 1rem;
        border-radius: 0.4375rem; //7px
    }
    & .msc-cart__btn-backtoshopping {
        margin-bottom: 1rem;
        line-height: normal;
    }
}


//Cart Line
.msc-cart-line {
    font-size: 0.875rem;
    &-wraper {
        border-radius: 0;
        border: none;
    }
    &__content {
        margin-left: 0 !important;
    }
    &__product-image {
        width: 12rem !important;
        height: 7rem !important;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; 
        margin-right: 1.5rem !important;
        background-color: $moe-gray-300;  
        //Mobile
        @media only screen and (max-width: $msv-breakpoint-l) {
            height: 8rem !important;
        }      
        & .msc_image {
            //min-width: 9.375rem;
            width: 100% !important;
            height: auto !important;            
        }
    }
    &__product-title {
        margin-bottom: 0.5rem;
        font-size: 1.25rem !important;
    }
    &__product-text {
        width: 17.5rem;
        // & a + p {
        //     display: none;
        // }
    }
    &__product-downloadcatridge {
        display: flex;
        position: absolute;
        top: 1.5rem;
        left: 20rem;
        & .msc-cart-line__product-resource {
            width: 8.125rem;
        }
    }
    &__remove-item {
        width: 2rem;
        height: 2rem;
        display: inline-flex;
        align-items: center;
        position: absolute;
        right: 0;
    }
    &__trash-icon {
        margin: 0;
    }
    &__trash-icon,
    &__trash-icon svg {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
    }
    &__product-resources {      
        display: flex;          
        max-width: 22rem;
        justify-content: space-between;
        padding: 0.8rem;
        margin: 0.8rem 0;
        background-color: $white;
        & .msc-cart-line__product-resource {
            display: flex;
            width: 12.5rem;
            //Mobile
            @media only screen and (max-width: $msv-container-max-width-xs) {
                width: 100%;
            }
            flex-wrap: wrap;
            &__img {
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
                display: inline-flex;
                align-items: center;
                & svg {
                    max-height: 100%;
                }
            }
        }
        & .msc-cart-line__product-edit {                    
            & .moe-btn {
                min-width: 4.5rem !important;
                height: 2rem;
                padding: 0.2rem 0.8rem 0.2rem 2rem;
                position: relative;
                &::before {
                    @include msv-icon();      
                    content: $msv-Edit;
                    font-size: 1.2rem;                    
                    width: 1.2rem;
                    height: 1.2rem;
                    position: absolute;
                    left: 0.5rem;
                }
            }
        }
    }
}

//Empty Cart alert
.msc-cart__empty-cart {
    padding: 1.5rem;
    margin: 0 auto 4rem auto !important;
    text-align: center;
    & .msc-cart-line {
        font-size: 1.25rem;
        margin-bottom: 1.5rem;
        font-weight: bold;
    }
}

$msv-breakpoint-xs: 320px;
$msv-breakpoint-s: 568px;
$msv-breakpoint-m: 768px;
$msv-breakpoint-ml: 1024px;
$msv-breakpoint-l: 992px;
$msv-breakpoint-mxl: 1280px;
$msv-breakpoint-xl: 1440px;

$msv-gutter-width-xs: 10px;
$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;
$msv-gutter-width-xl: 100px;

$msv-container-max-width-xs: 567px;
$msv-container-max-width-s: 767px;
$msv-container-max-width-m: 991px;
$msv-container-max-width-ml: 1023px;
$msv-container-max-width-l: 1439px;

$msv-container-min-width-s: 321px;
$msv-container-min-width-sm: 576px;
$msv-container-min-width-m: 769px;
$msv-container-min-width-l: 993px;
$msv-container-min-width-lxl: 1025px;
$msv-container-min-width-xl: 1500px;

$msv-width-full: 100%;
$msv-width-half: 50%;
$msv-width-threequarter: 75%;
$msv-width-quarter: 25%;
$msv-height-full: 100%;
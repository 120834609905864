// bootstrap color variables
$msv-white: #ffffff;
$msv-black: #000000;
$msv-blue: #0062af;
$msv-green: #107c10;
$msv-red: #a80000;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;
$msv-yellow: #ffff00;
$msv-grey: #323130;

$msv-gray-10: #100e11;
$msv-gray-20: #2c262d;
$msv-gray-30: #463d48;
$msv-gray-40: #595959;
$msv-gray-50: #c4c4c4;
$msv-gray-60: #161316;
$msv-gray-70: #201c21;
$msv-gray-75: #bfbfbf;
$msv-gray-80: #362f37;
$msv-gray-100: #f8f7f6;
$msv-gray-200: #e8e8e8;
$msv-gray-300: #d1d1d1;
$msv-gray-400: #6b727a;
$msv-gray-500: #616365;
$msv-gray-600: #c4c4c4;
$msv-gray-800: #8f8f8f;
$msv-gray-900: #1d1d1d;
$msv-gray-1000: #2d2d2d;
$msv-gray-tint: #495057;

$msv-primary: #1470e1;
$msv-primary-hover: #0e5cba;
$msv-secondary: #6c757d;
$msv-secondary-hover: #e1efdc;
$msv-gray-30: #2c262d;
$msv-blue-tint: #0094ff;
$msv-blue-10: #ccdeff;
$msv-blue-20: #e7efff;
$msv-blue-30: #0742ab;
$msv-blue-40: #0b53ce;
$msv-blue-50: #1f6cf9;
$msv-blue-60: #9cbeff;
$msv-blue-70: #1264a0;
$msv-blue-80: #4083ff;
$msv-blue-90: #538fff;
$msv-blue-100: #6ea1ff;

$msv-transparent: transparent;

$msv-text-color: $msv-gray-900;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-selected-button-background: rgba(0, 0, 0, 0.02);
$msv-selected-hovered-button-background: rgba(0, 0, 0, 0.04);
$msv-selected-button-hover-background: #0e5cba;

$msv-flyout-empty-img-border: #f3f2f1;

// Social button color variables
$facebook-bg-color: #3c3c41;
$facebook-hover-color: #1d1d1d;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #1d1d1d;
$social-bg-color: #3c3c41;
$social-hover-color: #1d1d1d;

$msv-unfilled-rating-color: $msv-gray-800;

//tiles
$msv-tile-top-hover-color: #1470e1;
$msv-tile-top-background-color: #101010;

//footer
$msv-footer-primary-bg-color: '#121212';
$msv-footer-secondary-bg-color: '#1e2228';

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-gray-100};
    --msv-btn-input-border-color: #{$msv-gray-500};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: #{$msv-white};
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};

    // Buttons
    --msv-selected-button-background: #{$msv-selected-button-background};
    --msv-selected-hovered-button-background: #{$msv-selected-hovered-button-background};
    --msv-selected-button-hover-background: #{$msv-selected-button-hover-background};

    //breadcrumb
    --msv-breadcrumb-color: #{$msv-blue};

    //tiles
    --msv-tile-top-hover-color: #{$msv-tile-top-hover-color};
    --msv-tile-top-background-color: #{$msv-tile-top-background-color};
    --msv-tile-shadow-color: #{$msv-box-shadow-color};

    //header
    --msv-header-bg-color: #{$msv-white};

    //footer
    --msv-footer-primary-bg-color: #{$msv-footer-primary-bg-color};
    --msv-footer-secondary-bg-color: #{$msv-footer-secondary-bg-color};
}

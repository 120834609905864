//kwr-button
$moe-btn-language-toggle-bg: #27253e;
$moe-btn-language-toggle-text-color: #fff;
$moe-btn-language-toggle-inactive-bg: #895a87;
$msv-btn-color: $color-red;
$moe-btn-blue: #1470e1;
$moe-btn-hover-blue: #0e5cba;

//Base theme overrides for MOE sites buttons

//Blue buttons
.msc-add-to-cart,
.ms-checkout__btn-place-order,
.ms-cart-icon__btn-guestcheckout,
.ms-cart-icon__btn-checkout,
.ms-write-review__toggle,
.ms-review-modal-submit,
.ms-order-history__btn-more {
    background-color: $msv-btn-color !important;
    border: 1px solid $msv-btn-color !important;
    &:hover {
        background-color: $moe-white !important;
        border: 2px solid $msv-btn-color !important;
        color: $msv-btn-color !important;
    }
    &:hover text {
        color: $msv-btn-color;
    }
}

//White buttons
.ms-review-modal-cancel,
.moe-btn-continue-browsing,
.msc-cart__btn-backtoshopping {
    border: 2px solid $msv-btn-color !important;
}

//KWR specific Language toggle button
.moe-btn-language-toggle {
    background: $moe-btn-language-toggle-bg;
    border: none;
    z-index: 9;
    &__list {
        &-item {
            color: $moe-btn-language-toggle-text-color;
        }
        &-item {
            &:first-child.inactive {
                &::before {
                    background: $moe-btn-language-toggle-inactive-bg;
                }                
            }
            &:last-child.inactive {
                &::after {
                    background: $moe-btn-language-toggle-inactive-bg;
                }                
            }            
        }
    }
}

// Blue MOE button styles
.moe-btn {
    border: 2px solid $msv-btn-color;

    // Default White Button
    &--default-white {        
        border: 2px solid $msv-btn-color;
        border-radius: 0.25rem;
        color: $msv-btn-color;
        & text {
            color: $msv-btn-color;
            font-weight: 800;
        }        
        &:hover {
            border: 2px solid $msv-btn-color !important;
        }
        &:hover text {
            color: $msv-btn-color;
        }
    }

    // Primary Blue button override to red for KWR
    &--primary-blue,
    &--primary-red {        
        background-color: $msv-btn-color;
        border-radius: 0.25rem;
        & text {
            color: $moe-white;
            font-weight: 800;
        }
        &:hover {
            background-color: $moe-white !important;
            border: 2px solid $msv-btn-color !important;
            color: $msv-btn-color !important;
        }
        &:hover text {
            color: $msv-btn-color;
        }
    }
}

//for Blue support button in the header
.moe-header-actions {
    .moe-btn {
        &--primary-blue {        
            background-color: $moe-btn-blue;
            border-radius: 1.563rem;
            border: none !important;
            padding: 0.5rem 1.5rem 0.4rem 1.5rem;
            & text {
                color: $moe-white;
                font-weight: 500;
            }
            &:hover {
                background-color: $moe-btn-hover-blue !important;
                border: none !important;
                color: $moe-white !important;
            }
            &:hover text {
                color: $moe-white;
            }
        }
    }        
}

//English text button as Maori text button (example: View more button as Whakarahi ake button) 
.moe-download-history-showmore {
    & .ms-order-history__btn-more {
        border-radius: 0.25rem;
        &:hover, &:focus {
            & + .moe-btn-maoritxt {
                color: $msv-btn-color !important;
            }
        }
    }
}
//moe modal

//Base theme overrides for MOE sites
.modal-open {
    right: 0 !important;
}

.msc-modal {
    & .moe-modal__filecontdialog-hide {
        display: none !important;
    }
    & .moe-modal__filecontdialog-show {
        top: 0;
        transform: unset;
        margin: 0 auto !important;
        //Devices 1024px and above
        @media only screen and (min-width: $msv-breakpoint-ml) {
            max-width: 90%;
        }
        //Devices 1440px and above
        @media only screen and (min-width: $msv-breakpoint-xl) {
            max-width: 80rem;
        }
        & .msc-modal__body {
            max-height: max-content;
        }
    }
    &__dialog {
        &.ms-quickView__dialog {
            float: none !important;
            margin: 0 auto !important;
            max-width: 60rem !important;
            height: 70% !important;
            //Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                max-width: 80% !important;
            }

            @media only screen and (max-width: $msv-container-max-width-xs) {
                max-width: 100% !important;
                height: 100% !important;
            }

            .ms-quickView {
                &__header {
                    border-bottom: none !important;
                }
                &__body {
                    display: flex !important;
                    .quantity {
                        .quantity__controls {
                            outline: none;
                            box-sizing: border-box;
                            border-radius: 2px;
                            cursor: pointer;
                            height: 40px;
                            border: 0.52px solid #c4c4c4;
                            background: white;
                        }
                        .quantity-input {
                            width: 55%;
                            height: 40px;
                            background-color: #ffffff;
                            border: 1px solid #6b727a;
                            border-radius: 0;
                            font-weight: normal;
                            font-style: normal;
                            font-size: 14px;
                            line-height: 20px;
                            text-transform: none;
                            padding: 4px 8px;
                            background-clip: border-box;
                            position: relative;
                            outline-offset: 1px;
                            appearance: textfield;
                            -moz-appearance: textfield;
                            box-sizing: border-box;
                            border-radius: 2px;
                            text-align: center;
                        }
                        .decrement:after {
                            @include msv-icon();
                            content: $msv-Remove;
                            font-size: 20px;
                            width: 3rem;
                            vertical-align: middle;
                            text-align: center;
                        }
                        .increment:after {
                            @include msv-icon();
                            content: $msv-Add;
                            font-size: 20px;
                            width: 3rem;
                            vertical-align: middle;
                            text-align: center;
                        }
                    }
                }
                &__content {
                    padding: 0 !important;
                    margin: 0 auto;
                    & .moe-link--selectall {
                        margin-bottom: 0.5rem;
                        position: relative;
                        right: -1rem;
                    }
                    & svg {
                        min-width: 2rem;
                        width: 2rem;
                        height: 2rem;
                        margin-right: 0.8rem;
                    }
                    & p {
                        display: flex;
                        align-items: center;
                        font-size: 1rem;
                        margin-bottom: 1rem;
                        position: relative;
                        padding-right: 3rem;
                    }
                    & input[type='checkbox'] {
                        position: absolute;
                        right: 0;
                    }
                }
                &__media-gallery {
                    overflow: hidden;
                    max-height: 18rem;
                    display: none;
                }
                &__seeDetailsbutton {
                    font-size: 14px;
                    background-color: transparent;
                    position: relative;
                    text-decoration: none;
                    min-height: 20px;
                    font-weight: 500;
                    font-style: normal;
                }
                &__seeDetailsbutton::before {
                    background-color: #2266e3;
                    bottom: -8px;
                    content: '';
                    height: 4px;
                    left: 0;
                    position: absolute;
                    transform: scaleX(1);
                    transition: all 0.2s ease-in-out;
                    visibility: visible;
                    width: 100%;
                }
                &__product-title {
                    font-size: $msv-title-font-size-xs;
                }
                &__footer {
                    padding: 1.5rem !important;
                }
                &__add-to-cart-container {
                    margin-bottom: 0 !important;
                    .msc-add-to-cart {
                        width: fit-content !important;
                    }
                }
            }
            & .ms-media-gallery__carousel,
            .msc-carousel {
                height: 100%;
            }

            //Toastify alerts
            & .Toastify {
                background-color: $moe-yellow;
                margin-top: 1rem;
                &__toast-icon {
                    width: 2rem;
                    height: 2rem;
                    float: left;
                }
                &__close-button {
                    width: 2rem;
                    height: 2rem;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
    &__content {
        padding: 0 !important;
    }
    &__header {
        font-size: 1.25rem;
        font-weight: bold;
        border-bottom: none !important;
        padding: 1.5rem 4rem 1.5rem 1.5rem !important;
        margin-bottom: 0.5rem;
        align-items: center;
        & .msc-modal__close-button:before {
            font-size: 1.25rem;
        }
    }
    &__body {
        padding: 0.5rem 1.5rem 1.5rem 1.5rem !important;
        //Search Filter control in Modal dialog
        & .ms-search-result-container {
            &__refiner-section {
                .Price {
                    display: none !important;
                }
                &-ttl,
                & h1,
                h2,
                h3 {
                    font-size: 1.25rem;
                    font-weight: bold !important;
                    //text-transform: uppercase;
                    text-transform: capitalize;
                    color: $moe-grey;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
                & .ms-refine-submenu__toggle_expanded,
                & button.ms-refine-submenu__toggle_collapsed {
                    color: $moe-grey;
                    font-size: 16px;
                    font-weight: bold;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
                & .ms-refine-submenu {
                    &.list-group:first-child,
                    &.list-group {
                        border: 0;
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
    &__footer {
        box-shadow: none !important;
        padding: 1.5rem !important;
        align-items: center;
        justify-content: center;
        & .msc-btn {
            margin-right: 0.4rem;
            margin-left: 0.4rem;
            //Small Mobile Responsive UI
            @media only screen and (max-width: $msv-breakpoint-s) {
                margin-right: 0 !important;
                margin-bottom: 1rem !important;
                min-width: 55% !important;
            }
        }
    }
}

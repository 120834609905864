//Base Search Results styles override for MOE sites starts
.ms-search-result-container {
    &__refine-product-section {
        //Responsive UI
        @media screen and (max-width: $msv-container-max-width-m) {
            margin-bottom: 0;
        }
    }
    &__category {
        &-nav-section {
            height: auto;
            margin-top: 0;
            margin-bottom: 0;
        }
        &-hierarchy {
            a {
                margin: 1.5rem 1rem;
                font-size: 1.25rem !important;
            }
        }
    }
    &__product-section {
        & .ms-search-result-wrapper {
            &-title-choice-summary,
            &-sort-by-category {
                //Responsive UI for Tablet
                @media screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-container-max-width-ml) {
                    flex: none;
                    max-width: none;
                    display: block;
                    & .ms-search-result-container__Sort-by-category {
                        justify-content: right;
                    }
                }
                //Responsive UI for 1024px and above devices
                @media screen and (min-width: $msv-breakpoint-ml) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
            &-title-choice-summary {
                //Responsive UI for 1024px and above devices
                @media screen and (min-width: $msv-breakpoint-ml) {
                    margin-bottom: 2rem;
                }
            }
            &-sort-by-category {
                // max-width: 9.375rem !important;
                & label {
                    font-weight: 600;
                    color: $moe-gray-mdm;
                }
                & select {
                    background-color: $moe-white !important;
                    height: 2rem !important;
                    border: 1px solid $moe-grey !important;
                    //width: 100%;
                    font-weight: normal !important;
                }
            }
        }
    }

    & .ms-search-result,
    & span.ms-search-result {
        &__collection-title {
            &-prefix,
            &-text,
            &-count {
                font-size: 1.25rem !important;
                color: $moe-gray-mdm !important;
            }
        }
        &-container__title h2 {
            font-size: 1.25rem !important;
            color: $moe-gray-mdm !important;
            margin-top: -5px;
        }
    }
    & .ms-refine-submenu__toggle_expanded,
    & .ms-refine-submenu__toggle_collapsed {
        color: $moe-grey !important;
        font-size: 1rem !important;
        font-weight: bold;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: left;
    }
    & .ms-refine-submenu__toggle_collapsed:after {
        color: black;
        font-size: 20px;
    }
    & .ms-refine-submenu-item.multi-select::before {
        font-size: 24px;
    }
    & .msc-btn:focus {
        outline: none;
        border: 0;
    }
    & ul.ms-refine-submenu__list {
        border-top: 1px solid #d1d1d1;
    }

    //Toggle between Tiles and List views under search results
    .moe-listview__button-container {
        display: flex;
        justify-content: end;
    }

    &__Products ul {
        display: flex !important;
        flex-wrap: wrap;
        & li {
            width: 100%;
        }

        .moe-och-srchresults {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 1rem;

            &__item {
                width: 80%;
                margin-top: 0.8rem;
            }

            &__link {
                width: 100%;
                font-size: 1.4rem;
                display: flex;
                justify-content: space-between;
                font-size: 1.4rem;
                text-decoration: none;
                padding: 1.2rem 0.7rem;
                background: white;
                color: #68ba57;
                align-items: center;
                border-top: 1px solid #dbdcdb;
                border-bottom: 1px solid #dbdcdb;
                line-height: 28px;
            }
        }
    }

    & .moe-local-search-container {
        // display: none;
        margin-bottom: 1rem;

        .moe-local-search-form {
            outline: 5px solid $moe-grey;
            border-radius: 9px;
            overflow: hidden;
            border: 1px solid $moe-grey;
            background-color: $moe-white;
            max-width: 42rem;
            overflow: hidden;
            margin: 0 auto;
            display: flex;
            height: 48px;
            justify-content: flex-end;
            min-width: 24rem;
            //Resposnive UI for 1280px and above
            @media screen and (min-width: $msv-breakpoint-mxl) {
                min-width: 40rem;
            }
            //Resposnive UI between 768px and 1279px and 
            @media screen and (min-width: $msv-breakpoint-m) and (max-width: 1279px) {
                min-width: 32rem;
            }
            //Mobile Responsive
            @media screen and (max-width: $msv-container-max-width-xs) {
                min-width: 18rem;
            }
        }

        .moe-local-search__input {
            color: var(--msv-search-font-color);
            order: 0;
            padding: 0 8px;
            width: 100%;
            height: 48px;
            background-color: $moe-white;
            max-width: 100%;
            outline: none;
            border: none;
            
            &:focus {
                border: 0.15rem dotted #737373 !important;
            
            }
        }

        .moe-local-search__cancel--btn {
            cursor: pointer;
            border: 0;
            background-color: transparent;
            align-items: center;
            height: 48px;
            order: 1;
            width: 48px;
            display: flex;

            &:focus {
                border: 2px dotted #737373;
               
            }

            &::before {
                color: $moe-black !important;
                font-family: 'Font Awesome 5 Free';
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-weight: 900;
                text-rendering: auto;
                line-height: 1;
                content: $msv-Cancel;
                width: 48px;
                text-align: center;
            }

        }

        .moe-local-search__submit--btn {
            background-color: $moe-white;
            cursor: pointer;
            border: 0;
            align-items: center;
            border-radius: 0;
            color: var(--msv-font-secondary-color);
            height: 48px;
            order: 2;
            width: 48px;
            display: flex;
            padding: 0;
            font-size: 16px;

            &:focus {
                border: 2px dotted #737373;
               
            }

            &::before {
                font-family: 'Font Awesome 5 Free';
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                font-weight: 900;
                text-rendering: auto;
                line-height: 1;
                content: $msv-Search;
                width: 48px;
                text-align: center;
                color: $moe-black !important;
            }
        }
    }
}

.ms-search-result-container {
    &__refiner-section,
    &__product-section {
        padding: 1rem;
    }
    &__refiner-section {
        width: 22rem;
        background-color: $white;
        .Price {
            display: none !important;
        }
        &-ttl,
        & h1,
        h2,
        h3 {
            font-size: 1.25rem;
            font-weight: bold !important;
            //text-transform: uppercase;
            text-transform: capitalize;
            color: $moe-grey;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }
        & .ms-refine-submenu {
            &.list-group:first-child,
            &.list-group {
                border: 0;
                margin-bottom: 1rem;
            }
        }

        & + .ms-search-result-container__product-section {
            & .ms-product-search-result__item {
                //2 column layout Responsive UI for base Desktop
                @media screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
                    min-width: 18.75rem;
                    flex: 1 1 50%;
                    max-width: 50%;
                }
            }
        }

        &-expand__collapse {
            margin-top: 1rem;
            margin-bottom: 1rem;

            button {
                border: none;
                background: none;
                cursor: pointer;
                padding: 0;
                color: $moe-grey;
                transition: all 0.3s;
                border-bottom: 1px solid transparent;

                &:hover {
                    border-bottom: 1px solid $moe-grey;
                }
            }

            .ms-search-result-container__refiner-section-expand-btn {
                margin-right: 0.3rem;
            }

            .expand-all-seperator {
                font-weight: bold;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }

            .ms-search-result-container__refiner-section-collapse-btn {
                margin-left: 0.3rem;
            }
        }
    }
}

//SIT Bug 83721 - UX Issue Fix
.ms-product-search-result__item {
    & .msc-dv-product-attribute {
        display: block;        
    }
    & .msc-spn-product-attribute {
        width: fit-content;
    }
}

//Responsive UI
#search-result-modal {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    align-items: center;

    &::before {
        @include msv-icon();
        content: $msv-Filter;
        font-size: 1.25rem;
        width: 1.25rem;
        margin-right: 0.4rem;
    }
}

//Mobile Responsive UI
@media screen and (max-width: $msv-breakpoint-l) {
    .msc-search-result-modal {
        max-width: 28rem;
        height: 100%;
        & .msc-modal__body {
            height: calc(100vh - 14rem);
            max-height: 100%;
        }
    }
}

//Base Search Results styles override for MOE sites ends

.products-list-view__container {
    .product-list-view__item-wrap {
        @media screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
            height: 100%;
        }

        .product-list-view-item {
            display: flex;
            flex-direction: column;
            width: 80%;
            //Medium desktop 1280px and below
            @media screen and (max-width: $msv-breakpoint-mxl) {
                width: 100%;
            }
            padding: 0.5rem 1rem;
            border-bottom: 2px solid #eaeaea;
            transition: all 0.3s ease-in;
        }

        .product-list-view-item:hover .product-link-title {
            color: #1473e6;
        }

        .product-list-view-item .product-link-title {
            cursor: pointer;
            color: #1e2229;
            position: relative;
            text-decoration-style: solid;
            transition: all 350ms ease-in;
        }

        .product-list-view-item .product-link-title .product-list__title {
            display: flex;
            font-size: 1.25rem;
            font-weight: 700;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;
            .moe-product-text-arrow {
                // margin: -3px 0.625rem 0 0.625rem;
                & svg {
                    width: 1.875rem;
                    height: 1.875rem;
                }
            }
        }

        .product-list-view-item .product-list__content-wrap {
            display: flex;
            align-items: flex-start;
            // margin-bottom: 1rem;
        }

        .product-list-view-item .product-list__content-wrap .image {
            min-width: 10rem;
            height: auto;
            background-color: #eaeaea;
            margin-right: 1rem;
            overflow: hidden;
        }

        .product-list-view-item .product-list__content-wrap .image.is-16by9 {
            padding-top: 56.25%;
            position: relative;
        }

        .product-list-view-item .product-list__content-wrap .image.is-16by9 img {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        .product-list-view-item .product-list__content-wrap .product-description {
            font-size: 0.83rem;
        }

        .product-list-view-item .product-list__content-wrap .product-description p {
            margin-top: 0;
        }

        .product-list-view-item .product-list-footer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1rem;

            //Small Mobile Potrait only
            @media screen and (max-width: $msv-container-max-width-xs) {
                align-items: baseline;
            }

            &--left {
                display: flex;

                & .product-list-footer--resources {
                    display: flex;
                    align-items: center;
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    & .moe-btn-iconlink {
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                    }
                }
            }

            &--right {
                transform: translateY(-1px);

                //Small Mobile Potrait only
                @media screen and (max-width: $msv-container-max-width-xs) {
                    margin-top: 1rem;
                }
            }
        }

        .product-list-view-item .product-list-footer .product-list__tags {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .product-list-view-item .product-list-footer .product-list__tags .c-tag {
            display: flex;
            align-items: center;
            height: 1.67rem;
            font-size: 0.65rem;
            line-height: 1;
            padding: 0 0.5rem;
            border: 1px solid #e4e6e2;
            background-color: #f5f5f5;
            border-radius: 0.25rem;
        }

        .product-list-view-item .product-list-footer .product-list__tags .product-list__tag {
            display: block;
            max-width: 12.5rem;
            margin: 0.2rem;
            padding-top: 0.4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .product-list-view-item .product-list__content-wrap {
        flex-direction: column;
    }

    .products-list-view__container .product-list-view__item-wrap .product-list-view-item .product-list__content-wrap .image {
        width: 100%;
    }

    .product-list-view-item .product-list__content-wrap .product-description {
        margin-top: 1rem;
    }

    .product-list-view-item .product-list-footer {
        flex-direction: column;
        margin-top: 1rem;
    }

    .product-list-view-item .product-list__ratings {
        margin-bottom: 0.5rem;
    }
}

// External resources - Digital NZ styling
.moe-search-ext-resources__container {
    .moe-search-ext-resources {
        display: flex;
        flex-direction: column;

        .moe-search-ext-resource {
            display: flex;
            align-items: center;
            padding: 1rem;
            border-bottom: 3px solid #151324;

            //Small Mobile Potrait only
            @media screen and (max-width: $msv-container-max-width-xs) {
                flex-direction: column;
                align-items: baseline;
            }

            .moe-search-ext-resource__header {
                flex: 1;

                a {
                    cursor: pointer;

                    .moe-search-ext-resource--title {
                        font-size: 1rem;
                        font-weight: 400;
                        letter-spacing: unset;
                        margin-bottom: 0;
                        margin-top: 0;
                        text-decoration: underline;

                        &:hover {
                            color: $msv-selected-button-hover-background;
                        }
                    }
                }

                .moe-search-ext-resource--source {
                    margin-top: 0.6rem;

                    ul {
                        font-size: 0.75rem;
                        margin: 0.4rem 0 0;
                        padding-left: 0;

                        li {
                            display: inline;
                            font-weight: 600;

                            &::before {
                                content: '•';
                                padding-left: 0.4rem;
                                padding-right: 0.4rem;
                            }

                            &:first-child::before {
                                content: '';
                                padding-left: 0.4rem;
                                padding-right: 0.4rem;
                            }
                        }
                    }
                }

                .moe-search-ext-resource--description {
                    margin-top: 0.4rem;
                    font-size: 0.8rem;
                }
            }

            .moe-search-ext-resource__footer {
                display: flex;
                align-items: center;
                padding-left: 3rem;

                @media screen and (max-width: $msv-container-max-width-xs) {
                    padding-left: 0;
                    flex-direction: column;
                    margin-top: 1rem;
                }

                .moe-search-ext-resource--image {
                    margin-top: 0;
                    height: 6rem;
                    overflow: hidden;
                    width: 6rem;

                    img {
                        background-color: #e5e5e5;
                        display: block;
                        min-height: 100%;
                        -o-object-fit: cover;
                        object-fit: cover;
                    }

                    .moe-search-ext-resource__no-image {
                        background-color: #e5e5e5;
                        font-size: 0.65rem;
                        height: 6rem;
                        line-height: 9.2;
                        text-align: center;
                        text-transform: uppercase;
                        width: 6rem;
                    }
                }

                .moe-search-ext-resource-link {
                    margin-top: 0;
                    text-align: right;
                    width: 9rem;
                    font-size: 0.75rem;

                    @media screen and (max-width: $msv-container-max-width-xs) {
                        margin-top: 1rem;
                        width: auto;
                    }

                    a {
                        line-height: 1.6;
                        text-decoration: none;
                        -webkit-transition: opacity 0.1s ease;
                        transition: opacity 0.1s ease;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .moe-search-ext-resources-showmore--btn {
            text-align: center;
            margin-top: 3rem;

            .moe-search-ext-resources-showmore--spinner::before {
                content: '';
                box-sizing: border-box;
                top: 50%;
                left: 50%;
                width: 15px;
                height: 15px;
                margin-top: -15px;
                margin-left: -15px;
                border-radius: 50%;
                border: 1px solid #ccc;
                border-top-color: #07d;
                animation: spinner 0.6s linear infinite;
            }
        }
    }
}

// Resource Artifacts styling
.moe-search-artifacts-resources {
    .moe-search-artifacts-resource {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 3px solid black;

        @media screen and (max-width: $msv-container-max-width-xs) {
            flex-direction: column;
        }

        & .moe-search-ext-resource__header {
            display: flex;

            & > a > svg {
                font-size: 2.5rem;
            }

            & p {
                margin-left: 0.5rem;
            }
        }

        & .moe-search-ext-resource__footer {
            min-width: 23rem;
            max-width: 23rem;

            @media screen and (max-width: $msv-container-max-width-xs) {
                min-width: auto;
                max-width: auto;
                margin-top: 1rem;
                margin-left: 0.4rem;
            }
        }
    }

    .moe-search-artifacts-resources-showmore--btn {
        text-align: center;
        margin: 2rem 0;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

// Show more buttons styling
.resources-showmore-container,
.och-showmore-container,
.artifacts-showmore-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

// OCH - Removal of resources button
.och-search-wrap {
    .moe-tabs {
        .moe-tabs__item-resources {
            display: none;
        }
    }
}

$msv-btn-icon-to-text-gap: 4px;
$msv-btn-border-radius: 1.563rem;

.msc-btn {
    @include button-default();
    display: inline-block;
    min-width: 1.5rem;
    min-height: 2rem;
    height: auto;
    font-family: $msv-primary-font-family;
    font-size: 1rem;
    line-height: 120%;
    border: 1px solid transparent;   
    text-decoration: none;
    text-align: center;
    padding: 0.8rem 1.5rem !important;
    margin-bottom: 0 !important;

    &:not(:disabled) {
        cursor: pointer;
    }

    // Disabled state of Button
    &:disabled, &.disabled, &[disabled] {
        opacity: 0.5;
        cursor: not-allowed !important;
        pointer-events: all;
    }
}
.ms-cart-icon-wrapper.msc-btn,
.ms-header__nav-icon.msc-btn,
.ms-nav .msc-btn {
    border-radius: 0 !important; 
}
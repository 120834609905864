/*
* Reusable sass mixins and functions
*/

// Breakpoints

@mixin breakpoint-custom-up($min) {
    @media all and (min-width: $min) {
      @content;
    }
  }
  
  @mixin breakpoint-xs-up {
    @include breakpoint-custom-up($screen-xs-min) {
      @content;
    }
  }
  
  @mixin breakpoint-sm-up {
    @include breakpoint-custom-up($screen-sm-min) {
      @content;
    }
  }
  
  @mixin breakpoint-md-up {
    @include breakpoint-custom-up($screen-md-min) {
      @content;
    }
  }
  
  @mixin breakpoint-ml-up {
    @include breakpoint-custom-up($screen-ml-min) {
      @content;
    }
  }
  
  @mixin breakpoint-lg-up {
    @include breakpoint-custom-up($screen-lg-min) {
      @content;
    }
  }
  
  @mixin breakpoint-xl-up {
    @include breakpoint-custom-up($screen-xl-min) {
      @content;
    }
  }
  
  @mixin breakpoint-xxl-up {
    @include breakpoint-custom-up($screen-xxl-min) {
      @content;
    }
  }
  
  @mixin breakpoint-full-nav {
    @media screen and (min-width: $screen-full-nav) {
      @content;
    }
  }
  
  @mixin breakpoint-compact-nav {
    @media screen and (max-width: #{$screen-full-nav - 1}) {
      @content;
    }
  }
  
  @mixin phone-all-orientations {
    @media (max-width: $screen-sm-min), (max-width: $screen-md-min) and (orientation: landscape) {
      @content;
    }
  }
  
  @mixin media-touch-device {
    @media (hover: none) and (pointer: coarse), (hover: none) and (pointer: fine) {
      @content;
    }
  }
  
  @mixin placeHolderShimmer {
    @keyframes placeHolderShimmer {
      0% {
        background-position: -23.4rem 0;
      }
  
      100% {
        background-position: 23.4rem 0;
      }
    }
  }
  
  // Colour classes
  .bg-purple {
    background: $color-grey-mid;
  }
  
  .bg-dark-purple {
    background: $color-grey-dark;
  }
  
  @mixin placeHolderShimmerGlare {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: rgba(255, 255, 255, 0.1);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.05) 8%, rgba(255, 255, 255, 0.1) 18%, rgba(255, 255, 255, 0.05) 33%);
    background-size: cover;
    position: relative;
  }
  
  @mixin title {
    color: $color-white;
    margin-left: 0.2rem;
    text-transform: uppercase;
    font-size: 0.6rem;
    opacity: 0.75;
    letter-spacing: 0.005rem;
    font-weight: bold;
    //margin-top: gap-size(1);
  }
  
  //
  // Pseudo class group mixins
  //
  
  // Shortcut for hover and focus states
  @mixin hover-focus {
    &:hover,
    &:focus {
      @content;
    }
  }
  
  // Shortcut for hover, focus and active states
  @mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
  
  // Clearfix
  @mixin clearfix {
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }
  
  // Cut off text with ellipsis when it gets too lon...
  @mixin truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  // Provide values in px
  @mixin svg-background-size($origWidth, $origHeight, $backgroundWidth) {
    // For IE 11
    // Original background svg dimensions: 1039.77px by 892.24px
    // stylelint-disable unit-blacklist
    $backgroundHeight: ($backgroundWidth * $origHeight) / $origWidth;
    // stylelint-enable unit-blacklist
  
    background-size: $backgroundWidth $backgroundHeight;
  }
  
  // Provide values in px
  @mixin svg-background-size-in-width($origWidth, $origHeight, $backgroundWidth) {
    @include svg-background-size($origWidth, $origHeight, $backgroundWidth);
  }
  
  // Provide values in px
  @mixin svg-background-size-in-height($origWidth, $origHeight, $backgroundHeight) {
    // For IE 11
    // Original background svg dimensions: 1039.77px by 892.24px
    // stylelint-disable unit-blacklist
    $backgroundWidth: ($backgroundHeight * $origWidth) / $origHeight;
    // stylelint-enable unit-blacklist
  
    background-size: $backgroundWidth $backgroundHeight;
  }
  
  @mixin reset-button {
    background: none;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
  }
  
  @mixin pseudo-element($content: "") {
    content: $content;
    position: relative;
    display: block;
    width: 3rem;
    height: 3rem;
  }
  
//kwr color variables

//kwr functional colors
$kwr-red-active: #dd2246;
$kwr-yellow-active: #F6CB5E;
$moe-blue-active: $kwr-red-active;
$kwr-altrow-bg: #f8f4f6;
$kwr-banner-bglgt: #F1F1F2;
$kwr-banner-bgdrk: #27253E;
$kwr-tags-bgdrk: #151324;
$kwr-txt: #151324;
$kwr-menu-bg: #1e1c34;
$kwr-txt-format: #96929a;





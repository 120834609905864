//kwr-search

//Base styles override for MOE sites
.ms-search {
    &__form-control {
        max-width: unset !important;
    }
    &__autoSuggest {
        max-width: 95%;
        background-color: $color-grey-dark;
        & .ms-Spinner-label{
            color: $white !important;
        }
    }
}

//Search
.moe-search {
    &.kwr-search .ms-search__searchForm {
        border-radius: 0.25rem;
    }
    &-wrap {
        background-color: transparent;
        & .ms-search__searchForm {
            outline: none;
        }
        & .ms-search__form-submitSearch {
            background-color: $white;
        }
    }

    &-communities {
        &-col-lft {
            background-color: #00acbd;
        }
        &-col-rgt {
            background-color: #006f79;
        }
        & .moe-btn,
        & a {
            background: $color-red !important;
            color: $white;
            &:hover {
                background: $white !important;
                color: $color-red;
            }
        }
    }

    &-results-wrap {
        & .ms-search-result-container {
            &__refiner-section {
                background-color: #fff;
                border-right: 1px solid #707070;
                padding: 1.5rem;
            }
            &__product-section {
                background-color: $moe-green-lgter;
                padding: 1.5rem;
            }
        }
    }
}

//kwr-search
//Filter section bg for min width 993px and above resolutions
@media only screen and (min-width: $msv-container-min-width-l) {
    .kwr-body-search {
        & main {
            position: relative;
            &::after {
                content: " ";
                display: block;
                width: 20rem;
                height: 100%;
                background-color: $color-grey-dark;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        & .ms-search-result-container__refiner-section {
            background-color: transparent !important;
        }
    }
}

.kwr-search {
    &-wrap {
        max-width: 100% !important;
        //background-color: $color-grey-mid;
        background-color: transparent;
        position: relative;
        z-index: 15;
        & .ms-search__searchForm {
            max-width: 100%;
        }
    }
    &-main {
        max-width: calc(100% - 20rem);
        padding-bottom: 0 !important;
        justify-content: center;
        margin: 0 !important;     
        //Responsive UI
        @media only screen and (max-width: $msv-container-max-width-ml) {
            padding-bottom: 1.5rem !important;
            max-width: 100%;
        }
        & .ms-search {                       
            &__searchForm {
                max-width: 100%;
                height: auto;
                max-height: 3.2rem;
                background-color: transparent;
                border: none !important;
                outline: none !important;
                position: relative;
                & input {
                    width: 100%;
                    height: 3rem;
                    background-color: $color-grey-dark;
                    border: 0.15rem solid transparent;
                    border-radius: 0.4375em;
                    padding: 0 3rem;
                    color: $white;
                    &:focus {
                        border: 0.15rem solid $kwr-red-active !important;
                    }
                }
            }
            &__form-cancelSearch, 
            &__form-submitSearch {
                background-color: transparent;
                padding: 0 !important;
                position: absolute;  
                top: 0.3rem;             
                &::before {                   
                    color: $white !important;
                }
            }
            &__form-cancelSearch {    
                height: 2rem;
                width: 2rem;
                border-radius: 1rem !important;            
                right: 0.4rem;
                margin-top: 0.2rem;
                &:hover {
                    background-color: $kwr-red-active;
                }
            }
            &__form-submitSearch {
                height: 2.3rem;
                width: 2.3rem;
                left: 0.4rem;
            }
            &__autoSuggest {
                max-width: 30rem;
                margin-top: 3.375rem;
                margin-left: 5rem;
                //Mobile Responsive UI
                @media only screen and (max-width: $msv-breakpoint-s) {
                    margin-left: 0;
                }
                & .kwr-search-main {
                    max-width: 100%;
                }
                &.show {
                    border: 1px solid $moe-grey;
                }                 
                & * {
                    color: $white;
                }
                & .msc-autoSuggest__keywordResults-item,
                & .msc-autoSuggest__productResults-item {
                    & :hover {
                        background-color: $color-grey-mid;
                    }
                }
                //Override to Base theme Responsive UI for min-width 320px and above all devices
                @media (min-width: $msv-breakpoint-xs) {
                    & .ms-search__autoSuggest__keyword {
                        border-top: 1px solid $moe-grey;
                    }
                    & > div:last-child {
                        border-bottom: 1px solid $moe-grey;
                    }
                }
            }
            &__autoSuggest__keyword, 
            &__autoSuggest__product, 
            &__autoSuggest__category,
            &__autoSuggest__curricula {
                //Override to Base theme Responsive UI for min-width 320px and above all devices
                @media (min-width: $msv-breakpoint-xs) {
                    border-left: 1px solid $moe-grey;
                    border-right: 1px solid $moe-grey;
                }
            }
        }
    }
}


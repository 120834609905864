//Base Carousel classes override for MOE Carousel
.msc-carousel {
    background-color: transparent;
    &__inner,
    & .ms-content-block,
    & .ms-content-block__image,
    & .ms-content-block__image > picture,
    & .msc_image {
        padding: 0;
        margin: 0;
        height: 28.125rem !important;
        object-fit: cover;
    }
    & .ms-content-block__details {        
        height: 15.625rem !important;
        max-width: 45.25rem;
        margin-top: 3.75rem;
        margin-left: 0;
        //background-color: #1E2228;
        background-color: $unifying-curriculum-darkblue; //Bug 84418 Fix
        padding: 1.5rem 1.5rem 1.5rem 7rem !important;
        & h1, h2, h3, h4 {
            margin-bottom: 1.5rem;
        }
        & p {
            font-size: 1rem;
        }
        & * {
            color: $white;
        }
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            height: fit-content !important;
            max-width: 100%;
            padding: 2rem !important;
            margin-top: 0;
        }
        //Tablet Responsive UI
        @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            height: fit-content !important;
            max-width: 100%;
            padding: 2rem !important;
            margin-top: 0;
        }
    }
    &__control__prev {
        left: 0.625rem;
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            left: 2rem;
        }
    }
    &__control__next {
        right: 0.625rem;
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            right: 2rem;
        }
    }
    &__control__prev,
    &__control__next {
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            top: auto;
            bottom: -3.5rem;
            background-color: $moe-gray-300;
            color: $moe-white;
        }
    }
    &__indicators {
        bottom: 1.5rem;
        & li {
            border-color: $moe-grey;
        }
        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            bottom: -2.5rem;
        }
    }
}

//MOE Carousel
.moe-carousel {
    &-wrap {
        padding: 0;
        margin: 0 0 1.25rem 0;
        height: 28.125rem;
        background-color: transparent;
        position: relative;
        overflow: hidden;

        //Mobile Responsive UI
        @media only screen and (max-width: $msv-container-max-width-s) {
            padding-bottom: 5rem;
            height: auto;
        }
    }
    height: 100%;
    max-width: $msv-breakpoint-xl;       
    margin: 0 auto;
    background-color: transparent;
    &__item {
        &-content {
            padding: 0;
            margin: 0;
        }
    }
    &-none {
        //Tile List in Carousel override
        & .msc-ss-carousel {
            &__flipper {
                display: none !important;
            }
            &-slide {
                display: flex;
                flex-wrap: wrap;
                //Mobile only Responsive UI
                @media only screen and (max-width: $msv-container-max-width-s) {
                    justify-content: center;
                    & .ms-tile__item {
                        margin: 0.75rem;
                    }
                }
                //tablet
                @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-ml) {
                    justify-content: left;
                }
                //Base Desktop to medium desktop
                @media only screen and (min-width: $msv-container-min-width-lxl) and (max-width: $msv-breakpoint-mxl) {
                    justify-content: center;
                }
                //Large Desktop devices
                @media only screen and (min-width: $msv-breakpoint-xl) {
                    justify-content: space-between;
                    & .ms-tile__item:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.msc-ss-carousel-slide.moe-carousel-none {
    flex-wrap: wrap !important;
}

//fix bug 99581 - remove indicators for nzc
.nzc-carousel .msc-carousel__indicators {
    display: none;
}
//END
//kwr-footer

.kwr-footer {
    &-container {
        //KWR Home view specific footer container
        &--home {
            background-color: #121212;
            position: relative;
            margin-top: 3rem;
            z-index: 8;
        }
    }
}

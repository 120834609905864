$msv-btn-font-color: --msv-font-secondary-color;
$msv-btn-hover-color: --msv-selected-button-hover-background;
$msv-btn-color: #1470e1;

//Base theme overrides for MOE sites buttons

.msc-add-to-cart,
.ms-cart-icon__btn-guestcheckout,
.ms-cart-icon__btn-checkout,
.ms-checkout__btn-place-order,
.ms-write-review__toggle,
.ms-review-modal-submit,
.ms-review-modal-cancel,
.msc-cart__btn-backtoshopping,
.moe-btn-continue-browsing {
    max-width: 17.5rem;
    min-height: 3rem;
    height: auto !important;
    font-size: 0.9rem !important;
    font-weight: bold !important;
    border-radius: 1.563rem !important;
    margin-top: 0 !important;
}

//Blue buttons
.msc-add-to-cart,
.ms-checkout__btn-place-order,
.ms-cart-icon__btn-guestcheckout,
.ms-cart-icon__btn-checkout,
.ms-write-review__toggle,
.ms-review-modal-submit {
    border: none;
    color: var($msv-btn-font-color) !important;
    background-color: $msv-btn-color !important;
    padding: 0.625rem 1.5rem !important;
    margin-left: 0 !important;
    transition: all 0.3s;
    &:hover {
        color: var($msv-btn-font-color);
        background-color: var($msv-btn-hover-color) !important;
        text {
            color: var($msv-btn-font-color);
        }
    }
    text {
        color: var($msv-btn-font-color);
    }
}

//White buttons
.ms-review-modal-cancel,
.moe-btn-continue-browsing,
.msc-cart__btn-backtoshopping {
    background-color: $moe-white !important;
    color: $moe-gray-drkst !important;
    border: 1px solid $msv-btn-color !important;
    &:hover {
        background-color: $moe-gray-100 !important;
        border: 1px solid var($msv-btn-hover-color) !important;
    }
}

// Blue MOE button styles
.moe-btn {
    font-size: 1rem;
    text-decoration: none;
    border-radius: 1.563rem;
    border: 1px solid $msv-btn-color;
    padding: 0.625rem 1.5rem;
    transition: all 0.3s;
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
    & text {
        letter-spacing: 0.05rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    // Disabled state of Button
    &--disabled,
    &:disabled,
    &.disabled,
    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed !important;
        pointer-events: all;
    }

    // Default White Button
    &--default-white {
        min-width: 8rem !important;
        background-color: $moe-white;
        color: $moe-gray-drkst;
        border: 1px solid $msv-btn-color;
        &:hover {
            background-color: $moe-gray-100 !important;
            border: 1px solid var($msv-btn-hover-color) !important;
        }
        &:hover text {
            color: var($msv-btn-font-color);
        }
    }

    // Primary Blue button
    &--primary-blue {
        min-width: 8rem !important;
        border: none;
        color: var($msv-btn-font-color);
        background-color: $msv-btn-color;
        &:hover {
            color: var($msv-btn-font-color);
            background-color: var($msv-btn-hover-color) !important;
            text {
                color: var($msv-btn-font-color);
            }
        }
        text {
            color: var($msv-btn-font-color);
        }
    }
    &-iconlink {
        cursor: pointer;
        &-wrap {
            padding: 0;
            margin: 0;
        }
        &--addtocollection,
        &--addtocart,
        &--downloadresources {
            height: 2rem;
            display: flex;
            align-items: center;
            & .ms-quickView__button,
            & .moe-btn-iconlink__icon {
                margin-right: 0;
            }
            & .moe-btn-iconlink__icon {
                font-size: 1.5rem;
            }
            & .moe-btn-iconlink__txt {
                margin: 0;
            }
        }
        &--addtocollection {
            & .moe-btn-iconlink__icon,
            svg {
                height: 2rem;
                width: 2rem;
                margin-right: 0.15rem;
            }

            .moe-btn-iconlink__txt {
                margin: 0;
            }
        }
        &--downloadresources {
            & .ms-quickView__button {
                // width: 1.5rem;
                height: 1.5rem;
                margin-right: 0rem;
                &::before {
                    font-family: 'Font Awesome 5 Free';
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: 900;
                    text-rendering: auto;
                    line-height: 1;
                    content: '\f019';
                    font-size: 1.5rem;
                    width: 1.5rem;
                    vertical-align: text-bottom;
                    text-align: center;
                }
                // background: url('https://files-au-prod.cms.commerce.dynamics.com/cms/api/qsjhkgqqvf/binary/ML1YsR') no-repeat;
            }
        }
    }
}

//My Account view buttons for all sites
.moe-btns-account {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    & .moe-btn {
        display: block;
        margin: 1rem auto;
        text-align: center;
        max-width: 16rem;
        padding: 1rem 1.5rem;
    }
}

//English and Maori Language Toggle
// Language toggle button
// only 1 list item will be visible at a time
// active li becomes visible text
// inactive li becomes the round dot

.moe-btn-language-toggle {
    position: relative;
    width: 8rem;
    background: $moe-btn-language-toggle-bg;
    border: 1px solid $moe-grey;
    box-sizing: border-box;
    border-radius: 1.563rem;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    font-size: 14px;
    // fix: bug 99547 (focus on language toggle fixed - removed outline: 0 and added margin-inline-start: 1px)
    margin-inline-start: 1px;
    padding: 0.625rem 0rem;
    &__list {
        list-style: none;
        margin: 0 auto;
        padding: 0;
        &-item {
            color: $moe-btn-language-toggle-text-color;
            display: inline-block;
            font-weight: 700;
            padding: 0;
            vertical-align: middle;
            // text-transform: uppercase;
            & span {
                margin: 0 4px;
            }
        }
        &-item.active {
            text-decoration: none;
        }
        &-item.inactive {
            & span {
                display: none;
            }
        }
        &-item:first-child.inactive {
            &::before {
                content: ' ';
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                background: $moe-btn-language-toggle-inactive-bg;
                border: 0;
                border-radius: 1.563rem;
            }
        }
        &-item:last-child.inactive {
            &::after {
                content: ' ';
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                background: $moe-btn-language-toggle-inactive-bg;
                border: 0;
                border-radius: 1.563rem;
            }
        }
    }
}

//English text button as Maori text button (example: View more button as Whakarahi ake button)
.moe-download-history-showmore {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 1rem 2rem;
    & .ms-order-history__btn-more {
        height: 2.875rem;
        // font-size: 0;
        // color: transparent;
        margin: 0 !important;
        white-space: nowrap;
    }
    & .moe-btn-maoritxt {
        text-transform: uppercase;
        color: #fff;
        position: absolute;
        top: 0.8rem;
        left: auto;
        right: auto;
        font-weight: 700;
        cursor: pointer;
    }
}

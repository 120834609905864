//moe-reviews-list

//Base theme override for MOE sites
.ms-reviews-list {
    &__title {
        font-size: 1.25rem;
        line-height: 130%;
    }
    & .msc-rating__count,
    & .msc-l-dropdown__label,
    &__date,
    &__text {
        font-weight: normal;
    }
    &__next, 
    &__previous {
        font-size: 1rem;
    }

    &__refiners {
        //Small Mobile only
        @media screen and (max-width: $msv-breakpoint-s) {
            position: relative;
            max-width: 20rem;
            margin-bottom: 1rem;
            & .msc-l-dropdown__label {
                width: 5rem;
                height: 1.5rem;
                display: block;
                position: absolute;
                top: 1.25rem;
                left: 0.2rem;
                &[for="reviewsListFilterBy"] {
                    left: 11.2rem;
                }               
            }
            & .msc-l-dropdown {
                width: 8rem;
                margin-top: 3rem;
                &#reviewsListFilterBy {
                    margin-left: 3rem;
                }
            }
        }
        //Small Mobile landscape only
        @media screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-container-max-width-s) {
            & .msc-l-dropdown {
                &#reviewsListSortBy {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}



//moe-product-specification

.msc-moe-product-specification-tag {
    /*background: #2d5299 0% 0% no-repeat padding-box;*/
    background: transparent 0% 0% no-repeat padding-box;
    display: block;
    @media only screen and (max-width: $msv-breakpoint-l) {
        &.tags-hide {
            display: none !important;
        }
        &.tags-show {
            display: block !important;
            padding: 1.5rem 1.5rem 1.5rem 3rem;
        }
    }
    .msc-moe-tag-main-container {
        .msc-moe-tag-container {
            list-style: none;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 11px;
            margin-top: 0.7rem;

            li {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 16px;
            }

            .tag-product-name {
                color: white;
                font-size: 0.9rem;
            }

            .tag-cell-data {
                // border: 1px solid $moe-blue-active;
                border: 1px solid #e4e6e2bc;
                border-radius: 5px;
                padding: 0px 12px 0px 12px;
                background: #f5f5f5 0% 0% no-repeat padding-box;
                // color: $moe-blue-active;
                color: black;
                min-width: 2.75rem;
                font-size: 0.8rem;
                margin-top: 0.15rem;
            }
        }

        .ms-product-specification__heading {
            // font-size: 1.313rem;
            // margin: 0;
            // color: white;
            // font-weight: 700;
            // line-height: 27px;
            display: none;
        }

        span.msc-tag-divider {
            border-top: 1px solid #fff;
            width: 18vh;
            height: 1px;
            display: block;
            margin-bottom: 9px;
        }
    }
}

.moe-tags-wrap {
    margin-bottom: 0.5rem;
    & .moe-btn-tags {
        display: none;
        //Mobile, Tablet and Base Desktop Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            display: flex;
            align-items: center;
            margin: 0.5rem 1.5rem;
            border-radius: 1.563rem !important;
            font-size: 1rem;
            font-weight: bold;
            padding: 0.5rem 1rem !important;
        }
    }
}

// moe color variables

//Primary Colors Set One
$moe-red-lgt: #fcd2bf;
$moe-red-mdm: #e6674a;
$moe-red: #dc291e; //Main Red
$moe-red-drk: #b6231d;
$moe-red-accent: #006f79;

$moe-yellow-lgt: #fff1a4;
$moe-yellow-mdm: #ffd444;
$moe-yellow: #fcb116; //Main Yellow
$moe-orange: #de6420;
$moe-yellow-accent: #252379;

//Primary Colors Set Two
$moe-green-lgter: #eef5e5;
$moe-green-lgt: #c6e1a6;
$moe-green-mdm: #9ecf7c;
$moe-green: #58b947; //Main Green
$moe-green-drk: #00853e;
$moe-green-accent: #532e8f;

$moe-blue-lgt: #c5d6e8;
$moe-blue-mdm: #6e99d4;
$moe-blue: #2a6ebb; //Main Blue
$moe-blue-drk: #20419a;
$moe-blue-accent: #f47721;

//Secondary or Accent Colors
$moe-teal-lgt: #a2dadc;
$moe-teal-mdm: #48b9c7;
$moe-teal: #00acbd; //Main Teal
$moe-teal-drk: #006f79;
$moe-teal-accent: #dc291e;

$moe-purple-lgt: #b2b5db;
$moe-purple-mdm: #7175b7;
$moe-purple: #5956a5; //Main Purple
$moe-purple-drk: #252379;
$moe-purple-accent: #fcb116;
$moe-purple-redish: #5f1972; //Static page Hero content bg color

//moe neutral colors
$moe-white: #ffffff;
$moe-gray-lgt: #fafafa;
$moe-gray-100: #f1f1f2;
$moe-gray-200: #e6e7e8;
$moe-gray-300: #d1d3d4;
$moe-gray-400: #a7a9ac;
$moe-grey: #737373; // IMP Gray shade for Accessibility AA color contrast
$moe-gray-mdm: #58595b;
$moe-gray-drk: #363636;
$moe-gray-drkst: #1e2229;
$moe-black: #000000;

//moe functional colors
$moe-tilebar-color: #101010;
$moe-blue-active: #1470e1;

//Other supporting colors
$moe-border-peach: #b49c86;

//All Learning Area Colors

$la-english-color: #0e4e96;
//$la-english-color: #0e4c96; //Bug 32111 fix
//$la-arts-color: #cd7b2d;
$la-arts-color: #ce7b2d; //Bug 33770 fix
//$la-health-physical-education-color: #b72837;
$la-health-physical-education-color: #b82837; //Bug 33768 fix
//$la-learning-languages-color: #009cbe;
$la-learning-languages-color: #0098bd; //Bug 33772 fix
//$la-mathematics-statistics-color: #8c0051;
$la-mathematics-statistics-color: #8c0052; //Bug 33773 fix
$la-science-color: #004029;
//$la-social-sciences-color: #702b90;
$la-social-sciences-color: #6f2c91; //Bug 33718 fix
//$la-technology-color: #945440;
$la-technology-color: #955440; //Bug 33774 fix
$la-unifying-curriculum-color: #003d58;

//Unifying Curriculum main & Complementory colors
$unifying-curriculum-darkblue: $la-unifying-curriculum-color;
$unifying-curriculum-lightgreen: #6ec8b8;
$unifying-curriculum-orange: #e59a44;


//moe account view NZC, TWK & KWR colors
$moe-account-nzc: $moe-gray-drkst;
$moe-account-twk: $moe-green-drk;
$moe-account-kwr: #27253e;

// Style presets
:root {
    //tiles
    --moe-tile-bottom-background-color: #{$moe-tilebar-color};
    --moe-tile-bottom-hover-color: #{$moe-blue-active};

    --moe-tile-top-background-color: #{$moe-tilebar-color};
    --moe-tile-top-hover-color: #{$moe-blue-active};
}

//language toggle colors
$moe-btn-language-toggle-bg: $moe-gray-200;
$moe-btn-language-toggle-text-color: $moe-gray-drk;
$moe-btn-language-toggle-inactive-bg: $moe-grey;

//Semantic Colours (Alerts like success, error, failure, reminder etc.)
$moe-active-color: #0072da;
$moe-info-color: $moe-active-color;
$moe-info-bg-color: #f8fafc;
$moe-positive-color: #36b37e;
$moe-success-color: #007e3a;
$moe-success-bg-color: $moe-green-lgter;
$moe-notice-color: #ffab00;
$moe-negative-color: #cc3931;
$moe-warning-color: $moe-negative-color;
$moe-warning-bg-color: #fdf2ed;

$moe-kwr-about-section-bg: #f9f4f6;

.moe-digintr {
    &__container {
        display: flex;
        max-width: 60rem;
        flex-wrap: wrap;
    }

    &__image {
        flex: 2 0 auto;
        min-width: 18rem;
        max-width: 28.125rem;
        //Mobile Responsiveness
        @media screen and (max-width: $msv-container-max-width-s) {
            max-width: 18rem;
        }
    }

    &__content {
        padding: 1.5rem;
        //Mobile Responsiveness
        @media screen and (max-width: $msv-breakpoint-ml) {
            padding: 1.5rem 0;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;        
        align-items: center;
        grid-gap: 2rem;
        margin-bottom: 2rem;
        & .moe-btn {
            width: max-content;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            & svg {
                font-size: 1.5rem;
                margin-right: 0.5rem;
            }
            & text {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
            }
        }
    }

    &__author {
        color: $msv-gray-40;
    }

    &__info {
        display: flex;
        grid-gap: 3rem;
        color: $msv-gray-40;
    }
}

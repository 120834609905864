//Base Accordion styles override for MOE Sites Accordion
.ms-accordion {
    max-width: $msv-breakpoint-xl;
    //padding: 1rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    &-header-section {
        margin: 0;
        padding: 1rem 0;
        background-color: #fafafa;
        border-bottom: 2px solid #707070;
        &__heading {
            font-size: 1.5rem;
            margin: 0 1rem;
        }
        &-toggle-button-container {
            justify-content: flex-end;
            & .ms-accordion_ExpandAll,
            & .ms-accordion_CollapseAll {
                color: $moe-blue-active;
            }
        }
    }
    &-item {
        &__drawer {
            border-top: none;
            background-color: #fafafa;
            border-bottom: 1px solid #707070;
            & .drawer__button {
                font-size: 1.125rem;
                align-items: center;
                text-align: left;
                margin-bottom: 5px !important;
                //Devices 1280px and below
                @media only screen and (max-width: $msv-breakpoint-mxl) {
                    font-size: 1rem;
                    height: auto;
                }
                &text {
                    margin-left: 1rem;
                }
                &:hover {
                    color: $moe-blue-active;
                }
                &[aria-expanded='true'] {
                    @include add-icon($msv-Cancel, after); //Icon override as per wireframe
                }
            }
        }
        &-content {
            font-size: 1rem;
            padding: 1rem 1rem 2rem 1rem;
            //Mobile Responsiveness
            @media screen and (max-width: $msv-breakpoint-s) {
                padding-left: 0;
                padding-right: 0;
            }
            background-color: $white;
            & h1,
            h2,
            h3 {
                font-size: 1.25rem;
                margin-bottom: 1rem;
            }
        }
    }
}

//MOE Accordion
.moe-accordion {
    &__item {
        &-content {
            & h1,
            h2,
            h3 {
                margin-top: 0.5rem;
            }
            & h1,
            h2,
            h3,
            p {
                margin-bottom: 1rem;
            }
        }

        .drawer {
            &__buttontext {
                text-align: left;
                font-size: 1.2rem;

                @media only screen and (min-width: $msv-breakpoint-xs) and (max-width: $msv-breakpoint-s) {
                    margin-right: 0.2rem;
                }
            }
        }
    }
}

.msc-modal__add-collection {
    padding: 1.5rem;
    outline: none;

    .msc-collection-modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        &--left {
            display: flex;

            h5 {
                margin-right: 1rem;
            }
        }

        h5 {
            font-size: 1.5rem;
            line-height: 120%;
        }

        .msc-collection-modal__close {
            cursor: pointer;
            font-size: 1.2rem;
            margin-left: 2rem;
            //Mobile Responsiveness
            @media screen and (max-width: $msv-breakpoint-s) {
                margin-left: 1rem;
            }
        }

        .msc-collection-modal__header--right {
            display: flex;
            align-items: center;

            .msc-addnewcollection-btn {
                &-wrap {
                    display: flex;
                    align-items: center;
                    & .moe-btn-txt {
                        white-space: nowrap;
                    }
                    //Mobile Responsiveness
                    @media screen and (max-width: $msv-breakpoint-s) {
                        flex-wrap: wrap;
                        justify-content: center;
                        & .moe-btn-txt {
                            text-align: center;
                        }
                    }
                    svg {
                        margin-left: 0.5rem;
                    }
                }
                min-width: 2rem;
                cursor: pointer;
                font-size: 2rem;
                font-weight: 100;                
                color: #1470e1;
                border: 2px solid #1470e1;
                border-radius: 50%;
            }

            .msc-addnewcollection-grayout {
                pointer-events: none;
                cursor: not-allowed;
                color: #cecece;
                border-color: #cecece;
            }
        }
    }

    .msc-collection-modal__body {
        display: flex;
        align-items: center;
        justify-content: center;

        // .msc-collection-modal__backbtn,
        // .msc-collection-modal__forwardbtn {
        //     cursor: pointer;
        //     z-index: 5;
        //     width: 3rem;
        //     height: 3rem;
        //     border-radius: 50%;
        //     border: 1px solid black;
        //     position: absolute;
        //     background-color: white;
        // }

        .msc-collection-modal__forwardbtn {
            right: 0;
        }

        .msc-collection-modal__backbtn {
            left: 0;
        }
    }

    .msc-collection-modal__collections {
        display: flex;
        transition: all 0.3s;
        align-items: flex-start;
        scroll-behavior: smooth;
        transition: all 0.3s;
        position: relative;
        flex-wrap: wrap;
        height: 20rem;
        max-height: 20rem;
        overflow-y: scroll;

        .msc-collection-border__white {
            border: 2px solid #cacaca !important;
        }

        .msc-collection-border__blue {
            border: 2px solid $moe-blue-active;
            border-radius: 9px !important;

            img {
                border-radius: 7px;
            }
        }

        .msc-collection-modal__collection {
            display: flex;
            flex-direction: column;
            margin-right: 1rem;
            cursor: pointer;
            transition: all 0.3s;
            position: relative;

            .ms-addcollection__crud {
                display: none;
                transition: all 0.3s;
            }

            &:focus,
            &:active {
                border-radius: 9px;
            }

            // .msc-collection-modal__images {
            //     display: grid;
            //     grid-template-columns: 100px 100px;
            //     grid-row: auto auto;

            //     img {
            //         height: 100px;
            //         object-fit: cover;
            //     }

            //     img:nth-child(1) {
            //         border-radius: 5px 0 0 0;
            //     }

            //     img:nth-child(2) {
            //         border-radius: 0 5px 0 0;
            //     }

            //     img:nth-child(3) {
            //         border-radius: 0 0 0 5px;
            //     }

            //     img:nth-child(4) {
            //         border-radius: 0 0 5px 0;
            //     }
            // }

            .msc-collection-modal__title,
            .msc-collection-modal__resources {
                text-align: center;
            }

            .msc-collection__details {
                .msc-collection-modal__title {
                    margin-top: 1rem;
                    font-weight: bolder;
                    border: none;
                    background: transparent;
                }
            }

            .msc-collection-modal__resources {
                color: #999898;
                font-size: 0.6rem;
            }
        }
    }

    .msc-collection-modal__footer {
        margin-top: 2.5rem;
        display: flex;
        justify-content: center;

        // button {
        //     font-size: 16px;
        //     border-radius: 5px;
        //     padding: 0.4rem 1rem;
        //     background-color: $moe-blue-active;
        //     transition: all 0.3s;
        //     color: white;
        //     border: none;
        //     outline: none;
        //     cursor: pointer;

        //     &:hover {
        //         background-color: #0e5cba;
        //     }

        //     &:focus {
        //         border: none;
        //         outline: none;
        //     }
        // }

        .msc-collection-modal__button {
            cursor: not-allowed;
            background-color: #1470e185;
            color: #ffffff9c;

            &:hover {
                background-color: #1470e185 !important;
                cursor: not-allowed;
                color: #ffffff9c;
            }
        }
    }
}

.ms-add-collection-container {
    padding: 2rem;
    max-width: 65rem;
    overflow: hidden;

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper.msc-ss-carousel__flipper--next {
        right: -30px;
        left: auto;
    }

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper {
        top: 50%;
        left: -10px;
        font-size: 30px;
        border: rgba(0, 0, 0, 0);
        border-radius: 50%;
        background: var(--msv-bg-color);
    }

    .msc-ss-carousel .msc-flipper.msc-ss-carousel__flipper.disabled {
        visibility: hidden;
    }

    .msc-ss-carousel-slide li {
        height: 100%;
    }
}

// Add border radius style for modal
// .ms-add-collection-container .msc-modal__content {
//     border-radius: 1.563rem !important;
// }

.moe-addtocollection-err--text {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
    z-index: 0;
}

// Animations
@keyframes fadeInRight {
    from {
        opacity: 0.8;
        right: -60%;
    }
    to {
        opacity: 1;
        right: 0%;
    }
}

@keyframes fadeOutRight {
    from {
        opacity: 1;
        right: 0%;
    }
    to {
        opacity: 0;
        right: -60%;
    }
}

@keyframes fadeInTop {
    from {
        opacity: 0.1;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeOutTop {
    from {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.fadeInTop {
    animation: fadeInTop 0.5s ease-in-out;
}

.fadeOutTop {
    animation: fadeOutTop 0.5s ease-in-out;
}

.fadeInRight {
    animation: fadeInRight 0.5s ease-in-out;
}

.fadeOutRight {
    animation: fadeOutRight 0.5s ease-in-out;
}

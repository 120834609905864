//moe-google-slides
$google-slides-width: 100%;

.moe-google-slide {
    max-width: $msv-breakpoint-xl;
    margin: 2rem auto;
    &__ttl {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
    &__iframe {
        &-cont {
            background-color: $moe-gray-400;
        }
        width: $google-slides-width;
        min-height: 20rem;
        //Tablet Responisve UI
        @media only screen and (min-width: $msv-container-min-width-sm) and (max-width: $msv-breakpoint-m) {
            min-height: 25rem;
        }
        //Tablet Responisve UI
        @media only screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-ml) {
            min-height: 27rem;
        }
        //Medium devices between 1025px and 1439px
        @media only screen and (min-width: $msv-container-min-width-lxl) and (max-width: $msv-container-max-width-l) {
            min-height: 30rem;
        }
        //Large devices above 1440px
        @media only screen and (min-width: $msv-breakpoint-xl) {
            min-height: 36rem;
        }
    }
}
.ms-social-share {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: auto;
    
    &-label {
        display: flex;
        align-items: center;
        & .ms-social-share-text {
            font-weight: 600;
            display: inline-block;
            margin-bottom: 0.3125rem;
        }
        & .ms-social-share-icon {
            margin: 0 0.5rem;
        }
    }

    &-ul {
        display: flex;
        list-style: none;

        &.caption::before {
            content: attr(data-title);
            display: block;
            font-weight: bold;
            height: 30px;
            padding: 4px;
        }

        &.vertical {
            display: block;

            li {
                margin-bottom: 10px;
            }

            li:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &-item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        & img {
            width: 100%;
            height: auto;
        }
    }

    &-li {
        margin: 0 1.5rem 0 0;
        height: 2rem;
        width: 2rem;

        &:hover {
            cursor: pointer;
        }
    }
}

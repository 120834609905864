//moe-icons

//Base theme overrides for MOE sites

.ms-header__profile-button {
    padding-right: 2rem;
    &::after {
        display: none !important;
    }
    & .ms-profile-button-text {
        display: inline-flex;
        align-items: center;
        &::after {
            content: ' ';
            display: inline-block;
            width: 1.8rem;
            height: 1.8rem;
            background: url('https://files-au-prod.cms.commerce.dynamics.com/cms/api/qsjhkgqqvf/binary/ML26rl') no-repeat;
            background-size: inherit;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}

.moe-icon {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    &-share {
        // & svg {
        //     visibility: hidden;
        // }
        width: 1.25rem;
        height: 1.25rem;
        display: inline-block;
        // background: url('https://files-au-prod.cms.commerce.dynamics.com/cms/api/qsjhkgqqvf/binary/ML2bha') no-repeat;
        background-size: inherit;
        fill: $moe-grey;
    }
    //Manage Account User Icon
    &-manageaccount {
        max-width: 6rem !important;
        height: 6rem !important;
        margin-bottom: 0 !important;
        background-color: transparent !important;
        // & .ms-image-list {
        //     &__list .msc-ss-carousel-slide {
        //         justify-content: center;
        //         li {
        //             width: 6rem;
        //             justify-content: center;
        //             margin: 0;
        //         }
        //     }
        //     &__link {
        //         width: 6rem;
        //         height: 6rem;
        //         padding-top: 0;
        //     }
        // }
    }
    &-audio, &-video, &-mobileapp, &-dg-interactive, &-web {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;        
        vertical-align: middle;
        margin-right: 0.5rem;
    }
    &-audio {
        background-image: url('../../../images/icons/icon-audio.svg');
    }
    &-video {
        background-image: url('../../../images/icons/icon-video.svg');
    }
    &-mobileapp {
        background-image: url('../../../images/icons/icon-mobileapp.svg');
    }
    &-dg-interactive {
        background-image: url('../../../images/icons/icon-interactive.svg');
    }
}

.moe-tileview__icon,
.moe-listview__icon,
.moe-icon-backtotop,
.moe-icon-rightarrow,
.fa-tags,
.fa-times,
.fa-question-circle {
    display: inline-flex;
    margin: 1rem 1rem 0 1rem;
    align-items: center;
    &::before {
        @include msv-icon();
        font-size: 1.5rem;
        width: 1.5rem;
    }
}
.moe-tileview__icon::before {
    content: $msv-tileview-icon;
}
.moe-listview__icon::before {
    content: $msv-listview-icon;
}
.moe-icon-backtotop::before {
    content: $moe-backtotop;
    margin: 0 0.3rem;
}
.moe-icon-rightarrow::before {
    content: $msv-arrow-right;
}
.fa-tags {
    margin: 0 0.5rem 0 0 !important;
}
.fa-tags::before {
    content: $moe-tags;
}
.fa-times::before {
    content: $msv-Cancel;
}
.fa-question-circle::before {
    content: $moe-question-mark;
}
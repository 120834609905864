//kwr-section

.kwr-section {
    &-ttl-wrap {
        margin-top: 0;
    }
    &-alt {
        background-color: $kwr-altrow-bg;
        &--drkblue {
            background-color: $kwr-banner-bgdrk;
            & .ms-product-collection__heading {
                color: $moe-white;
            }
        }
    }
    &__aside {
        width: 20rem;
        background-color: transparent;
        padding: 2rem 3rem;
        &--dark {
            //background-color: $color-grey-dark;
            & .msc-moe-product-specification-tag .msc-moe-tag-main-container span.msc-tag-divider {
                border-top: none;
            }
        }
        //Mobile and Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            width: 100%;
            background-color: $color-grey-dark;
        }
    }
    &__body {
        & .ms-product-collection {
            &__heading {
                text-align: center;
                padding-left: 0;
                padding-right: 0;
            }
            & .msc-ss-carousel-slide {
                //Tablet and base desktops Responsive UI
                @media only screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-mxl) {
                    justify-content: center;
                }
            }
            &__item {
                min-width: 33.3% !important;
                //Tablet and base desktops Responsive UI
                @media only screen and (min-width: $msv-container-min-width-m) and (max-width: $msv-breakpoint-mxl) {
                    min-width: 50% !important;
                }
            }
        }
        & .ms-video-player {
            margin: 1.5rem;
        }
    }

    //Responsive UI max-width 1439px and below
    @media only screen and (max-width: $msv-container-max-width-l) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        &-alt {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    .ms-reviews-list {
        &__refiners {
            .msc-l-dropdown {
                width: auto;
            }
        }
    }
}

//kwr-field
.kwr-field {
    &--dark {
        background-color: $color-grey-dark;
        & *,
        & .ms-breadcrumb_link {
            color: $white !important;
        }
        & .kwr-links {
            & ul {
                list-style: none;
            }
            & a {
                text-decoration: none !important;
                color: $white;
                &:hover {
                    text-decoration: underline !important;
                    color: $kwr-yellow-active;
                }
            }
        }
    }
    &.text-center {
        text-align: center;
        & .ms-text-block {
            margin-top: 0;
        }
        & .ms-breadcrumb {
            display: flex;
            justify-content: center;
            padding-bottom: 0;
        }
    }
    &--addresource {
        background-color: $moe-kwr-about-section-bg;
        & .ms-container-background-image {
            min-height: 30rem !important;
            height: 30rem;
            background-size: auto 100%;
        }
        & .moe-field__col-lft,
        & .moe-field__col-rgt {
            background-color: $moe-gray-100;
        }
        & .moe-field__col-rgt {
            padding: 1.5rem;
        }
        & .kwr-field__actions > .row > .col-12 {
            flex: 0 0 50%;
            max-width: 50%;

            // Responsive UI for mobile
            @media only screen and (max-width: $msv-container-max-width-s) {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }

        & .ms-social-share {
            margin-bottom: 1.5rem;
        }

        //Responsive UI between 993px and 1280px resolutions
        @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
            & .col-lg-3 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
}

.ms-subscribe {
    max-width: $msv-breakpoint-xl;
    margin: 0 auto 2rem auto;
    border: 1px solid rgba(128, 128, 128, 0.4);
    padding: 1rem 2rem;
    &__details {
        max-width: 30rem;
        &__heading {
            font-size: 1.3rem;
            margin-bottom: 1rem;
        }
        &__text {
            color: gray;
            margin-bottom: 1rem;
        }
        &__form {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            &__email {
                width: 100%;
                border: none;
                background: transparent;
                padding: 0.3rem 0.1rem;
                border-bottom: 1px solid gray;
                margin-bottom: 1rem;
                outline: none;
    
                &::placeholder {
                    font-size: 1rem;
                }
            }
            &__submit {
                font-size: 1rem;
                text-transform: uppercase;
                padding: 0.6rem 3rem;
            }
        }
    }
}

//moe-side-nav

.moe-side-nav {
    &.fade {
        display: none;
        left: -20rem;
    }
    &.show {
        display: block;
        left: 3rem;
    }
    &-wrap {
        display: flex;
        /*display: grid;
        grid-template-columns: 3rem 17rem calc(100% - 20rem);
        grid-template-rows: 1fr;*/
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 1.5rem;
    }
    &-menu {
        width: 3rem;
        min-height: 100%;        
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        align-items: center;;
        position: relative;        
        background: #151324;
        color: $moe-white;
        padding: 0;
        z-index: 3;

        &__btn {
            width: 2.5rem;        
            height: 3rem;
            border: none;
            cursor: pointer;
            position: absolute;
            top: 0.8rem;
            z-index: 7;
        }
        &__icon {
            width: 2.5rem;        
            height: 3rem;
            display: inline-block;
            background-image: url('../../../images/icons/side-menu-icon.svg');
            background-repeat: no-repeat;
            background-size: auto 1.5rem;
            background-position: 50%;
            vertical-align: middle;
            margin: 0;
            opacity: .35;
            padding: .5rem;
            transition: opacity .1s ease-out;
            -webkit-transition: opacity .1s ease-out;            
            &:hover {
                opacity: 1;
            }
        }
    }
    width: 17.5rem;
    background: #1e1c34;    
    display: flex;
    margin-left: 0;
    overflow: hidden;
    -webkit-transition: margin .1s ease-out;
    transition: margin .1s ease-out;
    z-index: 2;

    //Side Menu List styles
    &__list {
        position: relative;
        top: 1rem;
        width: 17rem;
        height: 100%;
        padding-bottom: 22rem;
        scrollbar-width: none;
        -webkit-transition: margin .1s ease-out;
        transition: margin .1s ease-out;        
    }
    &__item {
        & .moe-thumbnail {
            background-color: $moe-grey;
            margin: 0.5rem 1rem 0.5rem 0;
            overflow: hidden;
            & img {
                width: 100%;
                height: 100%; // fix: bug 124331 - resolve tile image crop issue               
            }
        }
        &.vertical {
            & .moe-thumbnail {
                width: 14.375rem;
                height: 8.125rem;
            }
        }
        &.horizontal {
            display: flex;
            & .moe-thumbnail {
                width: 5rem;
                height: 3.125rem;
            }
        }
        position: relative;        
        top: 0;
        padding-left: 1.5rem;
        padding-bottom: 1rem;  
        &-ttl, &-des {
            display: block;
            font-weight: 700;
        }      
        &-ttl {
            font-size: 0.875rem;
            line-height: 1.3;
            color: $moe-white;
            padding: 0 1rem 0 0;
            margin-top: 0.5rem;
            margin-bottom: 0.8rem;            
        }
        &-des {
            font-size: 0.75rem;
            color: $moe-grey;            
            font-weight: 700;
            opacity: .65;            
            text-transform: uppercase;
        }
    }

}
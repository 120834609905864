//MOE Hero
.moe-hero {
    &-wrap {
        padding: 0;
        margin: 0;
        height: 28.125rem;
        background-color: transparent;
        position: relative;
        overflow: hidden;
        & h1,
        h2,
        h3 {
            line-height: 120%;
        }
        & a,
        a:hover {
            color: $white;
        }
        // Mobile and Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            height: 24rem;
        }
        & .ms-container-background-image {
            position: relative;
            top: 0;
            min-height: 28.125rem !important;
            max-height: 100%;
            max-width: $msv-breakpoint-xl !important;
            margin: 0 auto;
            background-size: auto 100% !important;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                min-height: 24rem !important;
            }
        }
        & .ms-breadcrumb {
            display: flex;
            justify-content: center;
            &_item {
                .ms-breadcrumb_link,
                span[aria-hidden='true'] {
                    color: $white;
                }
                &.breadcrumb_item-current {
                    .ms-breadcrumb_link {
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
        &--2col {
            height: auto;
            max-height: 27rem;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-mxl) {
                max-height: fit-content;
            }
            & .ms-breadcrumb {
                max-width: $msv-breakpoint-xl;
                margin: 0 auto;
                padding-top: 1rem;
                padding-bottom: 1rem;
                //background-color: #1E2228;
                position: absolute;
                top: -3.5rem;
                left: 5.5rem;
                // Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-mxl) {
                    position: relative;
                    top: 0;
                    left: 0;
                    justify-content: left;
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;
                }
                // Mobile and Tablet Responsive UI
                @media only screen and (min-width: $msv-container-min-width-l) and (max-width: $msv-breakpoint-mxl) {
                    justify-content: center;
                }
            }
        }
        &--fluid {
            height: 26rem;
            & .moe-hero__content {
                max-width: 100%;
                background-color: $moe-purple-redish;
                margin-bottom: 2rem;
                & .moe-hero__text {
                    max-width: 42rem;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                    //Responisve UI 992px and below
                    @media only screen and (max-width: $msv-breakpoint-l) {
                        margin: auto !important;
                        padding: 1.5rem;
                    }
                }
            }
            & .ms-breadcrumb {
                margin-top: 0;
            }
        }
    }
    height: 100%;
    max-width: $msv-breakpoint-xl;
    margin: 0 auto;
    &__content {
        height: 16.3rem !important;
        max-width: 45.25rem;
        position: relative;
        margin-top: 3.75rem;
        margin-bottom: 5rem;
        background-color: #1e2228;
        padding: 0;
        overflow: hidden;
        & .ms-container-background-image {
            position: relative;
            background-position: left top !important;
            background-size: 6.25rem auto !important;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                background-size: 4rem auto !important;
            }
        }
        & .moe-hero__text {
            margin: 2rem 2rem 2rem 7.6rem;
            max-height: 13rem;
            overflow: auto;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-l) {
                margin: 1.5rem 1.5rem 1.5rem 5rem !important;
                & h1,
                h2,
                h3,
                h4 {
                    margin-top: 0.3125rem;
                }
                & h1,
                h2,
                h3,
                h4,
                p {
                    padding-left: 0 !important;
                }
            }
        }
        & h1,
        h2,
        h3,
        h4 {
            margin-bottom: 1.5rem;
        }
        // Mobile and Tablet Responsive UI
        @media only screen and (max-width: $msv-breakpoint-l) {
            height: 16rem !important;
            max-width: 100%;
            //padding: 1.5rem;
            margin-top: 0;
            p {
                font-size: 14px;
            }
        }
    }
    &__text {
        font-size: 1rem;
        line-height: 140%;
        color: $white;
    }
    &--2col {
        background-color: $moe-gray-drkst;
        & .row div[class^='col'] {
            height: 100%;
        }
        & .moe-hero__content {
            max-width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            // Mobile and Tablet Responsive UI
            @media only screen and (max-width: $msv-breakpoint-m) {
                height: auto !important;
                & .row div[class^='col'] {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
                & .moe-hero__img {
                    width: auto;
                    align-items: center;
                }
            }
            .moe-hero__text {
                margin: 2rem;
                margin-left: 6rem;
                //Medium Desktop and above
                @media only screen and (min-width: $msv-breakpoint-mxl) {
                    max-height: 10rem;
                }
                // Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-mxl) {
                    margin-left: 1.5rem !important;
                }
            }
        }
        & .moe-hero__img {
            //Mobile only
            @media only screen and (max-width: $msv-container-max-width-xs) {
                max-height: 15rem;
            }
            & .ms-container-background-image {
                background-size: 100% auto !important;
                // Mobile and Tablet Responsive UI
                @media only screen and (max-width: $msv-breakpoint-m) {
                    min-height: 20rem !important;
                }
                //Mobile only
                @media only screen and (max-width: $msv-container-max-width-xs) {
                    background-position: center top !important;
                }
            }
        }
    }
}

//MOE Hero Welcome section
.moe-hero-section {
    display: flex;
    justify-content: space-between;
    background-color: #2a2e34;

    .ms-moe-herosection-top {
        margin-top: 2rem;
        padding: 0 4rem 0 6rem;

        @media (max-width: $msv-breakpoint-m) {
            padding: 0 2rem 0 2rem;
        }

        @media (max-width: $msv-breakpoint-l) {
            padding: 0 2rem 0 2rem;
        }

        h1,
        p {
            color: white;
        }

        .ms-moe-herosection__desc {
            font-size: 0.7rem;
            width: 80%;
            margin-top: 1.5rem;

            @media (max-width: $msv-breakpoint-l) {
                width: 100%;
            }

            @media (max-width: $msv-breakpoint-m) {
                margin-bottom: 2rem;
                width: 100%;
            }
        }
    }

    .ms-moe-herosection-bottom {
        width: 100%;

        @media (max-width: $msv-breakpoint-m) {
            display: none;
        }

        img {
            object-fit: cover;
            max-width: none;
            width: 406px;
            height: 281px;

            @media (max-width: $msv-breakpoint-l) {
                width: 380px;
                height: 321px;
            }
        }
    }
}

//moe banner
.moe-banner {
    & .ms-container-background-image {
        min-height: 20rem !important;
        background-size: 100% auto;
    }
}
//moe hero section

.moe-resource-hero {
    background-color: #1e2228;
    width: 100%;

    margin: 0 auto;
    display: grid;
    grid-template-columns: 3fr 2fr;
    justify-content: center;
    align-items: stretch;
    max-width: $msv-breakpoint-xl; //breakpoint needs to be added
    min-height: 200px;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem;
        @media only screen and (min-width: $msv-breakpoint-mxl) {
            padding-left: 6rem;
        }
        &-overflow {
            max-height: 200px;
            overflow: auto;
            padding: 0;
            margin-bottom: 1.5rem;
        }

        h2 {
            color: $white;
            margin-bottom: 1rem;
            text-align: left;
            font-weight: bold;
        }
        p {
            color: $white;
            text-align: left;
        }
    }
    &__image {
        img {
            display: block;
            width: 100%;
            height: auto;
            object-fit: fill;
            max-height: 300px;
            @media screen and (max-width: $msv-breakpoint-m) {
                display: none;
            }
        }
    }
    & .ms-breadcrumb {
        margin: 0;
        padding: 0;

        color: $white;
        & .ms-breadcrumb_item {
            color: $white;
            & .ms-breadcrumb_link {
                color: $white;
            }
            & .ms-breadcrumb_link:first-child {
                margin-left: 0;
            }
        }
    }
    & a,
    a:hover {
        color: $white;
    }
}

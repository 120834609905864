// Pages for MOE
@import 'kwr-atua-search';
@import 'kwr-search-page';
@import 'kwr-resource-page';
@import 'kwr-landing-page';

//Global Common Styles and Overrides of Base Theme starts
body {
    padding: 0;
    margin: 0;
    background-color: $white;
    &-dark {
        background-color: $color-grey-mid !important; //Dark background
        color: $white;
    }
}
main {
    background-color: $white;
    color: $kwr-txt;
    & .ms-search-result-container__category-hierarchy {
        display: none;
    }
}

.kwr-body-search,
.kwr-category-body,
.kwr-body--curcols {
    .header__title {
        font-size: 1.75rem;
        font-weight: 800;
        margin-bottom: 1rem;
        & h1,
        h2,
        &--curatedproductheading,
        &--searchtext {
            font-size: 1.75rem;
            font-weight: 800;
            & br {
                display: none;
            }
        }
        &--curatedtext,
        &--curatedproduct {
            font-size: 1rem;
            font-weight: normal;
        }
    }
}

.kwr-body-search,
.kwr-category-body,
.kwr-body-newsevents,
.kwr-body--curcols {
    & .header {
        & .header__panel2 {
            text-align: right;
        }
        & .moe-language-toggle-holder {
            &--desktop {
                display: none;
                @media screen and (min-width: 992px) {
                    display: block;
                }
            }
        }
    }
    & main {
        background-color: $kwr-banner-bgdrk;
        color: $white;
        //Mobile and Tablet
        @media only screen and (max-width: $msv-container-max-width-ml) {
            min-height: auto;
        }
    }
    a:focus,
    a:hover,
    a:visited:focus,
    a:visited:hover {
        color: $white;
    }
    //mobile and Tablet responsive UI with max-width 1024px and below
    @media only screen and (max-width: $msv-breakpoint-ml) {
        & .overlay.open {
            max-height: 100vh;
            & .headerMenuMobile,
            & .headerMenuMobile__list--child-active {
                height: 100%;
            }
        }
    }
}
//Global Common Styles and Overrides of Base Theme ends

//Accessibility Findings Specific Fixes starts
.skip-to-main {
    background-color: $kwr-yellow-active;
    color: $kwr-banner-bgdrk;
}
.headerMenuMobile__child-main-title {
    color: $moe-gray-400;    
}
//Accessibility Findings Specific Fixes ends

//moe esl button

.moe-btn-esl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    border-radius: 9px;
    padding: 0 0 0 0.8rem;
    overflow: hidden;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.8rem 0;
    &__img {
        height: 48px;
    }
}
